import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { headingOne } from '../../styles/Typography';

const HeadingOneBase = props => {
  return (
    <Typography className={props.className} variant="h1">
      {props.label}
    </Typography>
  );
};

const HeadingOne = styled(HeadingOneBase)`
  &&& {
    ${headingOne}
  }
`;

export default HeadingOne;
