import moment from 'moment';

const mapStallProducts = stalls =>
  stalls.map(stall => ({
    id: stall.id,
    description: '',
    endDate: stall.dateRange.endDate || '',
    name: stall.name,
    nightly: stall.pricing === 'nightly',
    price: Number(stall.price),
    stalls: stall.stallsForThisRate,
    startDate: stall.dateRange.startDate || '',
    minNights: stall.minNights ? +stall.minNights : 1,
    stallFlip: !stall.flippingDisabled
  }));

const mapRvProducts = ({ rvs }) =>
  rvs.map(rv => {
    return {
      id: rv.id,
      description: '',
      endDate: rv.dateRange?.endDate || '',
      name: rv.rvLot ? rv.rvLot.name : '',
      price: Number(rv.price),
      nightly: rv.pricing === 'nightly',
      rvLotId: rv.rvLotId,
      rvSpots: rv.spots,
      startDate: rv.dateRange?.startDate || '',
      minNights: rv.minNights ? +rv.minNights : 1,
      rvFlip: !rv.flippingDisabled
    };
  });

const mapAddOnProducts = addOns =>
  addOns.map((addOn = {}) => ({
    addOnId: addOn.id,
    price: Number(addOn.price ? addOn.price : 0),
    disabled: 'disabled' in addOn ? addOn.disabled : false
  }));

const mapRequiredAddOns = requiredAddOns =>
  requiredAddOns.map((ra = {}) => ({
    id: +ra.id || '',
    eventId: +ra.eventId,
    addOnProduct: +ra.addOnProduct,
    xRefTypeId: +ra.xRefTypeId,
    ratio: +ra.ratio,
    condition: ra.condition,
    max: ra.max
  }));

const mapEventLinks = eventLinks =>
  eventLinks.map((el = {}) => ({
    id: +el.id || '',
    url: el.url || '',
    label: el.label || '',
    description: el.description || ''
  }));

export const mapFormToEditEventInput = (form, id) => {
  return {
    id,
    ...mapFormToCreateEventInput(form)
  };
};

export const mapFormToCreateEventInput = form => {
  const stallQuestions = form.stallQuestions.map((question, i) => {
    question.listOrder = i;
    delete question.id;
    delete question.__typename;
    delete question.updated;
    question.answerOptions = question.answerOptions.map(answer => answer.text);
    addLengthProps(question);
    return {
      ...question
    };
  });

  const rvQuestions = form.rvQuestions.map((question, i) => {
    question.listOrder = i;
    delete question.id;
    delete question.__typename;
    delete question.updated;
    question.answerOptions = question.answerOptions.map(answer => answer.text);
    addLengthProps(question);
    return {
      ...question
    };
  });

  return {
    checkInTime: form.checkInTime,
    checkOutTime: form.checkOutTime,
    closeDate: form.bookingWindow.endDate && form.closeTime ? moment(`${form.bookingWindow.endDate} ${form.closeTime}`) : '',
    openDate: form.bookingWindow.startDate && form.openTime ? moment(`${form.bookingWindow.startDate} ${form.openTime}`) : '',
    endDate: form.eventDates.endDate ? form.eventDates.endDate : '',
    name: form.eventName,
    description: form.eventDescription,
    startDate: form.eventDates.startDate ? form.eventDates.startDate : '',
    venueAgreementDocumentId: form.venueAgreement,
    venueMapDocumentId: form.venueMap || null,
    stallProducts: form.hasStalls ? mapStallProducts(form.stalls) : undefined,
    stallQuestions: stallQuestions,
    rvProducts: form.hasRvs ? mapRvProducts(form) : undefined,
    rvQuestions: rvQuestions,
    addOnProducts: form.addOns.length ? mapAddOnProducts(form.addOns) : undefined,
    renterGroupCodeMode: form.renterGroupCodeMode,
    allowAddOnOnlyOrder: form.allowAddOnOnlyOrder,
    protectedStartDate: form.protectedDates.startDate && form.protectedStartTime ? moment(`${form.protectedDates.startDate} ${form.protectedStartTime}`) : null,
    protectedEndDate: form.protectedDates.endDate && form.protectedEndTime ? moment(`${form.protectedDates.endDate} ${form.protectedEndTime}`) : null,
    password: form.password,
    protectedEvent: form.protectedEvent,
    hidden: form.hidden,
    requiredAddOns: mapRequiredAddOns(form.requiredAddOns),
    eventLinks: mapEventLinks(form.eventLinks)
  };
};

const addLengthProps = question => {
  question.minLength = isNumeric(question.minLength) ? parseInt(question.minLength) : null;
  question.maxLength = isNumeric(question.maxLength) ? parseInt(question.maxLength) : null;
};

const isNumeric = num => {
  return !isNaN(parseInt(num));
};
