import moment from 'moment';
import { useState, useEffect } from 'react';
import { getSelectedBuildingsId, getSelectedBuildingsOverlapping } from './stallsHelper';

export const useNoSelectableBuildings = (stallProducts, stallsHash, index) => {
  const [selectedBuildingsOverlapping, setSelectedBuildingsOverlapping] = useState({});
  const [noSelectableBuildingsId, setNoSelectableBuildingsId] = useState(new Set());
  const currentStallProduct = !!stallProducts && stallProducts[index];

  const getstallProductsOverlapping = () => {
    const [currentProductStart, currentProductEnd] = getStallProductDateRange(currentStallProduct);
    const currentProductRange = moment.range(currentProductStart, currentProductEnd);

    const stallProductsOverlapping = getAllProductsOverlapping(stallProducts, currentProductRange);
    const stallProductsOverlappingWithDiffFlip = getProductsWithDiffFlip(stallProductsOverlapping);

    return [stallProductsOverlapping, stallProductsOverlappingWithDiffFlip];
  };

  const getStallProductDateRange = stallProduct => {
    const { startDate, endDate } = stallProduct.dateRange;
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    return [momentStartDate, momentEndDate];
  };

  const getAllProductsOverlapping = (stallProducts, currentProductRange) => {
    const stallProductsOverlapping = stallProducts
      .map((p, index) => ({ ...p, index }))
      .filter(stallProduct => {
        return isOverlapping(stallProduct, currentProductRange);
      });

    return stallProductsOverlapping;
  };

  const getProductsWithDiffFlip = stallProducts => {
    return stallProducts.filter(stallProduct => {
      return !haveSameFlip(stallProduct, currentStallProduct);
    });
  };

  const haveSameFlip = (product1, product2) => {
    return product1.flippingDisabled === product2.flippingDisabled;
  };

  const isOverlapping = (stallProduct, currentProductRange) => {
    const [otherStallProductStart, otherStallProductEnd] = getStallProductDateRange(stallProduct);

    if (!otherStallProductStart || !otherStallProductEnd) {
      return false;
    }

    const otherProductRange = moment.range(otherStallProductStart, otherStallProductEnd);
    const overlaps = currentProductRange.overlaps(otherProductRange, { adjacent: true });
    return overlaps;
  };

  const getStallInformationArray = stallProductsWithOverlap => {
    const selectedStalls = stallProductsWithOverlap.reduce((acc, currentStallProduct) => {
      const stalls = getStalls(currentStallProduct);
      acc = [...acc, ...stalls];
      return acc;
    }, []);

    return selectedStalls;
  };

  const getStalls = stallProduct => {
    return stallProduct.stallsForThisRate.map(stallId => {
      const indexSet = new Set([stallProduct.index]);
      return { stallId, flip: !stallProduct.flippingDisabled, indexSet };
    });
  };

  const getStallsId = stallProducts => {
    const selectedStallsId = stallProducts.reduce((acc, currentStallProduct) => {
      acc = new Set([...acc, ...currentStallProduct.stallsForThisRate]);
      return acc;
    }, new Set());

    return selectedStallsId;
  };

  const hasValues = () => {
    return Object.keys(stallsHash).length > 0;
  };

  useEffect(() => {
    if (hasValues(stallsHash) && !!stallProducts) {
      const [stallProductsOverlapping, stallProductsOverlappingWithDiffFlip] = getstallProductsOverlapping();
      const selectedStallsOverlapping = getStallInformationArray(stallProductsOverlapping);
      const selectedStallsOverlappingWithDiffFlipId = getStallsId(stallProductsOverlappingWithDiffFlip);

      const selectedBuildingsOverlapping = getSelectedBuildingsOverlapping(stallsHash, selectedStallsOverlapping);
      const buildingsOverlappingWithDiffFlipId = getSelectedBuildingsId(stallsHash, selectedStallsOverlappingWithDiffFlipId);

      setSelectedBuildingsOverlapping(selectedBuildingsOverlapping);
      setNoSelectableBuildingsId(buildingsOverlappingWithDiffFlipId);
    }
  }, [stallProducts, stallsHash]);

  return [selectedBuildingsOverlapping, noSelectableBuildingsId];
};
