import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SAVED_QUESTIONS } from 'Queries/SavedQuestions';
import { UserContext } from 'Store/UserContext';
import FormCard from 'Components/Cards/FormCard';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import { FormControl, IconButton, MenuItem } from '@material-ui/core';
import { HeadingFour } from 'Components/Headings';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const SavedQuestionsContainer = ({ productType }) => {
  const { user } = useContext(UserContext);
  const {
    values: { stallQuestions, rvQuestions },
    setFieldValue
  } = useFormikContext();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestions] = useState(null);

  const userVenueId = user?.venues && user.venues[0].id;
  const productXRefType = productType === 'stalls' ? 1 : 3;

  const { data, loading } = useQuery(SAVED_QUESTIONS, {
    variables: {
      input: {
        venueId: userVenueId,
        productXRefType: productXRefType
      }
    },
    skip: !userVenueId,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.savedQuestions?.length > 0) {
      setQuestions(data.savedQuestions);
    }
  }, [data, loading]);

  if (loading || (questions && questions.length === 0)) {
    return null;
  }

  const handleQuestionSelect = () => {
    if (!selectedQuestion || questions.length === 0) return;
    const savedQuestion = questions.find(q => q.id === selectedQuestion);

    const eventQuestions = productType === 'stalls' ? stallQuestions : rvQuestions;
    const questionType = productType === 'stalls' ? 'stallQuestions' : 'rvQuestions';

    const maxQuestionId = eventQuestions.length > 0 ? Math.max(eventQuestions.map(q => q.id)) : 1;

    const newQuestion = {
      question: savedQuestion.question,
      answerOptions: savedQuestion.answerOptions.map((a, index) => {
        return { id: index + 1, text: a };
      }),
      questionType: savedQuestion.questionType,
      required: false,
      saved: false,
      listOrder: 0
    };

    setFieldValue(questionType, [...eventQuestions, { ...newQuestion, id: maxQuestionId + 1 }]);
  };

  const options = questions.map(q => (
    <MenuItem key={q.id} value={q.id}>
      {`${q.question.substring(0, 50)}...`}
    </MenuItem>
  ));

  return (
    <SavedQuestionsDiv>
      <HeadingFour label={'Saved questions'} className={'saved-questions-heading'} />
      <FormCard className={`card-item`}>
        <FlexContainer>
          <FormControl style={{ width: '100%' }}>
            <Field>
              {({ meta, ...props }) => (
                <Select
                  data-testid="question-type"
                  value={undefined}
                  {...props}
                  meta={meta}
                  disabled={false}
                  onChange={e => setSelectedQuestions(e.target.value)}>
                  {options}
                </Select>
              )}
            </Field>
          </FormControl>
          <IconButton data-testid="add-question-button" onClick={handleQuestionSelect}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </FlexContainer>
      </FormCard>
    </SavedQuestionsDiv>
  );
};

const SavedQuestionsDiv = styled.div`
  margin-top: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

export default SavedQuestionsContainer;
