import * as Yup from 'yup';

export const initialValues = {
  amount: '',
  stallLetter: '',
  firstStallNumber: '',
  buildingId: '',
  status: '',
  description: ''
};

export const deleteInitialValues = {
  stallId: '',
  stallIdFrom: '',
  stallIdTo: '',
  buildingId: ''
};

export const CreateStallsSchema = Yup.object().shape({
  amount: Yup.number().required('amount is required'),
  buildingId: Yup.string().required('The building id is required'),
  status: Yup.string().required('The status is required')
});

export const DeleteStallsSchema = Yup.object().shape({
  stallId: Yup.number().nullable(),
  stallIdFrom: Yup.string().nullable(),
  stallIdTo: Yup.string().nullable(),
  buildingId: Yup.string().required('The building id is required')
});
