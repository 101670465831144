import moment from 'moment';

export const getOverlappingRVProducts = (rvProducts, index, rvLotId, dateRange) => {
  const rvProductsWithSameLot = getOtherRVProductsWithSameLot(rvProducts, index, rvLotId);
  const [currentProductStart, currentProductEnd] = getRVProductDateRange(dateRange);
  const currentProductRange = moment.range(currentProductStart, currentProductEnd);

  return getProductsOverlapping(rvProductsWithSameLot, currentProductRange);
};

const getProductsOverlapping = (rvProducts, currentProductRange) => {
  const rvProductsOverlapping = rvProducts.filter(rvProduct => {
    return isOverlapping(rvProduct, currentProductRange);
  });

  return rvProductsOverlapping;
};

export const getOtherRVProductsWithSameLot = (rvProducts, index, rvLotId) => {
  return rvProducts.filter((rvProduct, i) => i !== index && rvProduct.rvLotId === rvLotId);
};

export const getRVProductDateRange = dateRange => {
  const { startDate, endDate } = dateRange;
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  return [momentStartDate, momentEndDate];
};

export const isOverlapping = (rvProduct, currentProductRange) => {
  const [otherRVProductStart, otherRVProductEnd] = getRVProductDateRange(rvProduct.dateRange);

  if (!otherRVProductStart || !otherRVProductEnd) {
    return false;
  }

  const otherProductRange = moment.range(otherRVProductStart, otherRVProductEnd);
  const overlaps = currentProductRange.overlaps(otherProductRange, { adjacent: true });
  return overlaps;
};
