import React from 'react';
import { useFormikContext } from 'formik';

import Ticket from './Ticket';

const FormikTicket = ({ className = '', stripe, elements, setTotal, setTotalFee }) => {
  const { values = {} } = useFormikContext();
  const { addOns, ccInformation, event, rv_spot, rvProductId, stalls, stallProductId, adminCharge } = values;
  const { addOnProducts } = event;
  return (
    <Ticket
      className={className}
      addOnProducts={addOnProducts}
      ccInformation={ccInformation}
      stallProductId={stallProductId}
      rvProductId={rvProductId}
      addOns={addOns}
      stalls={stalls}
      rv_spot={rv_spot}
      key={`${event.id}-${stallProductId}-${rvProductId}-${addOns.length}`}
      stripe={stripe}
      elements={elements}
      setTotal={setTotal}
      setTotalFee={setTotalFee}
      adminCharge={adminCharge}
    />
  );
};

export default FormikTicket;
