import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { TextField } from '@material-ui/core';

import FormCard from '../../../../components/Cards/FormCard';
import { HeadingThree } from '../../../../components/Headings';
import ActionButton from '../../../../components/Button/ActionButton';
import { Separator } from 'Components/Separator';
import { cleanMoneyInput } from 'Components/Fields/FormikMoneyField';

const split3Digits = ints => {
  if (ints.length <= 3) {
    return ints;
  }

  return ints
    .split('')
    .reverse()
    .map((char, index) => ((index + 1) % 3 === 0 && index + 1 !== ints.length ? `,${char}` : char))
    .reverse()
    .join('');
};

const parseMoney = (val = '') => {
  const valString = `${val}`;
  const [ints, decimals] = valString.split('.');
  if (!ints) {
    return decimals ? `0.${decimals.substr(0, 2)}` : valString;
  }

  if (ints.length > 3) {
    const withCommas = split3Digits(ints);
    return decimals ? `${withCommas}.${decimals.substr(0, 2)}` : valString.match(/\.$/) ? `${withCommas}.` : `${withCommas}`;
  }

  return decimals ? `${ints}.${decimals.substr(0, 2)}` : valString;
};

function extraChargeBase(props) {
  const [amount, setAmount] = useState(undefined);
  const [reason, setReason] = useState('');
  const { endDate, eventId } = props;
  const { values, setFieldValue } = useFormikContext();
  const { reservationEdit } = values;

  // BUSINESS RULES -- START
  const endDateAfter30Days = moment(endDate, 'YYYY-MM-DD').add(30, 'days');
  const moreThan30days = moment(new Date()).isAfter(endDateAfter30Days);

  if (moreThan30days) {
    return null;
  }

  // BUSINESS RULES -- END

  useEffect(() => {
    if (!values?.stallProductId && !values?.rvProductId && !hasAddons()) {
      setFieldValue('adminCharge', {});
    }
  }, [values.stallProductId, values.rvProductId, values.addOns]);

  const maskedValue = () => `$${parseMoney(amount)}`;

  const handleExtraCharge = () => {
    if (amount && +amount > 0 && reason && reason.length > 0) {
      // SET VALUES to form. Send it to update order and use the add admin charge (Misc charge) method in the API from there

      setFieldValue('adminCharge.amount', +amount);
      setFieldValue('adminCharge.reason', reason);
    }
  };

  const hasAddons = () => {
    const addon = Object.entries(values.addOns).find(x => x[1] > 0);
    return !!addon;
  };

  if (!reservationEdit && (!eventId || (!values?.stallProductId && !values?.rvProductId && !hasAddons()))) {
    return (
      <ExtraChargeCard className="card_extra_charge no-event-selected" dataTestId="card_extra_charge-no-event">
        <HeadingThree label={'Misc charge'} />
        <div className="switch-container">
          <p>Select Stalls, RV spots or Add Ons to add a misc charge</p>
        </div>
      </ExtraChargeCard>
    );
  }

  return (
    <ExtraChargeCard className="card_extra_charge" dataTestId="card_extra_charge">
      <HeadingThree label={'Misc charge'} />
      <Separator margin="0.625rem 0 1.375rem" />
      <div className="row">
        <div className="item">
          <div className="extra_charge_reason">
            <TextField
              className="card_extra_charge_input_reason"
              label="Reason"
              type="text"
              variant="filled"
              value={reason || ''}
              required
              onChange={e => {
                setReason(e.target.value);
              }}
            />
          </div>
          <div className="extra_charge_amount">
            <TextField
              className="card_extra_charge_input"
              label="Amount"
              type="text"
              variant="filled"
              required
              value={amount ? maskedValue() : ''}
              onChange={e => {
                setAmount(cleanMoneyInput(e.target.value));
              }}
            />
            <ActionButton className="card_extra_charge_button" primary={true} onClick={handleExtraCharge}>
              <span>Add</span>
            </ActionButton>
          </div>
        </div>
      </div>
    </ExtraChargeCard>
  );
}

const ExtraChargeCard = styled(FormCard)`
  padding: 20px;

  &.no-event-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;
  }

  .card_extra_charge_input {
    width: 66%;
  }

  .card_extra_charge_button {
    color: #fff !important;
    height: 56px !important;
    width: 28% !important;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .card_extra_charge_input_reason {
    width: 100%;
  }

  .item {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .extra_charge_reason,
    .extra_charge_amount {
      width: 100%;
    }

    .extra_charge_reason {
      margin-bottom: 10px;
    }

    .extra_charge_amount {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export default extraChargeBase;
