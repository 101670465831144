export const getStallsHash = stallsPerBuilding => {
  const buildingsWithStalls = stallsPerBuilding ?? [];

  const stallsHash = buildingsWithStalls.reduce((acc, currentBuilding) => {
    for (const stall of currentBuilding.availableStalls) {
      acc[stall.id] = currentBuilding.id;
    }
    return acc;
  }, {});

  return stallsHash;
};

export const getSelectedBuildingsId = (stallsHash, selectedStalls) => {
  const selectedBuildingsId = new Set();
  for (const currentStallId of selectedStalls) {
    const buildingId = stallsHash[currentStallId];
    selectedBuildingsId.add(buildingId);
  }

  return selectedBuildingsId;
};

export const getSelectedBuildingsOverlapping = (stallsHash, selectedStalls) => {
  const selectedBuildings = {};
  for (const currentStall of selectedStalls) {
    fillSelectedBuilding(stallsHash, currentStall, selectedBuildings);
  }

  return selectedBuildings;
};

const fillSelectedBuilding = (stallsHash, currentStall, selectedBuildings) => {
  const buildingId = stallsHash[currentStall.stallId];
  const existingBuilding = selectedBuildings[buildingId];

  if (existingBuilding) {
    currentStall.indexSet = new Set([...existingBuilding.indexSet, ...currentStall.indexSet]);
  }

  selectedBuildings[buildingId] = { ...currentStall, buildingId };
};
