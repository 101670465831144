import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Formik, Field, Form } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';
import TextFilter from '../../../../components/Table/Filters/TextFilter';
import SelectFilter from '../../../../components/Table/Filters/SelectFilter';
import ButtonFilter from '../../../../components/Table/Filters/ButtonFilter';
import CheckboxFilter from '../../../../components/Table/Filters/CheckboxFilter';
import { TableContext } from 'Components/Table/TableContext';
import { HeadingFour } from 'Components/Headings';
import './TableFiltersCalendar.scss';
import { formatPhoneNumber } from 'Helpers/formatPhoneNumber';
import Calendar from 'Components/Calendar/Calendar';

const TODAY = 'today';
const TOMORROW = 'tomorrow';
const CUSTOM = 'custom';
const SINGLE_DAY = 'singleDay';

const TableFiltersBase = props => {
  const tableContextRef = useContext(TableContext);
  const { open, filters, setFilters, checkInOutFilter, orders, setCheckInOutFilter, closeFilters } = props;
  const sessionFilters = JSON.parse(sessionStorage.getItem('filters'));
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [initialValues, setInitialValues] = useState({
    user: '',
    renterEmail: '',
    renterPhone: '',
    orderId: '',
    stallName: '',
    rvSpotName: '',
    event: '',
    group: '',
    checkInOutDate: '',
    reservationStatus: 0,
    hasStalls: false,
    hasRVs: false,
    hasAddOns: false,
    hasSpecialReqs: false,
    spacesNeedAssignment: false,
    allSpacesAssigned: false,
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let urlValues = {};
    for (const [key, value] of urlParams) {
      if (value === 'true' || value === 'false') {
        urlValues[key] = !!value;
      } else if (key === 'startDate') {
        urlValues[key] = value;
      } else if (key === 'reservationStatus') {
        urlValues[key] = value === '' ? 0 : parseInt(value);
      } else {
        urlValues[key] = value === '' ? null : value;
      }
    }

    if (
      Object.entries(filters)
        .sort()
        .toString() !==
      Object.entries(urlValues)
        .sort()
        .toString()
    ) {
      setFilters(urlValues);
      setInitialValues(urlValues);
      setCustomStartDate(moment(urlValues.startDate));
      setCustomEndDate(moment(urlValues.endDate));
    }
  });

  useEffect(() => {
    if (!sessionFilters) sessionStorage.setItem('filters', JSON.stringify(initialValues));
    else {
      setFilters(sessionFilters);
      setInitialValues(sessionFilters);
      setCustomStartDate(moment(sessionFilters.startDate));
      setCustomEndDate(moment(sessionFilters.endDate));
      handleSubmit(sessionFilters);
    }
  }, [JSON.stringify(sessionFilters)]);

  useEffect(() => {
    let checkInOutDate = '';
    if (filters.startDate) {
      checkInOutDate = getDateSelection(filters.startDate, filters.endDate);
    }
    setInitialValues({ ...initialValues, ...filters, checkInOutDate });
  }, [JSON.stringify(filters)]);

  const getDateSelection = (startDate, endDate) => {
    const diff = moment(endDate).diff(moment(startDate), 'days');
    const isStartDateToday = moment().isSame(moment(startDate), 'day');
    const isStartDateTomorrow = moment()
      .add(1, 'day')
      .isSame(moment(startDate), 'day');
    const isTodayOnly = diff === 0 && isStartDateToday;
    if (isTodayOnly) {
      return TODAY;
    } else if (isStartDateTomorrow && diff === 0) {
      return TOMORROW;
    } else if (diff === 0) {
      return SINGLE_DAY;
    }
    return CUSTOM;
  };

  const getDateRange = dateRange => {
    switch (dateRange) {
      case TODAY:
        setCustomStartDate(moment().startOf('day'));
        setCustomEndDate(moment().endOf('day'));
        return {
          startDate: moment()
            .startOf('day')
            .format('YYYY-MM-DD'),
          endDate: moment()
            .endOf('day')
            .format('YYYY-MM-DD'),
          partialDateMatching: true
        };
      case TOMORROW:
        setCustomStartDate(
          moment()
            .add(1, 'days')
            .startOf('day')
        );
        setCustomEndDate(
          moment()
            .add(1, 'days')
            .endOf('day')
        );
        return {
          startDate: moment()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment()
            .add(1, 'days')
            .endOf('day')
            .format('YYYY-MM-DD'),
          partialDateMatching: true
        };
      case SINGLE_DAY:
        return {
          startDate: customStartDate ? customStartDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate: customEndDate ? customEndDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          partialDateMatching: true
        };
      case CUSTOM:
        return {
          startDate: customStartDate ? customStartDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate: customEndDate ? customEndDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          partialDateMatching: false
        };
      default:
        return '';
    }
  };

  const handleDateRange = ({ startDate, endDate }) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };

  const handleSingleDate = date => {
    setCustomStartDate(date);
    setCustomEndDate(date);
  };

  const getQueryString = values => {
    let queryString = '/admin/orders?';
    for (let [key, value] of Object.entries(values)) {
      if (key === 'event') {
        value = value.replace('&', '%26');
      }
      queryString += value && typeof key === 'string' && (typeof value === 'string' || typeof value === 'number') ? `${key}=${value}&` : '';
      queryString += value && typeof key === 'string' && typeof value !== 'number' && typeof value !== 'string' ? `${key}=${'true'}&` : '';
    }

    return queryString;
  };

  const handleSubmit = (values, actions) => {
    const dateRange = getDateRange(values.checkInOutDate);
    const filterValues = {};
    const keys = Object.keys(values);
    for (const key of keys) {
      if (values[key] && key !== 'checkInOutDate') {
        filterValues[key] = values[key];
      }
    }
    const filtersForQuery = { ...filterValues, ...dateRange };
    setInitialValues(values);
    sessionStorage.setItem('filters', JSON.stringify(filtersForQuery));
    setFilters(filtersForQuery);

    history.pushState({ type: 'Filter' }, 'page 2', getQueryString(filtersForQuery));
    actions?.setSubmitting(false);
  };

  const handleClear = () => {
    const filterValues = {
      user: '',
      renterEmail: '',
      renterPhone: '',
      orderId: '',
      stallName: '',
      rvSpotName: '',
      event: '',
      checkInOutDate: '',
      group: '',
      reservationStatus: 0,
      hasStalls: false,
      hasRVs: false,
      hasAddOns: false,
      hasSpecialReqs: false,
      spacesNeedAssignment: false,
      allSpacesAssigned: false,
      startDate: '',
      endDate: ''
    };

    setCheckInOutFilter({ checkInOnly: false, checkOutOnly: false });
    setFilters(filterValues);
    setInitialValues(filterValues);
    sessionStorage.setItem('filters', JSON.stringify(filterValues));
    history.pushState({ type: 'FilterClear' }, 'page 2', '/admin/orders?');
  };

  const handleFieldClear = (e, name, form) => {
    form.setFieldValue(name, '');
    const values = { ...form.values };
    delete values[name];
    if (name === 'checkInOutDate') {
      delete values['startDate'];
      delete values['endDate'];
      form.setFieldValue('startDate', '');
      form.setFieldValue('endDate', '');
      setCheckInOutFilter({ checkInOnly: false, checkOutOnly: false });
    } else if ((checkInOutFilter.checkInOnly || checkInOutFilter.checkOutOnly) && !orders.length) {
      setCheckInOutFilter({ checkInOnly: false, checkOutOnly: false });
    }
    handleSubmit(values);
  };

  const handleCloseFilters = () => {
    isMobile && closeFilters && closeFilters();
  };

  return (
    <TableFilters isMobile={isMobile} className={`${isMobile && !open ? 'closed' : ''}`}>
      <div className="table-filters">
        {isMobile && (
          <button type="prymary" className="close-button" onClick={handleCloseFilters}>
            <CloseIcon />
          </button>
        )}
        <div className="header-container">
          <HeadingFour label="Filters" className="" />
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {(
            { values, resetForm } // eslint-disable-line
          ) => (
            <Form>
              <div className="input-group">
                <Field name="user" fieldlabel="renter" component={TextFilter} value={values.user || ''} clear={handleFieldClear} />
                <Field name="renterEmail" fieldlabel="renter email" component={TextFilter} value={values.renterEmail || ''} clear={handleFieldClear} />
                <Field
                  name="renterPhone"
                  fieldlabel="renter phone"
                  component={TextFilter}
                  value={formatPhoneNumber(values.renterPhone) || ''}
                  clear={handleFieldClear}
                />
                <Field name="orderId" fieldlabel="order ID" component={TextFilter} value={values.orderId || ''} clear={handleFieldClear} />
                <Field
                  name="stallName"
                  fieldlabel="stall"
                  component={TextFilter}
                  value={values.stallName || ''}
                  clear={handleFieldClear}
                  disabled={!!values.rvSpotName}
                />
                <Field
                  name="rvSpotName"
                  fieldlabel="rv spot"
                  component={TextFilter}
                  value={values.rvSpotName || ''}
                  clear={handleFieldClear}
                  disabled={!!values.stallName}
                />
                <Field name="event" fieldlabel="event" component={TextFilter} value={values.event || ''} clear={handleFieldClear} />
                <Field name="group" fieldlabel="group name" component={TextFilter} value={values.group || ''} clear={handleFieldClear} />
                <Field
                  name="checkInOutDate"
                  fieldlabel="check in / check out date"
                  component={SelectFilter}
                  value={values.checkInOutDate || ''}
                  clear={handleFieldClear}
                  options={[
                    { value: TODAY, name: 'Today' },
                    { value: TOMORROW, name: 'Tomorrow' },
                    { value: SINGLE_DAY, name: 'Single Day' },
                    { value: CUSTOM, name: 'Custom' }
                  ]}
                />
                {values.checkInOutDate === SINGLE_DAY && <Calendar plural={false} selectableRangeStart={'2019-10-30'} cb={handleSingleDate} disabled={false} />}
                {values.checkInOutDate === 'custom' && (
                  <Calendar
                    selectableRangeStart={'2019-10-30'}
                    startDatePlaceholder={'START'}
                    endDatePlaceholder={'END'}
                    rangeEndWithoutLimits={true}
                    plural={true}
                    cb={handleDateRange}
                    disabled={false}
                    defaultValue={[null, null]}
                  />
                )}
                <Field
                  name="reservationStatus"
                  fieldlabel="status"
                  component={SelectFilter}
                  value={values.reservationStatus || ''}
                  clear={handleFieldClear}
                  options={[
                    { value: 1, name: 'Reserved' },
                    { value: 2, name: 'Checked In' },
                    { value: 3, name: 'Departed' },
                    { value: 4, name: 'Canceled' },
                    { value: 5, name: 'New Order' },
                    { value: 6, name: 'Confirmed' },
                    { value: 7, name: 'Delivered' }
                  ]}
                />
              </div>
              <div className="checkbox-group">
                <h3>included in reservation</h3>
                <Field name="hasStalls" label="Stalls" component={CheckboxFilter} checked={values.hasStalls || false} />
                <Field name="hasRVs" label="RVs" component={CheckboxFilter} checked={values.hasRVs || false} />
                <Field name="hasAddOns" label="Add Ons" component={CheckboxFilter} checked={values.hasAddOns || false} />
                <Field name="hasSpecialReqs" label="Special Requests" component={CheckboxFilter} checked={values.hasSpecialReqs || false} />
              </div>
              <div className="checkbox-group">
                <h3>stall / rv spot assignment</h3>
                <Field name="spacesNeedAssignment" label="Needs Assignment" component={CheckboxFilter} checked={values.spacesNeedAssignment || false} />
                <Field name="allSpacesAssigned" label="Stall/Spot Assigned" component={CheckboxFilter} checked={values.allSpacesAssigned || false} />
              </div>
              <div className="button-group">
                <ButtonFilter
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    tableContextRef.clearAllSelections();
                    handleCloseFilters();
                  }}
                  fullWidth>
                  apply filters
                </ButtonFilter>
                <ButtonFilter
                  color="primary"
                  fullWidth
                  onClick={() => {
                    handleClear();
                    resetForm();
                    handleCloseFilters();
                  }}>
                  clear filters
                </ButtonFilter>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </TableFilters>
  );
};

const TableFilters = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    `
      position: absolute;
      z-index: 999;
      background: rgba(0, 0, 0, 0.50);
      top: 6%;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
    `}

  &.closed {
    left: -9999px;
    opacity: 0;
    right: auto;
  }

  .table-filters {
    background: white;
    width: 300px;
    text-align: left;
    margin-right: 12px;
    height: calc(100% + 33px);
    overflow-y: auto;
    box-shadow: 0 2px 6px rgba(17, 24, 31, 0.03), 0 2px 3px rgba(17, 24, 31, 0.1);
    position: relative;

    ${({ isMobile }) =>
      isMobile &&
      `
        width: 90%;
        height: 100%;
        margin: 0 auto;
    `}

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      outline: none;
      border: none;
    }

    .checkbox-group {
      padding: 0 20px;
    }

    .input-group {
      padding: 0 20px;
    }

    .header-container {
      margin: 20px;
    }

    .calendar-picker {
      margin-bottom: 20px;
    }

    .DateInput {
      width: 90px;

      .DateInput_input {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .DateInput_input {
      width: 90px;
      padding: 11px 4px 9px;
    }

    .checkbox-group {
      margin-bottom: 25px;
    }

    h3 {
      text-transform: uppercase;
      font-family: 'IBMPlexSans-Bold';
      font-size: 12px;
      font-weight: 400;
      color: #4f5d6d;
      margin-top: 0;
      margin-bottom: 3px;
    }

    .MuiButton-root {
      margin: 0 0 14px 0;
      font-size: 16px;
    }

    .button-group {
      position: sticky;
      bottom: 0;
      background: white;
      box-shadow: 0 2px 6px rgba(17, 24, 31, 0.08), 0 2px 6px rgba(17, 24, 31, 0.3);
      padding: 16px 20px 0 20px;
    }
  }
`;

export default TableFiltersBase;
