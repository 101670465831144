import gql from 'graphql-tag';

export const UPLOAD_EVENT_MAP = gql`
  mutation uploadEventMap($input: UploadEventFileInput) {
    uploadEventMap(input: $input) {
      success
      error
      documentId
    }
  }
`;
