import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import { SnackbarContext } from '../../../../store/SnackbarContext';
import WarningModal from '../../../../components/WarningModal/WarningModal';

import { UPLOAD_EVENT_MAP } from '../../../../mutations/UploadEventMap';

type MapType = {
  description: string;
  id: string | number;
  url: string;
  name: string;
};

type VenueMapUploadPropsType = {
  reloadMaps: () => void;
  venueId: string;
  maps: MapType[];
};

export const VenueMapUpload: React.FC<VenueMapUploadPropsType> = ({ reloadMaps, venueId, maps }) => {
  const [fileObj, setFileObj] = useState<null | File>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const [uploadEventMap, { data, loading, error }] = useMutation(UPLOAD_EVENT_MAP);

  const mapNamesList = maps?.map((file: MapType) => file.name);
  const { setFieldValue } = useFormikContext();

  const handleSetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files && e.currentTarget.files[0];
    if (file?.type !== 'application/pdf') {
      showSnackbar('File type must be PDF', { error: true });
    } else {
      setFileObj(e.currentTarget.files && e.currentTarget.files[0]);
    }
  };

  const uploadEventMapCall = useCallback(async () => {
    await uploadEventMap({
      variables: {
        input: {
          venueId: venueId,
          file: fileObj
        }
      }
    });
  }, [venueId, fileObj]);

  useEffect(() => {
    if (fileObj) {
      if (mapNamesList.includes(fileObj.name) && !overwrite) setIsModalOpen(true);
      else {
        uploadEventMapCall();
      }
    }
  }, [JSON.stringify(fileObj), overwrite]);

  useEffect(() => {
    if (loading) {
      showSnackbar('Uploading file...', { error: false, duration: 5000 });
    } else {
      if (error) {
        showSnackbar('File uploaded failed', { error: true });
      }

      if (!loading && !error && data) {
        showSnackbar('File uploaded successfully', { error: false });
        reloadMaps();
        setFieldValue('venueMap', data?.uploadEventMap?.documentId);
      }

      setFileObj(null);
      setOverwrite(false);
    }
  }, [data, loading, error]);

  const handleModalCancel = () => {
    setFileObj(null);
    setIsModalOpen(false);
  };

  return (
    <div className={'upload-venue-agreement'}>
      <WarningModal
        isOpen={isModalOpen}
        hideWarningIcon={false}
        handleClose={handleModalCancel}
        onCancel={handleModalCancel}
        continueLabel="REPLACE"
        header="ARE YOU SURE?"
        text={`${fileObj?.name} already exists. Do you want to replace it? Replacing it will overwrite its current contents.`}
        onContinue={() => {
          setOverwrite(true);
          setIsModalOpen(false);
        }}
      />
      or{' '}
      <label htmlFor="venue-map-file">
        upload new map
        <div style={{ display: 'none' }}>
          <input onChange={e => handleSetFile(e)} value="" accept="application/pdf" className="map-input" id="venue-map-file" multiple type="file" />
        </div>
      </label>
    </div>
  );
};
