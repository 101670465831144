import React from 'react';
import { useFormikContext } from 'formik';
import UpdatedChip from '../../Admin/shared/UpdatedChip';
import { StyledReadOnlyDeliveryNotes } from './deliveryNotesStyles';

const ReadOnlyDeliveryNotes = ({ showChip, highlighted }) => {
  const { values } = useFormikContext();

  const getUpdatedChip = () => {
    return deliveryNotesIsUpdated() && showChip ? <UpdatedChip /> : '';
  };

  const getHighLightedClass = () => {
    return deliveryNotesIsUpdated() && highlighted ? 'highlighted' : '';
  };

  const deliveryNotesIsUpdated = () => values?.addOnDeliveryNotes !== values?.initialOrder?.addOnDeliveryNotes;

  return (
    <StyledReadOnlyDeliveryNotes>
      <div className="header-container">
        <h4>Delivery Notes</h4>
        {getUpdatedChip()}
      </div>
      <span className={getHighLightedClass()}>{values.addOnDeliveryNotes}</span>
    </StyledReadOnlyDeliveryNotes>
  );
};

export default ReadOnlyDeliveryNotes;
