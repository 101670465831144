import gql from 'graphql-tag';

export const SEND_EVENT_PASSWORD = gql`
  mutation sendEventPassword($input: EventPasswordInput) {
    sendEventPassword(input: $input) {
      success
      error
      token
    }
  }
`;
