import gql from 'graphql-tag';

export const CREATE_STALLS = gql`
  mutation createStalls($input: CreateStallsInput) {
    createStalls(input: $input) {
      stalls {
        id
        name
        building {
          id
          name
          venue {
            id
            name
          }
        }
      }
    }
  }
`;
