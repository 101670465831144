import gql from 'graphql-tag';

export const UPDATE_BUILDING = gql`
  mutation updateBuilding($input: UpdateBuildingInput) {
    updateBuilding(input: $input) {
      name
      id
      venue {
        id
      }
    }
  }
`;
