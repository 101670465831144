import gql from 'graphql-tag';

export const GET_MAPS_BY_VENUE_ID = gql`
  query getMap($venueId: ID) {
    maps(venueId: $venueId) {
      id
      name
      description
      url
      type
      buildings
      venue {
        id
      }
    }
  }
`;
