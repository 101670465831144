import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import Button from '../../../components/Button';
import { MapStyles } from '../Shared/MapStyles';
import { initialValues } from '../Shared/initialValues';
import { CreateValidationSchema } from './CreateValidationSchema';
import { MapForm } from '../Shared/MapForm';
import { MapFormFields } from '../Shared/MapFormFields';
import { CREATE_MAP } from 'Mutations/CreateMap';

const CreateMap = ({ className = '' }) => {
  const [createMap, { data, called, loading, error }] = useMutation(CREATE_MAP);
  const [hasMapErrors, setHasMapErrors] = useState(false);

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={CreateValidationSchema}
      onSubmit={async values => {
        await createMap({
          variables: {
            input: {
              name: values.mapName,
              description: values.mapDescription,
              type: values.mapType,
              venueId: values.venueId,
              file: values.file,
              buildings: values.buildings
            }
          }
        });
      }}>
      {({ isSubmitting, dirty, isValid, submitForm }) => {
        return (
          <MapForm className={className} isLoading={false}>
            <>
              <div className={'title'}>CREATE MAP</div>
              <MapFormFields setHasMapErrors={setHasMapErrors} />
              <SubmitButton
                className="form-button"
                primary
                variant="contained"
                size="small"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid || loading || called || hasMapErrors}
                data-testid="create-map-button"
                onClick={e => {
                  e.preventDefault();
                  submitForm();
                }}>
                CREATE
              </SubmitButton>
              {error && <div>Ups...There was an error creating the map</div>}
              {loading && <div>Uploading and creating the map...</div>}
              {data && <div>Success</div>}
            </>
          </MapForm>
        );
      }}
    </Formik>
  );
};

const SubmitButton = styled(Button)`
  &&& {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export default MapStyles(CreateMap);
