import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Grid, Modal } from '@material-ui/core';
import { HeadingFour } from 'Components/Headings';
import ContextSnackbar from 'Components/Snackbar';
import { UpdateAddonSchema } from 'Pages/SuperAdmin/Addons/helpers';
import { Field, Form, Formik } from 'formik';
import { FormSelect } from 'Components/Select';
import { FormikField } from 'Components/Fields';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ADDON } from 'Mutations/updateAddon';
import { SnackbarContext } from 'Store/SnackbarContext';
const AddonsTable = ({ addons, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const [updateAddon, { data, loading, error }] = useMutation(UPDATE_ADDON);

  useEffect(() => {
    if (data) {
      showSnackbar('Addon saved successfully', {
        error: false,
        duration: 5000
      });
      setIsOpen(false);
      refetch && refetch();
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to save Add on`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  if (!addons) {
    return null;
  }

  if (addons && addons.length === 0) {
    return <div>No addons</div>;
  }

  const handleEdit = addonId => {
    const addon = addons.find(addon => addon.id === addonId);
    if (addon) {
      setSelectedAddon(addon);
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ContextSnackbar />
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Unit name</th>
            <th>Description</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {addons.map(addon => {
            return (
              <tr>
                <td>{addon.id}</td>
                <td>{addon.name}</td>
                <td>{addon.unitName}</td>
                <td>{addon.description}</td>
                <td>
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleEdit(addon.id);
                    }}>
                    <span>EDIT</span>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal open={isOpen} onClose={handleClose} disableAutoFocus={true} disableRestoreFocus={true}>
        <Card className="modal-card">
          <div className="header-container">
            <HeadingFour label="EDIT ADD ON" />
          </div>
          <Formik
            initialValues={{
              name: selectedAddon.name,
              description: selectedAddon.description,
              unitName: selectedAddon.unitName
            }}
            onSubmit={async values =>
              await updateAddon({
                variables: {
                  input: {
                    id: selectedAddon.id,
                    name: values.name,
                    description: values.description,
                    unitName: values.unitName
                  }
                }
              })
            }
            validationSchema={UpdateAddonSchema}>
            {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field autoComplete="none" label="NAME" type="text" name="name" variant="filled" component={FormikField} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="unitName" autoComplete="none">
                        {({ field, meta }) => (
                          <FormSelect
                            {...field}
                            {...meta}
                            selectedOption={values.unitName || selectedAddon.unitName}
                            label="UNIT NAME"
                            cb={e => {
                              setFieldValue('unitName', e.target.value);
                            }}
                            className="unit-name-select"
                            options={[
                              { label: 'bag', value: 'bag' },
                              { label: 'night', value: 'night' },
                              { label: 'panel', value: 'panel' },
                              { label: 'bale', value: 'bale' },
                              { label: 'dry', value: 'dry' },
                              { label: 'chair', value: 'chair' },
                              { label: 'pipe', value: 'pipe' },
                              { label: 'table', value: 'table' },
                              { label: 'outlet', value: 'outlet' },
                              { label: 'gallon', value: 'gallon' },
                              { label: 'service', value: 'service' },
                              { label: 'stall mat rental', value: 'stall mat rental' },
                              { label: 'unit', value: 'unit' },
                              { label: 'trailer', value: 'trailer' }
                            ]}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <div className="flex-button-wrapper">
                    <Button
                      className="form-button"
                      primary
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid || loading}
                      data-testid="create-group-save">
                      SAVE
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Modal>
    </>
  );
};

export default AddonsTable;
