import styled from 'styled-components';

export const StyledReadOnlyDeliveryNotes = styled.div`
  .header-container {
    margin-top: 18px;

    h4 {
      font-family: 'IBMPlexSans-SemiBold';
      font-size: 18px;
      letter-spacing: 0.79px;
      margin-bottom: 0;
      display: inline;
    }

    div {
      display: inline !important;
    }
  }

  span {
    font-family: 'IBMPlexSans-Regular';
    font-size: 1rem;

    &.highlighted {
      display: block;
      background-color: #f7e569;
      width: fit-content;
    }
  }
`;

export const StyledDeliveryNotes = styled.div`
  .header-container {
    display: flex;

    h4 {
      margin: 0;
      margin-right: 6px;
      font-size: 18px;
      font-family: 'IBMPlexSans-SemiBold';
    }
  }
`;
