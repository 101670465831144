import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import { withSnackbarContextActions } from 'Store/SnackbarContext';
import { reportGraphqlError } from 'Helpers/graphqlResponseUtil';

export const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      error
      success
    }
  }
`;

export const withUpdateOrder = graphql(UPDATE_ORDER, {
  props: ({ mutate }) => ({
    updateOrder: input => mutate({ variables: { input } })
  }),
  options: props => ({
    onCompleted: async data => {
      if (data.updateOrder && (data.updateOrder.error || !data.updateOrder.success)) {
        reportGraphqlError(props.showSnackbar, data.updateOrder.error, data.updateOrder.error);
        return;
      }

      if (!data.updateOrder) {
        reportGraphqlError(props.showSnackbar, 'There was an error updating the order.', 'There was an error updating the order.');
        return;
      }

      // Success condition
      props.showSnackbar('Order successfully updated');
    },
    onError: async error => {
      reportGraphqlError(props.showSnackbar, 'There was an error updating the order', error);
    }
  })
});

export default compose(withRouter, withSnackbarContextActions, withUpdateOrder);
