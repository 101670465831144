import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { HeadingFour } from 'Components/Headings';
import { Field, useFormikContext } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxThemed from '../Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import { BlueTooltip } from 'src/containers/Order/Admin/Edit/orderHistoryHelpers';
import { getOtherRVProductsWithSameLot, getRVProductDateRange, isOverlapping } from './rvFlippingHelper';
import { Notice } from '../../../../components/Alerts';
import styled from 'styled-components';

const RvFlipping = ({ index, isDisabled, lotsWithFlipping, selectedLotsOverlapping }) => {
  const { setFieldValue, values, initialValues } = useFormikContext();
  const flippingDisabled = values.rvs[index].flippingDisabled;
  const currentProductId = values.rvs[index].id;
  const selectedLot = values.rvs[index];
  const [lotWithMismatchFlipIsSelected, setLotWithMismatchFlipIsSelected] = useState(true);
  const [warning, setWarning] = useState('');

  const checkIfSelectedLotMismatchesFlip = () => {
    return checkIfLotMismatchesDBLot(selectedLot.rvLotId) || checkIfLotMismatchesOverlappingLot();
  };

  const checkIfLotMismatchesDBLot = lotId => {
    const lot = lotsWithFlipping.find(l => l.id === lotId);
    if (!lot) {
      return false;
    }
    return flipMismatches(lot);
  };

  const flipMismatches = lotWithFlipping => {
    const rvProducts = excludeCurrentProduct(lotWithFlipping);
    return rvProducts.length > 0 && rvProducts[0].flip === !flippingDisabled;
  };

  const excludeCurrentProduct = lotWithFlipping => {
    return lotWithFlipping?.rvProducts.filter(rvp => rvp.id !== currentProductId);
  };

  const checkIfLotMismatchesOverlappingLot = () => {
    const rvProductsWithSameLot = getOtherRVProductsWithSameLot(values.rvs, index, selectedLot.rvLotId);
    const overlappingRVProductsWithDifferentFlip = getOverlappingRVProductsWithDifferentFlip(rvProductsWithSameLot);

    return overlappingRVProductsWithDifferentFlip && overlappingRVProductsWithDifferentFlip.length > 0;
  };

  const getOverlappingRVProductsWithDifferentFlip = rvProductsWithSameLot => {
    const [currentProductStart, currentProductEnd] = getRVProductDateRange(selectedLot.dateRange);
    const currentProductRange = moment.range(currentProductStart, currentProductEnd);

    return getProductsOverlappingWithDiffFlip(rvProductsWithSameLot, currentProductRange);
  };

  const getProductsOverlappingWithDiffFlip = (rvProducts, currentProductRange) => {
    const rvProductsOverlapping = rvProducts.filter(rvProduct => {
      return isOverlapping(rvProduct, currentProductRange) && haveSameFlip(rvProduct, selectedLot);
    });

    return rvProductsOverlapping;
  };

  const haveSameFlip = (product1, product2) => {
    return product1.flippingDisabled === product2.flippingDisabled;
  };

  const getWarning = () => {
    const enableDisabled = flippingDisabled ? 'enable' : 'disable';
    return (
      <span>
        To {enableDisabled} flipping please select other RV Lot or <ClearButton onClick={clearSelection}>Clear Selection</ClearButton>
      </span>
    );
  };

  const clearSelection = () => {
    setFieldValue(`rvs[${index}].rvLotId`, null);
  };

  const isBookedWithFlippingEnabled = () => {
    const isBooked = values.rvs[index].booked;
    const initialRvProduct = initialValues?.rvs?.find(s => s.id === currentProductId);
    const hasFlippingEnabled = initialRvProduct && !initialRvProduct.flippingDisabled;

    return isBooked && hasFlippingEnabled;
  };

  const getTooltipText = () => {
    return isBookedWithFlippingEnabled()
      ? 'This function is now unable to be edited. please reach out to support if you need assistance.'
      : 'RVs can only be sold once during an event';
  };

  useEffect(() => {
    const selectedLotMismatchesFlip = checkIfSelectedLotMismatchesFlip();

    setLotWithMismatchFlipIsSelected(selectedLotMismatchesFlip);
    setWarning(getWarning());
  }, [selectedLot, flippingDisabled, lotsWithFlipping, selectedLotsOverlapping]);

  return (
    <div className="flipping">
      <HeadingFour className="section-label" label="Flipping" />
      <div className="checkbox-container" style={{ display: 'flex', alignItems: 'center' }}>
        <Field name={`rvs[${index}].flippingDisabled`} type="checkbox">
          {({ field }) => (
            <FormControlLabel
              aria-label="Disable Flip"
              control={
                <CheckboxThemed
                  name={field.name}
                  onChange={e => {
                    setFieldValue(field.name, e.target.checked);
                  }}
                  disabled={isDisabled || lotWithMismatchFlipIsSelected || isBookedWithFlippingEnabled()}
                  checked={flippingDisabled}
                />
              }
              label="Disable Flip"
            />
          )}
        </Field>
        <BlueTooltip placement="top" arrow title={getTooltipText()}>
          <InfoIcon fontSize="medium" />
        </BlueTooltip>
      </div>
      {lotWithMismatchFlipIsSelected && !isBookedWithFlippingEnabled() && <Notice label={warning} />}
    </div>
  );
};

export default RvFlipping;

const ClearButton = styled.a`
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
`;
