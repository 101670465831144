import gql from 'graphql-tag';

export const VENUE_AGREEMENT = gql`
  query venue($id: ID) {
    venue(id: $id) {
      venueAgreements {
        id
        name
        description
        url
      }
    }
  }
`;
