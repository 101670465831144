import React, { memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import EditMap from '../../../containers/Map/Edit';
import ContextSnackbar from '../../../components/Snackbar';

const SuperAdminEditMapBase = ({ className }) => (
  <>
    <section className={className}>
      <ContextSnackbar />
      <EditMap />
    </section>
  </>
);

const SuperAdminEditMap = styled(SuperAdminEditMapBase)`
  margin: 85px 50px 50px;
`;

// eslint-disable-next-line prettier/prettier
export default compose(withRouter)(memo(SuperAdminEditMap));
