import gql from 'graphql-tag';

export const VENUES = gql`
  query Venues {
    venues {
      description
      name
      id
      buildings {
        id
        name
      }
      rvLots {
        id
        name
        description
        sewer
        water
        power
      }
      events {
        startDate
        endDate
        description
        name
        id
      }
      addOns {
        id
        name
        unitName
        description
      }
    }
  }
`;
