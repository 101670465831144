import React, { useMemo } from 'react';

import { getValueByPropPath } from 'Utils/objectHelpers';
import { pluralizeName } from '../../../Order/Renter/Create/TicketOrderItem';

export const SidePanelAddOns = ({ addOnOrderItems, children }) => {
  const items = addOnOrderItems || [];
  const columns = useMemo(
    () =>
      items.map(orderItem => {
        const { addOnProduct, quantity } = orderItem;
        const unit = pluralizeName(getValueByPropPath(addOnProduct, 'addOn.unitName', ''), quantity);
        const addOnName = pluralizeName(getValueByPropPath(addOnProduct, 'addOn.name', ''), quantity);
        return {
          title: addOnName.toUpperCase(),
          text: `${quantity} ${unit}`
        };
      }),
    [JSON.stringify(addOnOrderItems)]
  );
  return <>{children(columns)}</>;
};
