import gql from 'graphql-tag';

export const SET_USER_SETTINGS = gql`
  mutation setUserSettings($input: UserSettingsInput) {
    setUserSettings(input: $input) {
      success
      error
    }
  }
`;
