import gql from 'graphql-tag';

export const UPDATE_RVLOT = gql`
  mutation updateRVlot($input: UpdateRVlotInput) {
    updateRVlot(input: $input) {
      id
      name
      description
      power
      sewer
      water
      venue {
        id
      }
    }
  }
`;
