import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { VENUES } from '../../../queries/SuperAdmin';
import { SnackbarContext } from '../../../store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { initialValues, CreateAddonSchema } from './helpers';
import { CREATE_ADDON } from '../../../mutations/CreateAddon';
import { HeadingFive } from 'Components/Headings';
import './Addons.scss';
import { FormSelect } from 'Components/Select';

const getOptions = venues => {
  if (!venues) return null;

  return venues.map(venue => {
    return {
      label: venue.name,
      value: venue.id
    };
  });
};

const CreateAddonForm = () => {
  const [venues, setVenues] = useState([]);
  const { data: venuesData } = useQuery(VENUES);
  const { showSnackbar } = useContext(SnackbarContext);

  const [createAddon, { data, loading, error }] = useMutation(CREATE_ADDON);
  const history = useHistory();

  useEffect(() => {
    if (venuesData) setVenues(venuesData.venues);
  }, [venuesData]);

  useEffect(() => {
    if (data) {
      showSnackbar('Addon created successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.ADDONS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to create Add on`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  return (
    <div className="create-addon-container">
      <ContextSnackbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async values =>
          await createAddon({
            variables: {
              input: {
                venueId: values.venueId,
                name: values.name,
                description: values.description,
                unitName: values.unitName
              }
            }
          })
        }
        validationSchema={CreateAddonSchema}>
        {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="ADD ON INFORMATION" />
              <Field name="venueId" autoComplete="none">
                {({ field, meta }) => (
                  <FormSelect
                    {...field}
                    {...meta}
                    selectedOption={values.venueId}
                    label="VENUE"
                    cb={e => {
                      const venue = venues.filter(venue => venue.id === e.target.value)[0];
                      if (venue) {
                        setFieldValue('venueId', venue.id);
                      }
                    }}
                    className="venue-select"
                    options={getOptions(venues)}
                  />
                )}
              </Field>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="NAME" type="text" name="name" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="unitName" autoComplete="none">
                    {({ field, meta }) => (
                      <FormSelect
                        {...field}
                        {...meta}
                        selectedOption={values.unitName}
                        label="UNIT NAME"
                        cb={e => {
                          setFieldValue('unitName', e.target.value);
                        }}
                        className="unit-name-select"
                        options={[
                          { label: 'bag', value: 'bag' },
                          { label: 'night', value: 'night' },
                          { label: 'panel', value: 'panel' },
                          { label: 'bale', value: 'bale' },
                          { label: 'dry', value: 'dry' },
                          { label: 'chair', value: 'chair' },
                          { label: 'pipe', value: 'pipe' },
                          { label: 'table', value: 'table' },
                          { label: 'outlet', value: 'outlet' },
                          { label: 'gallon', value: 'gallon' },
                          { label: 'service', value: 'service' },
                          { label: 'stall mat rental', value: 'stall mat rental' },
                          { label: 'unit', value: 'unit' },
                          { label: 'trailer', value: 'trailer' }
                        ]}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  SAVE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAddonForm;
