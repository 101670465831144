import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import WarningIcon from '@material-ui/icons/Warning';
import addOnStatus from '../../constants/addOnStatus';

const types = {
  'checked-in': { classType: 'checked-in', text: 'checked in' },
  'partial-check-in': {
    classType: 'partial-check-in',
    text: 'partially checked in'
  },
  reserved: { classType: 'reserved', text: 'reserved' },
  hasMultiple: { classType: 'hasMultiple', text: isMobile ? 'MO' : 'multiple orders' },
  canceled: { classType: 'canceled', text: 'canceled' },
  'need-assignment': {
    classType: 'need-assignment',
    text: 'needs assignment'
  },
  departed: { classType: 'departed', text: 'departed' },
  [addOnStatus.NEW_ORDER.id]: { classType: 'new-order', text: addOnStatus.NEW_ORDER.name },
  [addOnStatus.CONFIRMED.id]: { classType: 'confirmed', text: addOnStatus.CONFIRMED.name },
  [addOnStatus.DELIVERED.id]: { classType: 'delivered', text: addOnStatus.DELIVERED.name }
};

const BadgeBase = props => {
  const badgeType = types[props.type];

  return (
    <Badge className={`badge ${badgeType.classType}`} isMobile={isMobile}>
      {props.type === 'need-assignment' && <WarningIcon />}
      <span>{badgeType.text}</span>
    </Badge>
  );
};

const Badge = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.7px;
  line-height: 17px;
  margin: 0 6px;
  height: max-content;
  border: 1px solid black;
  border-radius: 16px;
  padding: 4.5px 10.5px;
  font-size: 13px;

  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 3px 6px;
    `}

  &.hasMultiple {
    ${({ isMobile }) =>
      isMobile &&
      `
      margin: 0 6px;
    `}
  }

  &.checked-in {
    border-color: #29b490;
    color: #29b490;
  }

  &.partial-check-in {
    border-color: #387fc7;
    color: #387fc7;
  }

  &.reserved {
    border-color: #1c2229;
    color: #1c2229;
  }

  &.hasMultiple {
    color: #576574;
    border-color: #576574;
  }

  &.canceled {
    border-color: #ec494a;
    color: #ec494a;
  }

  &.need-assignment {
    border-color: #ff9e42;
    color: #495868;
    white-space: nowrap;

    svg {
      font-size: 15px;
      color: #ff9e42;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }

  &.departed {
    border-color: #cdd9e7;
    color: #5a6776;
  }

  &.new-order {
    border-color: #ec494a;
    color: #ec494a;
  }

  &.confirmed {
    border-color: #387fc7;
    color: #387fc7;
  }

  &.delivered {
    border-color: #29b490;
    color: #29b490;
  }
`;

export default BadgeBase;
