import React from 'react';

import { BasicInformation } from './BasicInformation';
import { Dates } from './Dates';
import { AddOns } from '../AddOns';
import { Links } from '../Links';
import { BottomNavigation } from '../Navigation/BottomNavigation';
import { useValidations } from '../Form/useValidations';

export const Details = ({ displayNextStep, isEdit }) => {
  const { isDetailsValid: isValid } = useValidations();

  return (
    <>
      <BasicInformation isEdit={isEdit} />
      <Dates isEdit={isEdit} />
      <AddOns isEdit={isEdit} />
      <Links isEdit={isEdit} />
      {displayNextStep && <BottomNavigation next={'stalls'} nextLabel={'NEXT: ADD STALLS'} isValid={isValid} />}
    </>
  );
};
