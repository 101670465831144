const Privacy = () => {
  return (
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      src="https://open-stalls.s3.us-east-2.amazonaws.com/docs/agreements/rodeo-logistics-website-privacy-policy.pdf"
    />
  );
};

export default Privacy;
