import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import WarningModal from '../../../../components/WarningModal/WarningModal';
import { useFormikContext } from 'formik';
import { SnackbarContext } from 'Store/SnackbarContext';
import { UPLOAD_AGREEMENT } from 'Mutations/UploadAgreement';

export const VenueAgreementUpload = ({ agreements, reloadAgreements, venueId }) => {
  const [fileObj, setFileObj] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useFormikContext();

  const [uploadAgreement, { data, loading, error }] = useMutation(UPLOAD_AGREEMENT);

  const agreementNamesList = agreements?.map(file => file.name);

  const handleSetFile = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if (validity?.valid) {
      if (file?.type !== 'application/pdf') {
        showSnackbar('File type must be PDF', { error: true });
      } else {
        setFileObj(file);
      }
    }
  };

  const handleModalCancel = () => {
    setFileObj(null);
    setIsModalOpen(false);
  };

  const uploadAgreementCall = useCallback(async () => {
    await uploadAgreement({
      variables: {
        input: {
          venueId: venueId,
          file: fileObj
        }
      }
    });
  }, [venueId, fileObj]);

  useEffect(() => {
    if (fileObj) {
      if (agreementNamesList.includes(fileObj.name) && !overwrite) setIsModalOpen(true);
      else {
        uploadAgreementCall();
      }
    }
  }, [JSON.stringify(fileObj), overwrite]);

  useEffect(() => {
    if (loading) {
      showSnackbar('Uploading file...', { error: false, duration: 5000 });
    } else {
      if (error) {
        showSnackbar('File uploaded failed', { error: true });
      }

      if (!loading && !error && data) {
        showSnackbar('File uploaded successfully', { error: false });
        reloadAgreements();
        setFieldValue('venueAgreement', data?.uploadAgreement?.documentId);
      }

      setFileObj(null);
      setOverwrite(false);
    }
  }, [data, loading, error]);

  return (
    <div className={'upload-venue-agreement'}>
      <WarningModal
        isOpen={isModalOpen}
        handleClose={handleModalCancel}
        onCancel={handleModalCancel}
        continueLabel="REPLACE"
        header="ARE YOU SURE?"
        text={`${fileObj?.name || ''} already exists. Do you want to replace it? Replacing it will overwrite its current contents.`}
        onContinue={() => {
          setOverwrite(true);
          setIsModalOpen(false);
        }}
      />
      or{' '}
      <label htmlFor="venue-agreement-file">
        upload new agreement
        <input onChange={e => handleSetFile(e)} value="" accept="application/pdf" className="agreement-input" id="venue-agreement-file" multiple type="file" />
      </label>
    </div>
  );
};
