import gql from 'graphql-tag';

export const USER_SETTINGS_QUERY = gql`
  query GetGroupLeaders {
    userSettings {
      reservationEmail
      addOnReservationEmail
    }
  }
`;
