import gql from 'graphql-tag';

export const RV_AVAILABILITY_BY_PRODUCT_IDS = gql`
  query RvAvailabilityByProductIds($input: RvAvailabilityByProductIdsInput) {
    rvAvailabilityByProductIds(input: $input) {
      availableSpaces {
        id
        name
        rvLot {
          id
          name
        }
      }
    }
  }
`;
