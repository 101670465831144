import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { SnackbarContext } from '../../../store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { initialValues, CreateStallsSchema } from './helpers';
import { CREATE_STALLS } from '../../../mutations/CreateStalls';
import { HeadingFive } from 'Components/Headings';
import './Stalls.scss';
import { FormSelect } from 'Components/Select';

const CreateStallsForm = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [createStalls, { data, loading, error }] = useMutation(CREATE_STALLS);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      showSnackbar('Stalls created successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.STALLS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to create stalls`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  return (
    <div className="create-stalls-container">
      <ContextSnackbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async values =>
          await createStalls({
            variables: {
              input: {
                amount: Number(values.amount),
                stallLetter: values.stallLetter,
                firstStallNumber: Number(values.firstStallNumber),
                buildingId: values.buildingId,
                status: values.status,
                description: values.description
              }
            }
          })
        }
        validationSchema={CreateStallsSchema}>
        {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="STALLS INFORMATION" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="HOW MANY STALLS?" type="text" name="amount" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="STALL LETTER" type="text" name="stallLetter" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="FIRST STALL NUMBER" type="number" name="firstStallNumber" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="BUILDING ID" type="number" name="buildingId" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                </Grid>
              </Grid>
              <Field name="status" autoComplete="none">
                {({ field, meta }) => (
                  <FormSelect
                    {...field}
                    {...meta}
                    selectedOption={values.status}
                    label="STATUS"
                    cb={e => {
                      setFieldValue('status', e.target.value);
                    }}
                    className="venue-select"
                    options={[
                      { label: 'Clean', value: 'clean' },
                      { label: 'Occupied', value: 'occupied' },
                      { label: 'Dirty', value: 'dirty' }
                    ]}
                  />
                )}
              </Field>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  SAVE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateStallsForm;
