import React from 'react';
import { Field } from 'formik';
import { FormikField } from '../../../../components/Fields';
import { Separator } from '../../../../components/Separator';
import { StyledDeliveryNotes } from './deliveryNotesStyles';
import { BlueTooltip } from 'src/containers/Order/Admin/Edit/orderHistoryHelpers';
import InfoIcon from '@material-ui/icons/Info';

const DeliveryNotes = () => {
  return (
    <StyledDeliveryNotes>
      <Separator margin="0.625rem 0 1.375rem" />
      <div className="header-container">
        <h4>Delivery Notes</h4>
        <BlueTooltip
          placement="top"
          arrow
          title={<p style={{ fontSize: '14px', textAlign: 'center', margin: '5px 0' }}>please indicate your stall barn & numbers for delivery.</p>}>
          <InfoIcon fontSize="small" />
        </BlueTooltip>
      </div>
      <Field
        id="add-on-delivery-notes"
        component={FormikField}
        label="DELIVERY NOTES"
        type="text"
        autoComplete="addOnDeliveryNotes"
        multiline
        minRows="1"
        variant="filled"
        name="addOnDeliveryNotes"
        inputProps={{ maxLength: 250 }}
      />
    </StyledDeliveryNotes>
  );
};

export default DeliveryNotes;
