import React from 'react';
import styled from 'styled-components';

import Button from '../../../../components/Button';

const ReviewAndSaveButton = ({ cardReader, disabled, onClick }) => (
  <ReviewAndSaveButtonBase data-testid="review-and-save-button" primary variant="contained" size="large" disabled={disabled} onClick={onClick}>
    {cardReader && <>REVIEW &amp; PAY</>}
    {!cardReader && <>REVIEW &amp; SAVE</>}
  </ReviewAndSaveButtonBase>
);

const ReviewAndSaveButtonBase = styled(Button)`
  &&& {
    width: auto;
    margin-left: 20px;
    letter-spacing: 0.7px;
    line-height: normal;
  }
`;

export default ReviewAndSaveButton;
