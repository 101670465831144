import gql from 'graphql-tag';

export const BUILDINGS_WITH_FLIPPING_QUERY = gql`
  query getBuildingsWithFlipping($input: BuildingsWithFlippingInput) {
    buildingsWithFlipping(input: $input) {
      id
      stallProducts {
        id
        name
        event
        flip
      }
    }
  }
`;
