import React, { useEffect, useRef, useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, Select } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import addOnStatus from '../../../../constants/addOnStatus';
import { UPDATE_ADDON_STATUS } from '../../../../mutations/updateAddOnStatus';
import { SnackbarContext } from '../../../../store/SnackbarContext';

const AddOnStatusSelect = ({ disabled, orders, orderID }) => {
  const { values } = useFormikContext();
  const didMountRef = useRef(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const [updateAddOnStatus] = useMutation(UPDATE_ADDON_STATUS);

  useEffect(() => {
    const updateAddOnStatusAsync = async () => {
      const response = await updateAddOnStatus({
        variables: { input: { orderId: values?.order?.id, statusId: values?.addOnStatusId } }
      });

      updateOrdersInfiniteTable();
      showNotification(response);
    };

    if (didMountRef.current) {
      updateAddOnStatusAsync();
    }
    didMountRef.current = true;
  }, [values.addOnStatusId]);

  const updateOrdersInfiniteTable = () => {
    const selectedOrder = orders.find(o => o.id === orderID);
    selectedOrder.addOnStatusId = values?.addOnStatusId;
  };

  const showNotification = response => {
    if (response?.data?.updateAddOnStatus) {
      showSnackbar('Add-ons Status edited successfully');
    } else {
      showSnackbar('Something went wrong', { error: true });
    }
  };

  const getOptions = () => {
    return Object.values(addOnStatus).map(status => (
      <MenuItem key={status.id} value={status.id}>
        {status.name}
      </MenuItem>
    ));
  };

  return (
    <div className={'order-select'}>
      <Field name="addOnStatusId">
        {({ field }) => (
          <FormControl disabled={disabled}>
            <Select {...field} disableUnderline>
              {getOptions()}
            </Select>
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default AddOnStatusSelect;
