import React from 'react';
import styled from 'styled-components';

import { EventCard } from '../Cards/EventCard';
import { LinksRows } from './LinksRows';

export const Links = () => {
  return (
    <LinksBase title={'Links'} testId="event-create-links">
      <p>
        Use the fields below to add links for your renters. Enter the URL for the website, a Label (optional) to display instead of the full URL, and a
        Description (optional) to provide context for the link.{' '}
        <span>
          <strong>The links will only be visible on the order confirmation page.</strong>
        </span>
      </p>
      <p>
        <em>
          For example, if you set the URL to ‘www.example.com’, the Label to ‘Click Here’, and the Description to ‘Visit our official page.’, the renter will
          see: ‘Visit our official page. Click Here’, with ‘Click Here’ as the clickable link. If no Label is added, the full URL will be displayed.
        </em>
      </p>
      <LinksRows />
    </LinksBase>
  );
};

const LinksBase = styled(EventCard)`
  h4,
  p {
    margin: 0 0 10px;
  }
`;
