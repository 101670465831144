import gql from 'graphql-tag';

export const REPORT_CONFIG = gql`
  query ReportConfig($id: ID) {
    venue(id: $id) {
      venueConfig {
        accountingReport
        reconReport
      }
    }
  }
`;
