import gql from 'graphql-tag';

export const STALL_PRODUCT_AVAILABILITY = gql`
  query StallProductAvailability($input: StallProductAvailabilityInputType) {
    stallProductAvailability(input: $input) {
      productId
      available
    }
  }
`;
