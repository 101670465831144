import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { VENUES } from '../../../queries/SuperAdmin';
import { SnackbarContext } from '../../../store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { initialValues, CreateRVlotSchema } from './helpers';
import { CREATE_RVLOT } from '../../../mutations/CreateRVlot';
import { HeadingFive } from 'Components/Headings';
import './RVlots.scss';
import { FormSelect } from 'Components/Select';

const getOptions = venues => {
  if (!venues) return null;

  return venues.map(venue => {
    return {
      label: venue.name,
      value: venue.id
    };
  });
};

const CreateRVLotForm = () => {
  const [venues, setVenues] = useState([]);
  const { data: venuesData } = useQuery(VENUES);
  const { showSnackbar } = useContext(SnackbarContext);

  const [createRVLot, { data, loading, error }] = useMutation(CREATE_RVLOT);
  const history = useHistory();

  useEffect(() => {
    if (venuesData) setVenues(venuesData.venues);
  }, [venuesData]);

  useEffect(() => {
    if (data) {
      showSnackbar('RV Lot created successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.RVLOTS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to create RV lot`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  return (
    <div className="create-rvlot-container">
      <ContextSnackbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async values =>
          await createRVLot({
            variables: {
              input: {
                venueId: values.venueId,
                name: values.name,
                description: values.description,
                power: values.power,
                sewer: values.sewer === 'true' ? true : false,
                water: values.water === 'true' ? true : false
              }
            }
          })
        }
        validationSchema={CreateRVlotSchema}>
        {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="RV LOT INFORMATION" />
              <Field name="venueId" autoComplete="none">
                {({ field, meta }) => (
                  <FormSelect
                    {...field}
                    {...meta}
                    selectedOption={values.venueId}
                    label="VENUE"
                    cb={e => {
                      const venue = venues.filter(venue => venue.id === e.target.value)[0];
                      if (venue) {
                        setFieldValue('venueId', venue.id);
                      }
                    }}
                    className="venue-select"
                    options={getOptions(venues)}
                  />
                )}
              </Field>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="NAME" type="text" name="name" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="power" autoComplete="none">
                    {({ field, meta }) => (
                      <FormSelect
                        {...field}
                        {...meta}
                        selectedOption={values.power}
                        label="POWER"
                        cb={e => {
                          setFieldValue('power', e.target.value);
                        }}
                        className="power-select"
                        options={[
                          { label: '20', value: '20' },
                          { label: '30', value: '30' },
                          { label: '50', value: '50' },
                          { label: '20, 30', value: '20, 30' },
                          { label: '20, 50', value: '20, 50' },
                          { label: '30, 50', value: '30, 50' },
                          { label: '20, 30, 50', value: '20, 30, 50' }
                        ]}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="sewer" autoComplete="none">
                    {({ field, meta }) => (
                      <FormSelect
                        {...field}
                        {...meta}
                        selectedOption={values.sewer === 'true' ? 'true' : 'false'}
                        label="SEWER"
                        cb={e => {
                          setFieldValue('sewer', e.target.value);
                        }}
                        className="sewer-select"
                        options={[
                          { label: 'TRUE', value: 'true' },
                          { label: 'FALSE', value: 'false' }
                        ]}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="water" autoComplete="none">
                    {({ field, meta }) => (
                      <FormSelect
                        {...field}
                        {...meta}
                        selectedOption={values.water === 'true' ? 'true' : 'false'}
                        label="WATER"
                        cb={e => {
                          setFieldValue('water', e.target.value);
                        }}
                        className="water-select"
                        options={[
                          { label: 'TRUE', value: 'true' },
                          { label: 'FALSE', value: 'false' }
                        ]}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  SAVE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateRVLotForm;
