import gql from 'graphql-tag';

export const GET_MAP = gql`
  query getMap($id: ID) {
    map(id: $id) {
      id
      name
      description
      url
      type
      buildings
      venue {
        id
      }
    }
  }
`;
