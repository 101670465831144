import React, { useContext, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { SelectRvLot } from './SelectRvLot';
import { ReservableSpots } from './ReservableSpots';
import { BottomNavigation } from '../Navigation/BottomNavigation';
import { UserContext } from 'Store/UserContext';
import { VENUE_RV_LOTS } from 'Queries/Admin/CreateEvent/VenueRvLots';
import { unauthenticatedHelper } from 'Helpers/unauthenticatedHelper';
import routeCodes from '../../../../constants/routes';
import { ProductSelectionToggle } from '../Cards/ProductSelectionToggle';
import RateCard from '../Stalls/RateCard';
import { HeadingFour } from 'Components/Headings';
import CustomQuestions from 'Components/CustomQuestions';
import { getOverlappingRVProducts } from './rvFlippingHelper';

export const RvSpots = ({ setRvQuestionsAreValid, basicDetailsIsEdited, rvSectionIsEdited, editView }) => {
  const {
    values: { hasRvs, rvQuestions, rvs = [] }
  } = useFormikContext();
  const {
    user: { venues = [] }
  } = useContext(UserContext);
  const [{ id: venueId }] = venues;

  const [loadRVLots, { data, error }] = useLazyQuery(VENUE_RV_LOTS);

  const rvLots = useMemo(() => (data ? data.venue.rvLots : []), [JSON.stringify(data)]);

  const renderLotNames = (inputId, disabled, rvProduct, lotsWithFlipping) => {
    const lotsWithFlippingInfo = getLotsWithFlippingInfo(rvProduct, lotsWithFlipping, inputId);
    const dateIsSelected = !!rvProduct?.dateRange?.startDate && !!rvProduct?.dateRange?.startDate;

    return (
      <div className={'rvs-spots-form'}>
        <div className={'spot-details'}>
          <div className={'lot-select'}>
            <HeadingFour className="section-label" label="Lot" />
            <SelectRvLot inputId={inputId} rvLots={lotsWithFlippingInfo} name={`rvs[${inputId}].rvLotId`} disabled={disabled || !dateIsSelected} />
          </div>
        </div>
      </div>
    );
  };

  const getLotsWithFlippingInfo = (currentRVProduct, lotsWithFlipping, index) => {
    return rvLots.map(lot => {
      const lotWithFlipping = lotsWithFlipping.find(l => l.id === lot.id);
      const rvProducts = excludeCurrentProduct(lotWithFlipping, currentRVProduct.id);
      const overlappingRVProducts = getOverlappingRVProducts(rvs, index, lot.id, currentRVProduct.dateRange);

      const lotWithFlippingInfo = getLotWithFlippingInfo(lot, lotWithFlipping, rvProducts, currentRVProduct, overlappingRVProducts);
      return lotWithFlippingInfo;
    });
  };

  const excludeCurrentProduct = (lotWithFlipping, currentProductId) => {
    return lotWithFlipping?.rvProducts.filter(rvp => rvp.id !== currentProductId);
  };

  const getLotWithFlippingInfo = (lot, lotWithFlipping, rvProducts, currentRVProduct, overlappingRVProducts) => {
    const lotWithFlippingInfo = { ...lot };

    if (overlappingRVProducts && overlappingRVProducts.length > 0) {
      const [nameWithFlip, disabled] = getFlippingInfoFromOverlap(overlappingRVProducts, lot, currentRVProduct);

      lotWithFlippingInfo.nameWithFlip = nameWithFlip;
      lotWithFlippingInfo.disabled = disabled;
    } else if (lotWithFlipping && rvProducts.length > 0) {
      const [nameWithFlip, disabled] = getFlippingInfo(rvProducts, lot, currentRVProduct);

      lotWithFlippingInfo.nameWithFlip = nameWithFlip;
      lotWithFlippingInfo.disabled = disabled;
    } else {
      lotWithFlippingInfo.nameWithFlip = lot.name;
    }

    return lotWithFlippingInfo;
  };

  const getFlippingInfoFromOverlap = (rvProducts, lot, currentRVProduct) => {
    const firstRVProduct = rvProducts[0];
    const flipStatus = firstRVProduct.flippingDisabled ? 'disabled' : 'enabled';
    const nameWithFlip = `${lot.name} (Flipping ${flipStatus} in this event)`;
    const disabled = firstRVProduct.flippingDisabled !== currentRVProduct.flippingDisabled;

    return [nameWithFlip, disabled];
  };

  const getFlippingInfo = (rvProducts, lot, currentRVProduct) => {
    const firstRVProduct = rvProducts[0];
    const flipStatus = firstRVProduct.flip ? 'enabled' : 'disabled';
    const event = firstRVProduct.event;
    const nameWithFlip = `${lot.name} (Flipping ${flipStatus} in event "${event}")`;
    const disabled = firstRVProduct.flip === currentRVProduct.flippingDisabled;

    return [nameWithFlip, disabled];
  };

  const renderReservables = (rv, inputId) => {
    return (
      <div className={'rvs-spots-form'}>
        <div className={'reservable-spots'}>
          <ReservableSpots rvLots={rvLots} rvLotId={rv.rvLotId} name={`rvs[${inputId}].spots`} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (venueId) {
      loadRVLots({ variables: { id: venueId } });
    }
  }, [venueId]);

  if (unauthenticatedHelper(error)) return <Redirect to={routeCodes.LOGIN} />;

  const cards = rvs.map((rv, index) => {
    return (
      <RateCard
        index={index}
        key={index}
        productType="rvs"
        stallsPerBuilding={null}
        renderLotNames={(disabled, lotsWithFlipping) => renderLotNames(index, disabled, rv, lotsWithFlipping)}
        renderReservables={() => renderReservables(rv, index)}
        scope="hasRvs"
      />
    );
  });

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <ProductSelectionToggle header="Configure RV Spots" scope="hasRvs" product="rvs" />
        {hasRvs && (
          <div data-testid="create-event-rvs">
            <CustomQuestions productType="rvs" questions={rvQuestions} setProductQuestionsAreValid={setRvQuestionsAreValid} />
            {cards}
          </div>
        )}
        <BottomNavigation
          back={'stalls'}
          backLabel={'BACK TO STALLS'}
          showSubmit
          editView={editView}
          basicDetailsIsEdited={basicDetailsIsEdited}
          rvSectionIsEdited={rvSectionIsEdited}
        />
      </DndProvider>
    </>
  );
};
