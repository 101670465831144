import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { headingFive } from '../../styles/Typography';

const HeadingFiveBase = props => {
  return (
    <Typography className={props.className} variant="h5">
      {props.label}
    </Typography>
  );
};

const HeadingFive = styled(HeadingFiveBase)`
  &&& {
    ${headingFive}
  }
`;

export default HeadingFive;
