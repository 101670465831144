import gql from 'graphql-tag';

export const CREATE_RVSPOTS = gql`
  mutation createRVspots($input: CreateRVspotsInput) {
    createRVspots(input: $input) {
      rvSpots {
        id
        name
      }
    }
  }
`;
