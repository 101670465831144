import * as Yup from 'yup';

export const initialValues = {
  venueId: '',
  name: '',
  description: '',
  power: '',
  sewer: '',
  water: ''
};

export const CreateRVlotSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Add on name is required'),
  venueId: Yup.string().required('Venue is required'),
  power: Yup.string().required('Power is required')
});

export const UpdateRVlotSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Add on name is required'),
  power: Yup.string().required('Power is required')
});
