import React, { useState, memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { isMobile } from 'react-device-detect';

import UsersTable from '../../containers/Tables/Admin/UsersTable';
import RenterForm from '../RenterFormModal';
import { displayFlex } from '../../styles/Mixins';
import AddButton from '../../components/Button/AddButton';
import { withUserContext } from 'Store/UserContext';
import { withUserRoleContext } from 'Store/UserRoleContext';
import ContextSnackbar from '../../components/Snackbar';
import { HeadingOne } from 'Components/Headings';

const AdminUsersBase = props => {
  const { className, user: adminUser, userRoles } = props;
  const [openAdd, onAddClick] = useState(false);
  const [openEdit, onEditClick] = useState(false);
  const [user, selectUser] = useState({});

  const closeAdd = () => onAddClick(false);
  const closeEdit = () => {
    selectUser({});
    onEditClick(false);
  };
  const openAddModal = () => onAddClick(true);
  const openEditModal = user => {
    selectUser(user);
    onEditClick(true);
  };

  return (
    <AdminUsers isMobile={isMobile}>
      <ContextSnackbar />
      <RenterForm adminUser={adminUser} heading={'ADD USER'} onClose={closeAdd} open={openAdd} user={{}} userRoles={userRoles} />
      <RenterForm adminUser={adminUser} heading={'EDIT USER'} onClose={closeEdit} open={openEdit} user={user} userRoles={userRoles} />
      <section className={className}>
        <FlexWrapper isMobile={isMobile}>
          <HeadingOne label="USERS" />
          <AddButton
            onClick={openAddModal}
            label={isMobile ? '' : 'ADD USER'}
            styles={isMobile ? { width: '40px', height: '40px', marginRight: '33px' } : {}}
          />
        </FlexWrapper>
        <UsersTable onEditClick={openEditModal} userRoles={userRoles} />
      </section>
    </AdminUsers>
  );
};

const AdminUsers = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 50px 0;
      min-width: 100%;
    `}

  ${({ isMobile }) =>
    !isMobile &&
    `
      margin: 85px 50px 50px;
      max-width: 1800px;
      min-width: 1130px;
    `}
`;

const FlexWrapper = styled.div`
  ${displayFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    `
      margin-left: 22px;
    `}
`;

export default memo(compose(withUserContext, withUserRoleContext)(AdminUsersBase));
