import gql from 'graphql-tag';

export const STALL_AVAILABILITY_BY_PRODUCT_IDS = gql`
  query StallAvailabilityByProductIds($input: StallAvailabilityByProductIdsInput) {
    stallAvailabilityByProductIds(input: $input) {
      availableSpaces {
        id
        name
        status
        building {
          id
          name
        }
      }
    }
  }
`;
