import gql from 'graphql-tag';

export const CREATE_EVENT = gql`
  mutation createEvent($input: EventUpsertInput) {
    createEvent(input: $input) {
      event {
        id
      }
      success
      error
    }
  }
`;
