import gql from 'graphql-tag';

export const CREATE_BUILDING = gql`
  mutation createBuilding($input: CreateBuildingInput) {
    createBuilding(input: $input) {
      name
      venue {
        id
      }
    }
  }
`;
