import React, { useEffect, useState } from 'react';
import './Buildings.scss';
import ContextSnackbar from 'Components/Snackbar';
import { HeadingTwo } from 'Components/Headings';
import { subRouteCodes as SUB_ROUTES } from 'Constants/routes';
import AddButton from 'Components/Button/AddButton';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { displayFlex } from '../../../styles/Mixins';
import { paragraphReg } from '../../../styles/Typography';
import colors from '../../../styles/Colors';
import { useQuery } from '@apollo/react-hooks';
import { VENUES } from 'Queries/SuperAdmin';
import { MenuItem, Select } from '@material-ui/core';
import BuildingsTable from 'Pages/SuperAdmin/Buildings/BuildingsTable';

const getOptions = data => {
  if (!data) return null;

  const options = data.map(data => (
    <MenuItem key={data.id} value={data.id}>
      {data.name}
    </MenuItem>
  ));

  return options;
};

function Buildings(props) {
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const { data: venuesData, loading: venuesLoading, error: venuesError, refetch } = useQuery(VENUES, {
    fetchPolicy: 'network-only'
  });
  const { className } = props;

  useEffect(() => {
    if (venuesData) setVenues(venuesData.venues);
  }, [venuesData]);

  const handleVenueChange = e => {
    const venue = venues.filter(venue => venue.id === e.target.value)[0];
    setSelectedVenue(venue);
  };

  return (
    <>
      <div className={className}>
        <ContextSnackbar />
        <div className={`${className}__page-header`}>
          <div className={`${className}__header-wrapper`}>
            <HeadingTwo label="BUILDINGS" />
          </div>
        </div>
      </div>
      <div className={className}>
        <div className={`${className}__page-body`}>
          <div>
            <CreateBuildingLink to={SUB_ROUTES.SUPER_ADMIN.BUILDING_CREATE}>
              <AddButton label={'CREATE BUILDING'} />
            </CreateBuildingLink>
          </div>
          <div>
            <h3>Choose a venue</h3>
            {venues && !venuesLoading && !venuesError && (
              <Select data-testid="map-select-venue" onChange={handleVenueChange} value={selectedVenue?.id || ''} disableUnderline>
                {getOptions(venues)}
              </Select>
            )}
            {selectedVenue && <BuildingsTable buildings={selectedVenue.buildings} refetch={refetch} />}
          </div>
        </div>
      </div>
    </>
  );
}

const CreateBuildingLink = styled(Link)`
  text-decoration: none;
`;

const SuperAdminBuildings = styled(Buildings)`
  &&& {
    ${displayFlex}
    align-items: center;
    width: 100%;
    height: auto;
    padding: 12px 0 11px 20px;
    ${paragraphReg}
    font-size: 14px;
    text-align: left;
    background-color: ${colors.background.primary};
    color: ${colors.text};
    border-radius: 3px;
  }

  &__page-header {
    text-align: left;
    width: 100%
    border-bottom: 1px solid rgb(200, 214, 229);
    &&& {
      margin: 65px 0 25px;
    }

  }

  &__page-body {
    text-align: left;
    width: 100%
    &&& {
      margin: 0;
      padding: 10px
    }

    h3 {
      text-transform: uppercase;
      font-family: IBMPlexSans-Bold;
      font-size: 12px;
      font-weight: 400;
      color: rgb(79, 93, 109);
      margin-top: 0px;
      margin-bottom: 3px;
    }
  }

  &__page-body-child {
    margin-top: 20px;
  }

  &__header-wrapper {
    margin: 20px 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiInputBase-root {
    border: ${colors.text.lightGray2} 1px solid;
    border-radius: 3px;
    padding: 0 10px;
    min-width: 300px;
  }

  .MuiSvgIcon-root {
    width: .8em;
    height: .8em;
  }

  .MuiIconButton-root {
    padding-top: 6px;
  }

  .line {
    background: ${colors.border.primary};
    height: 1px;
    flex: 1;
    position: relative;
    top: 11px;
  }
`;

export default SuperAdminBuildings;
