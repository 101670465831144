const { JSDOM } = require('jsdom');
import api from '../../../lib/api';
import { SPOTS_IDS_BY_BUILDING_OR_LOT } from 'Queries/SuperAdmin/spotsIdsByBuildingOrLotIds';

/*
 *  See Interactive maps documentation
 *  here: https://rodeologistics.atlassian.net/wiki/x/AYDhcw
 */

const validateSVGMap = async (file, { venueId, type, buildings }) => {
  let errors = [];
  let warnings = [];

  if (file && venueId && buildings && type) {
    try {
      const isStallMap = type === 1 || type === 'barn';
      const stringType = isStallMap ? 'stall' : 'rv';
      const fileClone = new Blob([file], { type: file.type });
      const fileContent = await readFileAsync(fileClone);
      const dom = new JSDOM(fileContent);
      const document = dom.window.document;
      const mainTag = document.querySelector(`svg g#map_svg_${stringType}`);

      if (!mainTag) {
        errors.push('No correct main tag (g) found');
      }

      const interactiveTag = document.querySelector('svg g#interactive');

      if (!interactiveTag) {
        errors.push('No interactive tag found');
      }

      const allElements = interactiveTag.querySelectorAll('*');
      const regexForIds = isStallMap ? /^stall_\d+$/ : /^rv_\d+$/;
      const spots = Array.from(allElements).filter(el => regexForIds.test(el.id));

      if (Array.from(allElements).length !== spots.length) {
        errors.push('One or more spots have a wrong ID (typo)');
      }

      if (spots.length === 0) {
        errors.push(`No ${stringType} elements found`);
      }

      const idsFromSVG = [];
      let badIdFlag = false;
      let badTypeFlag = false;
      spots.forEach(el => {
        const htmlId = el.getAttribute('id');
        const [type, id] = htmlId.split('_');

        if (type !== stringType) {
          badTypeFlag = true;
        }

        if (!id) {
          badIdFlag = true;
        } else {
          idsFromSVG.push(id);
        }
      });

      if (badIdFlag || badTypeFlag) {
        errors.push('One or more spots have a wrong number or type in their IDs (typo)');
      }

      const uniqueIdsFromSVG = new Set(idsFromSVG);
      if (uniqueIdsFromSVG.size !== idsFromSVG.length) {
        errors.push('The svg has repeated ids');
      }

      const queryResults = await api.query({
        query: SPOTS_IDS_BY_BUILDING_OR_LOT,
        variables: {
          input: {
            buildings: isStallMap ? buildings : [],
            lots: isStallMap ? [] : buildings
          }
        }
      });

      const idsFromApi = queryResults?.data?.spotsIdsByBuildingOrLotIds;

      if (!idsFromApi || idsFromApi.length === 0) {
        errors.push('Could not find any ids for spots from the API. Network issue or bad building or lot ids');
      } else {
        const containsAllIds = idsFromSVG.every(element => idsFromApi.includes(element));
        const hasLessIds = idsFromSVG.length < idsFromApi.length;

        if (!containsAllIds) errors.push(`One or more ids in the svg are not bound to the selected ${isStallMap ? 'buildings' : 'lots'}`);
        if (hasLessIds) warnings.push(`The svg map has less spots than the selected ${isStallMap ? 'building/s' : 'lot/s'}`);
      }
    } catch (error) {
      console.error('Error processing the map', error);
      errors.push('Error processing the map');
    }
  } else {
    errors.push('No valid file or missing info');
  }

  return [errors, warnings];
};

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(e) {
      resolve(e.target.result);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsText(file);
  });
}

export default validateSVGMap;
