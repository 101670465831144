import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

const FormCardBase = props => {
  return (
    <FormCard style={props.style} className={`${props.className}`} data-testid={`${props.dataTestId || ''}`}>
      {props.children}
    </FormCard>
  );
};

const FormCard = styled(Card)`
  width: 100%;
  margin: 0 0 20px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(17, 24, 31, 0.1), 0 2px 5px rgba(17, 24, 31, 0.3);
  border-radius: 0;
  text-align: left;
  overflow: visible;
`;

export default FormCardBase;
