import React, { useCallback, useContext, useEffect, useState } from 'react';
import { loadStripeTerminal } from '@stripe/terminal-js';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { displayFlex } from '../../../../styles/Mixins';
import { paragraphBold, paragraphReg } from '../../../../styles/Typography';
import colors from '../../../../styles/Colors';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { SnackbarContext } from 'Store/SnackbarContext';
import { UserContext } from 'Store/UserContext';

export const CardReaderModalBase = props => {
  const API_URL = process.env.REACT_APP_API_URL;
  const NODE_ENV = process.env.NODE_ENV;
  const isProd = NODE_ENV === 'production';
  const { className, close, handleSubmit, heading, open, total, totalFee, order } = props;
  const { setFieldValue, isSubmitting } = useFormikContext();
  const {
    user: { venues = [] }
  } = useContext(UserContext);
  const [firstVenue = {}] = venues;
  const { id: venueId, stripeLocation } = firstVenue;
  const { showSnackbar } = useContext(SnackbarContext);
  const [posPaymentSuccess, setPosPaymentSuccess] = useState(false);

  const [terminal, setTerminal] = useState(null);
  const [discoveredReaders, setDiscoveredReaders] = useState(null);
  const [seletectedReader, setSeletectedReader] = useState(null);
  const [isCharging, setIsCharging] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const totalInCents = Math.round(100 * parseFloat(typeof total === 'string' ? total.replace(/[$,]/g, '') : total));
  const handleSubmitForm = () => {
    handleSubmit && handleSubmit();
  };

  const fetchConnectionToken = async () => {
    const bodyContent = JSON.stringify({ venueId: venueId });
    return fetch(`${API_URL}/admin/connection_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: bodyContent
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.secret;
      })
      .catch(() => {
        showSnackbar('There was an error retrieving the token', {
          duration: 2000,
          error: true
        });
      });
  };

  const unexpectedDisconnect = () => {
    showSnackbar('Disconnected from reader', {
      duration: 2000,
      error: true
    });
  };

  const setupStripeTerminal = useCallback(async () => {
    const StripeTerminal = await loadStripeTerminal();
    const t = StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect
    });
    setTerminal(t);
  });

  useEffect(() => {
    if (!terminal) {
      setupStripeTerminal();
      setPosPaymentSuccess(false);
      setDiscoveredReaders(null);
      setSeletectedReader(null);
      setIsCharging(false);
      setIsConnecting(false);
    }
  }, []);

  useEffect(() => {
    if (open && terminal) {
      handleDiscoverReaders();
    }
  }, [open]);

  const handleDiscoverReaders = () => {
    const config = {
      simulated: !isProd,
      location: stripeLocation ? stripeLocation : 'nullLocation'
    };
    terminal.discoverReaders(config).then(function(discoverResult) {
      if (discoverResult.error) {
        showSnackbar('Failed to discover readers.', {
          duration: 2000,
          error: true
        });
      } else if (discoverResult.discoveredReaders.length === 0) {
        showSnackbar('No available readers.', {
          duration: 2000,
          error: true
        });
      } else {
        setDiscoveredReaders(discoverResult.discoveredReaders);
      }
    });
  };

  const handleConnectReader = () => {
    const reader = discoveredReaders.find(reader => reader.id === seletectedReader);
    if (reader) {
      setIsConnecting(true);
      terminal.connectReader(reader).then(function(connectResult) {
        if (connectResult.error) {
          showSnackbar('Failed to connect reader.', {
            duration: 2000,
            error: true
          });
        } else {
          showSnackbar('Reader connected', {
            duration: 2000
          });
          handleCollect();
        }
        setIsConnecting(false);
      });
    }
  };

  const fetchPaymentIntentClientSecret = amount => {
    const bodyContent = getBodyContent(amount);

    return fetch(`${API_URL}/admin/create_payment_intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: bodyContent
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.client_secret;
      })
      .catch(() => {
        showSnackbar('Failed to fetch payment intent.', {
          duration: 2000,
          error: true
        });
      });
  };

  const getBodyContent = amount => {
    const isGroupOrderNoPayed = order?.group && order?.payments.length === 0;
    const fee = isGroupOrderNoPayed ? totalFee + order.platformFee : totalFee;
    const bodyContent = JSON.stringify({ amount, venueId, totalFee: fee });
    return bodyContent;
  };

  const handleCollect = () => {
    setIsCharging(true);
    fetchPaymentIntentClientSecret(totalInCents).then(function(client_secret) {
      if (!isProd) {
        terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
      }
      terminal.collectPaymentMethod(client_secret).then(function(result) {
        if (result.error) {
          showSnackbar(`Couldn't collect the payment`, {
            duration: 2000,
            error: true
          });
          close && close();
        } else {
          terminal.processPayment(result.paymentIntent).then(function(processedPayment) {
            if (processedPayment.error) {
              showSnackbar(`Couldn't process the payment`, {
                duration: 2000,
                error: true
              });
              setPosPaymentSuccess(false);
              close && close();
            } else if (processedPayment.paymentIntent) {
              handlePaymentSuccess(processedPayment.paymentIntent);
            }
          });
        }
      });
    });
  };

  const handlePaymentSuccess = paymentIntent => {
    showSnackbar(`The payment was processed`, {
      duration: 2000
    });
    setIsCharging(false);
    setPosPaymentSuccess(true);
    setFieldValue('ccInformation.paymentIntentId', paymentIntent?.id);
    setFieldValue('ccInformation.paymentIntentAppFee', +paymentIntent?.application_fee_amount);

    const latestCharge = paymentIntent?.charges?.data?.pop();

    setFieldValue('ccInformation.paymentIntentCharge', latestCharge?.id);
    setFieldValue('ccInformation.last4', latestCharge?.payment_method_details?.card_present?.last4);
    setFieldValue('ccInformation.country', latestCharge?.payment_method_details?.card_present?.country);

    handleSubmitForm();
  };

  return (
    <Modal className={`${className}__card-reader-modal`} heading={heading} open={open} dataTestId="card_reader_modal">
      <FlexWrapper>
        <Wrapper>
          {discoveredReaders && discoveredReaders.length > 0 && (
            <Wrapper>
              <div className="reader-selector-wrapper">
                <div>Select the card reader:</div>
                <Select
                  value={seletectedReader || ''}
                  onChange={e => setSeletectedReader(e.target.value)}
                  labelWidth={200}
                  disabled={discoveredReaders.length === 0}>
                  {discoveredReaders.length > 0 &&
                    discoveredReaders.map(option => {
                      return (
                        <MenuItem key={`${option.value}-${option.label}`} value={option.id}>
                          <div>
                            {option.label} #{option.serial_number}
                          </div>
                        </MenuItem>
                      );
                    })}
                  {discoveredReaders.length === 0 && (
                    <MenuEmptyItem value="" disabled>
                      <div>No options</div>
                    </MenuEmptyItem>
                  )}
                </Select>
              </div>
              {seletectedReader && (
                <Button isLoading={isConnecting || isCharging} primary onClick={handleConnectReader} disabled={isConnecting || isCharging || posPaymentSuccess}>
                  Connect & Collect
                </Button>
              )}
            </Wrapper>
          )}
        </Wrapper>
        <FormButton disabled={posPaymentSuccess} className={`${className}__cancel-button`} secondary variant="contained" size="large" onClick={close}>
          CANCEL
        </FormButton>
        <FlexButtonWrapper>
          <FormButton
            data-testid="review-and-save-save-button"
            primary
            variant="contained"
            size="large"
            onClick={handleSubmitForm}
            disabled={isSubmitting || !posPaymentSuccess}>
            SAVE
          </FormButton>
          {isSubmitting && <CircularProgress size={24} className={`${className}__progress-spinner`} />}
        </FlexButtonWrapper>
      </FlexWrapper>
    </Modal>
  );
};

const CardReaderModal = styled(CardReaderModalBase)`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  height: 100%;
  justify-content: space-around;

  &__card-reader-modal {
    .MuiCard-root {
      overflow-y: scroll;
      max-height: 100%;
      padding: 20px 20px 0;
    }

    button {
      margin-bottom: 20px;
    }
  }

  &__cancel-button {
    position: absolute !important;
    right: 20px;
    top: 20px;
  }

  &__divider {
    width: 100%;
    background-color: ${colors.border.primary};
  }

  &__header_text {
    margin-right: 10px;
    font-family: IBMPlexSans-SemiBold;
    font-size: 22px;
    letter-spacing: 0.97px;
    line-height: 25px;
    white-space: nowrap;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiButtonBase-root.MuiButton-root {
    min-width: 200px;
    margin: 20px 0;
  }

  .MuiSelect-select.MuiSelect-selectMenu {
    min-width: 150px;
    margin-top: 15px;
  }
`;

const FlexWrapper = styled.div`
  ${displayFlex}
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &:first-child {
    margin: 0 0 30px 0;
  }
  @media screen and (max-width: 601px) {
    ${displayFlex}
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &:first-child {
      margin: 0 0 30px 0;
    }
  }

  p:nth-child(1) {
    ${paragraphBold}
    align-self: flex-start;
    margin: 0;
    padding: 20px 0 0;
  }
  p:not(:nth-child(1)) {
    ${paragraphReg}
    margin: 5px 0 0;
    width: 170px;
  }
`;

const FlexButtonWrapper = styled(FlexWrapper)`
  ${displayFlex}
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  width: 100%;
  position: unset;
  margin-top: 20px;
  @media screen and (max-width: 601px) {
    ${displayFlex}
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 0;
  }
`;

const FormButton = styled(Button)`
  &&& {
    line-height: 0;
    width: 175px;
    margin-left: ${props => (props.primary ? 20 : 0)}px;
  }
`;

export default CardReaderModal;
