import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { VENUES } from '../../../queries/SuperAdmin';
import { SnackbarContext } from '../../../store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { initialValues, CreateBuildingSchema } from './helpers';
import { CREATE_BUILDING } from '../../../mutations/CreateBuilding';
import { HeadingFive } from 'Components/Headings';
import './Buildings.scss';
import { FormSelect } from 'Components/Select';

const getOptions = venues => {
  if (!venues) return null;

  return venues.map(venue => {
    return {
      label: venue.name,
      value: venue.id
    };
  });
};

const CreateBuildingForm = () => {
  const [venues, setVenues] = useState([]);
  const { data: venuesData } = useQuery(VENUES);
  const { showSnackbar } = useContext(SnackbarContext);

  const [createBuilding, { data, loading, error }] = useMutation(CREATE_BUILDING);
  const history = useHistory();

  useEffect(() => {
    if (venuesData) setVenues(venuesData.venues);
  }, [venuesData]);

  useEffect(() => {
    if (data) {
      showSnackbar('Building created successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.BUILDINGS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to create building`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  return (
    <div className="create-building-container">
      <ContextSnackbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async values =>
          await createBuilding({
            variables: {
              input: {
                venueId: values.venueId,
                name: values.name
              }
            }
          })
        }
        validationSchema={CreateBuildingSchema}>
        {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="BUILDING INFORMATION" />
              <Field name="venueId" autoComplete="none">
                {({ field, meta }) => (
                  <FormSelect
                    {...field}
                    {...meta}
                    selectedOption={values.venueId}
                    label="VENUE"
                    cb={e => {
                      const venue = venues.filter(venue => venue.id === e.target.value)[0];
                      if (venue) {
                        setFieldValue('venueId', venue.id);
                      }
                    }}
                    className="venue-select"
                    options={getOptions(venues)}
                  />
                )}
              </Field>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="BUILDING NAME" type="text" name="name" variant="filled" component={FormikField} />
                </Grid>
              </Grid>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  SAVE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateBuildingForm;
