import React from 'react';

import { formatPhoneNumber } from '../../helpers';

const PhoneLink = ({ phone, className }) => {
  return (
    <a href={`tel:${phone}`} style={{ textDecoration: 'underline' }} className={className}>
      {formatPhoneNumber(phone)}
    </a>
  );
};

export default PhoneLink;
