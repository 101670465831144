import FormCard from 'Components/Cards/FormCard';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import { HeadingTwo } from '../../../components/Headings';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_MAPS_BY_VENUE_ID } from 'Queries/Maps/getMapsByVenueId';
import { STALL_AVAILABILITY } from 'Queries/StallAvailability';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'Helpers/DATE_FORMAT';
import MapSpotsSelector from 'Pages/Admin/Maps/MapSpotsSelector';
import { RV_SPOT_AVAILABILITY } from 'Queries/RvSpotAvailability';
import gql from 'graphql-tag';

const PickSpotOnMapForRenter = ({ type }) => {
  const { values, setFieldValue } = useFormikContext();
  const { event, stalls, stallProductId, selectedStalls, rv_spot, rvProductId, selectedRvs } = values;
  const venue = event?.venue;
  const { buildings } = venue;
  const { start: startStall, end: endStall } = stalls;
  const { start: startRv, end: endRv } = rv_spot;
  const start = type === 'barn' ? startStall : startRv;
  const end = type === 'barn' ? endStall : endRv;
  const startDate = start ? moment(start).format(DATE_FORMAT) : null;
  const endDate = end ? moment(end).format(DATE_FORMAT) : null;

  const EVENT_STALL_BUILDINGS = gql`
    query eventProducts($id: ID) {
      event(id: $id) {
        stallProducts {
          id
          stalls {
            building {
              id
            }
          }
        }
      }
    }
  `;

  const { data: mapsData, error: mapsError, loading: loadingMaps } = useQuery(GET_MAPS_BY_VENUE_ID, {
    variables: { venueId: venue?.id },
    fetchPolicy: 'network-only'
  });

  const [getAvailableStalls, { data: stallsAvailability }] = useLazyQuery(STALL_AVAILABILITY, {
    variables: {
      input: {
        startDate,
        endDate: endDate ?? startDate,
        productId: stallProductId,
        reservationId: null,
        includeCurrentReservation: false
      }
    },
    fetchPolicy: 'network-only'
  });

  const [getAvailableRvSpots, { data: rvsAvailability }] = useLazyQuery(RV_SPOT_AVAILABILITY, {
    variables: {
      input: {
        startDate,
        endDate: endDate ?? startDate,
        productId: rvProductId,
        reservationId: null
      }
    },
    fetchPolicy: 'network-only'
  });

  const { data: eventStallProducts } = useQuery(EVENT_STALL_BUILDINGS, {
    variables: {
      id: event.id
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (stallProductId && startDate && endDate && type === 'barn') {
      getAvailableStalls();
    }

    if (rvProductId && startDate && endDate && type === 'lot') {
      getAvailableRvSpots();
    }
  }, [stallProductId, rvProductId, startDate, endDate, type]);

  const maps = mapsData?.maps.filter(map => map.type === type);

  if (!venue?.interactiveMaps || !venue?.interactiveMapsForRenters || mapsError || loadingMaps || maps.length === 0) {
    return null;
  }

  const showMap = startDate && endDate && ((stallProductId && type === 'barn') || (rvProductId && type === 'lot'));

  let availableSpots = [];
  let availability = type === 'barn' ? stallsAvailability : rvsAvailability;
  if (availability) {
    let eventSpotsAvailable = [];

    if (type === 'barn') {
      availability?.stallAvailability?.forEach(building => {
        const array = eventSpotsAvailable.concat(building.availableSpaces);
        eventSpotsAvailable = array;
      });
    } else {
      availability?.rvSpotAvailability?.forEach(building => {
        const array = eventSpotsAvailable.concat(building.availableSpaces);
        eventSpotsAvailable = array;
      });
    }

    availableSpots = eventSpotsAvailable.sort((a, b) => (!!a && !!b ? a.name.localeCompare(b.name, 'en', { numeric: true }) : 0));
  }

  const toggleSpot = clickedSpot => {
    let newSelectedSpots = [];
    if (type === 'barn') {
      newSelectedSpots = selectedStalls?.length > 0 ? [...selectedStalls] : [];
    } else {
      newSelectedSpots = selectedRvs?.length > 0 ? [...selectedRvs] : [];
    }

    const index = newSelectedSpots.findIndex(ss => ss.id === clickedSpot.id);
    if (index > -1) {
      newSelectedSpots.splice(index, 1);
    } else {
      const quantity = type === 'barn' ? stalls.quantity : rv_spot.quantity;

      if (quantity <= newSelectedSpots.length) return;
      newSelectedSpots.push(clickedSpot);
    }

    setFieldValue(type === 'barn' ? 'selectedStalls' : 'selectedRvs', newSelectedSpots);
  };

  const formatBuildingValues = () => {
    if (!eventStallProducts) return [];

    const eventBuildingsObj = {};
    eventStallProducts.event.stallProducts
      .find(product => product.id === stallProductId)
      ?.stalls.forEach(stall => {
        if (!eventBuildingsObj[stall.building.id]) eventBuildingsObj[stall.building.id] = true;
      });

    const eventOnlyBuidings = buildings.filter(building => eventBuildingsObj[building.id]);

    return (
      eventOnlyBuidings?.map(building => {
        return { value: building.id };
      }) || []
    );
  };

  const formatRvLotValues = () => {
    return availableSpots.map(arv => {
      return {
        value: arv.rvLot.id,
        name: arv.rvLot.name
      };
    });
  };

  return (
    <FormCard>
      <div>
        <HeadingTwoStyled label={`Choose your ${type === 'barn' ? 'stall(s)' : 'spot(s)'}`} />
      </div>
      {showMap && (
        <div className="map-renter-section">
          <MapSpotsSelector
            maps={maps}
            onSelect={toggleSpot}
            availableSpots={availableSpots}
            selectedSpots={[]}
            r
            maxSelection={type === 'barn' ? stalls.quantity : rv_spot.quantity}
            buildings={type === 'barn' ? formatBuildingValues() : formatRvLotValues()}
          />
        </div>
      )}
      {!showMap && <div>{`Select your dates and ${type === 'barn' ? 'stall rate price' : 'RV lot'} above`}</div>}
    </FormCard>
  );
};

export default PickSpotOnMapForRenter;

const HeadingTwoStyled = styled(HeadingTwo)`
  margin: 0;
  font-size: 25px !important;
  letter-spacing: 1.1px !important;
  line-height: 25px !important;
`;
