import gql from 'graphql-tag';

export const ORDERS_FOR_MAP_DETAILS = gql`
  query getOrdersBySpaceAndEventId($input: OrdersBySpaceAndEventIdInput) {
    getOrdersBySpaceAndEventId(input: $input) {
      success
      count
      orders {
        id
        orderItems {
          id
          addOnProduct {
            id
            price
            addOn {
              id
              name
              unitName
            }
          }
          reservation {
            id
            endDate
            rvProduct {
              id
              name
              description
              price
              nightly
              rvLot {
                id
                description
                name
                power
                sewer
                water
              }
            }
            rvSpots {
              id
              name
            }
            stallProduct {
              id
              name
              description
              nightly
              price
            }
            stalls {
              id
              building {
                id
                name
              }
              name
              description
              status
            }
            startDate
            status {
              id
              name
            }
          }
          quantity
        }
        total
        user {
          id
          email
          firstName
          fullName
          lastName
          phone
        }
      }
    }
  }
`;
