import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Separator } from 'Components/Separator';
import { useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';

import FormCard from '../../../../components/Cards/FormCard';
import { formatPhoneNumber } from '../../../../helpers';
import EventSelection from './EventSelection';
import RenterInformation from './RenterInformation';
import { HeadingThree } from 'Components/Headings';
import _upperFirst from 'Utils/upperFirst';

const BasicInformationBase = ({ adminUser, className, order }) => {
  const { values } = useFormikContext();
  const { reservationEdit } = values;
  const { event, user, group } = order || {};

  const getBasicTitle = () => {
    if (reservationEdit && user) {
      return (
        <HeadingThree label={`${_upperFirst(user.firstName)} ${_upperFirst(user.lastName)}${group ? ` | ${_upperFirst(group.name)}` : ''}`} className="" />
      );
    }

    return <HeadingThree label="Basic Information" className="" />;
  };

  return (
    <BasicInformationCard isMobile={isMobile} className={`${className ? className : ''} ${isMobile ? 'mobile' : ''}`} dataTestId="basic_info">
      <div className="basic_info--title">{getBasicTitle()}</div>
      <Separator margin="0.625rem 0 1.375rem" />
      {reservationEdit ? (
        <Grid container justify="space-between" className="basic-info-wrapper">
          <div item container className="section" md={4}>
            <span className="section-subheader">Email</span>
            <p className="renter-email">{user.email}</p>
          </div>
          <div item container className="section" md={4}>
            <span className="section-subheader">Phone</span>
            <p className="renter-phone">{formatPhoneNumber(user.phone)}</p>
          </div>
          <div item container className="section event__section" md={4}>
            <span className="section-subheader">Event</span>
            <p className="event-name">{event.name}</p>
          </div>
        </Grid>
      ) : (
        <>
          <RenterInformation />
          <Separator />
          <EventSelection user={adminUser} />
        </>
      )}
    </BasicInformationCard>
  );
};

const BasicInformationCard = styled(FormCard)`
  &&& {
    padding: 20px;

    ${({ isMobile }) =>
      isMobile &&
      `
      padding: 16px;
    `}
  }

  .basic-info-wrapper {
    display: flex;
    flex-direction: row;

    ${({ isMobile }) =>
      isMobile &&
      `
      flex-direction: column;
    `}
  }

  .basic_info--title {
    font-size: 25px;
    line-height: 25px;
    margin: 0;
    white-space: nowrap;

    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .section {
    height: 100%;
    flex: 1;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-bottom: 15px;
    `}
  }

  .section:not(:last-child) {
    padding-right: 10px;
  }

  .event__section {
    min-width: 0;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .section-subheader {
    width: 100%;
    display: block;
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 18px;
    letter-spacing: 0.79px;
    line-height: 23px;
  }

  .renter-name,
  .renter-email,
  .renter-phone,
  .event-name {
    width: 100%;
    margin: 0;
  }

  .renter-name {
    text-transform: capitalize;
  }
`;

export default BasicInformationBase;
