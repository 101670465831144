export const initialValues = () => ({
  mapName: '',
  mapDescription: '',
  mapType: '',
  venueId: '',
  buildings: [],
  file: ''
});

export const setInitValues = data => {
  const map = data?.map;

  if (!map) {
    return initialValues;
  }

  return {
    mapName: map.name,
    mapDescription: map.description,
    mapType: map.type,
    venueId: map.venue.id,
    buildings: map.buildings,
    file: { name: map.url }
  };
};
