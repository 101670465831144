import React, { memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';

import EditEvent from '../../containers/Event/Edit';
import ContextSnackbar from '../../components/Snackbar';

const AdminEditEventBase = props => {
  const { location } = props;
  const path = location.pathname;
  const eventId = path.substring(path.lastIndexOf('/') + 1);

  return (
    <AdminEditEvent isMobile={isMobile}>
      <ContextSnackbar />
      <EditEvent eventId={eventId} />
    </AdminEditEvent>
  );
};

const AdminEditEvent = styled.div`
  margin: 85px 50px 50px;

  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 50px 16px;
    `}
`;

export default compose(withRouter)(memo(AdminEditEventBase));
