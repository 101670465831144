export default (payments, currentPayment) => {
  if (currentPayment.success) {
    return false;
  }
  return !payments.some(
    p =>
      p.amount === currentPayment?.amount &&
      currentPayment.cardPayment !== p.cardPayment &&
      currentPayment.success !== p.success &&
      currentPayment.cardBrand !== p.cardBrand
  );
};
