import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Button from '../../../components/Button';
import { MapStyles } from '../Shared/MapStyles';
import { setInitValues } from '../Shared/initialValues';
import { EditValidationSchema } from '../Create/CreateValidationSchema';
import { MapForm } from '../Shared/MapForm';
import { MapFormFields } from '../Shared/MapFormFields';
import { UPDATE_MAP } from 'Mutations/updateMap';
import { GET_MAP } from 'Queries/Maps/getMap';

const EditMap = ({ className = '' }) => {
  const [updateMap, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_MAP);
  const [hasMapErrors, setHasMapErrors] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const mapId = queryParams.get('mapId');
  const { loading, error, data } = useQuery(GET_MAP, {
    variables: {
      id: mapId
    }
  });

  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  const initialValues = setInitValues(data);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditValidationSchema}
      onSubmit={async values => {
        await updateMap({
          variables: {
            input: {
              id: mapId,
              name: values.mapName,
              description: values.mapDescription,
              type: values.mapType,
              venueId: values.venueId,
              buildings: values.buildings,
              ...(values.file?.name && { file: values.file })
            }
          }
        });
      }}>
      {({ isSubmitting, dirty, isValid, submitForm }) => {
        return (
          <MapForm data-testid="map-edit-form" className={className} isLoading={false}>
            <>
              <div className={'title'}>EDIT MAP</div>
              <MapFormFields isEdit={true} setHasMapErrors={setHasMapErrors} />
              <SubmitButton
                className="form-button"
                primary
                variant="contained"
                size="small"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid || updateLoading || hasMapErrors}
                data-testid="map-edit-submit-cta"
                onClick={e => {
                  e.preventDefault();
                  submitForm();
                }}>
                SAVE
              </SubmitButton>
              {updateError && <div>Ups...There was an error editing the map</div>}
              {updateLoading && <div>Editing the map...</div>}
              {updateData && <div>Success</div>}
            </>
          </MapForm>
        );
      }}
    </Formik>
  );
};

const SubmitButton = styled(Button)`
  &&& {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export default MapStyles(EditMap);
