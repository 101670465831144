import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import { withSnackbarContextActions } from 'Store/SnackbarContext';
import { reportGraphqlError } from 'Helpers/graphqlResponseUtil';

import { withUserContext } from 'Store/UserContext';
import { IS_ADMIN, IS_GROUP_LEADER } from 'Constants/authRules';
import { check } from 'Components/Can';
import { subRouteCodes as SUB_ROUTES } from '../constants/routes';

export const ORDER_CHECKOUT = gql`
  mutation OrderCheckout($input: OrderCheckoutInput) {
    checkout(input: $input) {
      success
      order {
        payments {
          last4
          cardBrand
        }
        orderItems {
          id
          price
          quantity
          addOnProduct {
            id
            price
            addOn {
              description
              id
              name
              unitName
            }
          }
          reservation {
            id
            status {
              id
              name
            }
            startDate
            endDate
            assignmentConfirmed
            stallProduct {
              id
              nightly
              startDate
              endDate
              name
              description
              price
            }
            rvProduct {
              id
              startDate
              endDate
              price
              nightly
              rvLot {
                id
                name
                description
                sewer
                water
                power
              }
              name
              description
            }
          }
        }
        createdAt
        fee
        id
        notes
        successor
        total
        user {
          id
        }
        event {
          id
          name
          startDate
          endDate
          checkInTime
          checkOutTime
          venue {
            city
            description
            id
            name
            phone
            state
            street
            street2
            timeZone
            zip
            interactiveMaps
            interactiveMapsForRenters
            requiredMapAssignmentsForRenters
            allowCardReaderPayments
            stripeLocation
          }
          stallProducts {
            description
            endDate
            id
            name
            nightly
            price
            startDate
          }
          addOnProducts {
            id
            price
            addOn {
              id
              name
              description
              unitName
            }
          }
          rvProducts {
            id
            name
            description
            startDate
            endDate
            price
            nightly
            rvLot {
              id
              name
              description
              sewer
              water
              power
            }
          }
        }
      }
      error
    }
  }
`;

const orderItemGroups = (orderItems = []) =>
  orderItems.reduce(
    (state, curr) => {
      if (curr.addOnProduct) {
        return {
          ...state,
          addOnProduct: true
        };
      }
      if (curr.reservation && curr.reservation.stallProduct) {
        return {
          ...state,
          stallProduct: true
        };
      }
      if (curr.reservation && curr.reservation.rvProduct) {
        return {
          ...state,
          rvProduct: true
        };
      }
      return state;
    },
    {
      addOnProduct: false,
      stallProduct: false,
      rvProduct: false
    }
  );

const successMessage = order => {
  const groups = orderItemGroups(order.orderItems);

  if (groups.rvProduct && groups.stallProduct && !groups.addOnProduct) {
    return 'Stall and RV spot reservation has been successfully created';
  }
  if (groups.rvProduct && !groups.stallProduct && !groups.addOnProduct) {
    return 'RV spot reservation has been successfully created';
  }
  if (!groups.rvProduct && groups.stallProduct && !groups.addOnProduct) {
    return 'Stall reservation has been successfully created';
  }
  if (!groups.rvProduct && !groups.stallProduct && groups.addOnProduct) {
    return 'Add on order has been successfully created';
  }
  return 'Reservation/order has been successfully created';
};

export const withOrderCheckout = graphql(ORDER_CHECKOUT, {
  props: ({ mutate }) => ({
    orderCheckout: async input => {
      return mutate({ variables: { input } });
    }
  }),
  options: props => ({
    onCompleted: ({ checkout }) => {
      if (checkout.success) {
        if (props.user.id === checkout.order.user.id) {
          props.onUpdate(props.user);
        }
        if (check(props.user.role.id, IS_ADMIN)) {
          props.history.push(SUB_ROUTES.ADMIN.ORDERS);
          props.showSnackbar(successMessage(checkout.order), {
            success: true,
            duration: 5000
          });
        } else if (check(props.user.role.id, IS_GROUP_LEADER)) {
          props.history.push(SUB_ROUTES.GROUP_LEADER.ORDERS);
          props.showSnackbar(successMessage(checkout.order), {
            success: true,
            duration: 5000
          });
        } else {
          props.history.push({
            pathname: SUB_ROUTES.RENTER.CONFIRM_RESERVATION.replace(':orderId', checkout.order.id),
            state: checkout
          });
        }
      }

      if (checkout.error) {
        reportGraphqlError(props.showSnackbar, checkout.error, checkout.error, props.user.role.name);
      }
    },
    onError: error => {
      reportGraphqlError(props.showSnackbar, `${error && typeof error == 'string' ? error : 'Order could not be created'}`, error);
    }
  })
});

export default compose(withSnackbarContextActions, withRouter, withUserContext, withOrderCheckout);
