import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import { withUserContext } from 'Store/UserContext';
import { IS_SUPER_ADMIN } from 'Constants/authRules';
import ROUTES, { base, subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import SuperAdminNavigation from '../../Navigation/SuperAdminNavigation';
import Can from '../../../components/Can';
import SuperAdminVenues from '../../../pages/SuperAdmin/SuperAdminVenues';
import CreateVenue from '../../../pages/SuperAdmin/CreateVenue/CreateVenue.tsx';
import Maps from '../../../pages/SuperAdmin/Map/Map';
import CreateMap from '../../../pages/SuperAdmin/Map/CreateMap';
import EditMap from '../../../pages/SuperAdmin/Map/EditMap';
import Buildings from '../../../pages/SuperAdmin/Buildings/Buildings';
import CreateBuilding from '../../../pages/SuperAdmin/Buildings/CreateBuilding';
import Addons from '../../../pages/SuperAdmin/Addons/Addons';
import CreateAddon from '../../../pages/SuperAdmin/Addons/CreateAddon';
import Stalls from '../../../pages/SuperAdmin/Stalls/Stalls';
import CreateStalls from '../../../pages/SuperAdmin/Stalls/CreateStalls';
import DeleteStalls from '../../../pages/SuperAdmin/Stalls/DeleteStalls';
import RVlots from '../../../pages/SuperAdmin/RVlots/RVlots';
import CreateRVlots from '../../../pages/SuperAdmin/RVlots/CreateRVlots';
import RVspots from '../../../pages/SuperAdmin/RVSpots/RVspots';
import CreateRVspots from '../../../pages/SuperAdmin/RVSpots/CreateRVspots';
import Users from '../../../pages/SuperAdmin/Users/Users';
import UserCreate from '../../../pages/SuperAdmin/Users/CreateUser';

const AdminHome = props => {
  useEffect(() => {
    if (props.location.pathname === ROUTES.SUPER_ADMIN) {
      props.history.push(SUB_ROUTES.SUPER_ADMIN.VENUES);
    }
  });
  return (
    <Can
      perform={IS_SUPER_ADMIN}
      yes={() => (
        <section className="root-admin">
          <SuperAdminNavigation>
            <Switch>
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.VENUES} component={SuperAdminVenues} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.CREATE_VENUE} component={CreateVenue} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.MAPS} component={Maps} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.MAPS_CREATE} component={CreateMap} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.MAPS_EDIT} component={EditMap} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.BUILDINGS} component={Buildings} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.BUILDING_CREATE} component={CreateBuilding} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.ADDONS} component={Addons} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.ADDONS_CREATE} component={CreateAddon} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.STALLS} component={Stalls} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.STALLS_CREATE} component={CreateStalls} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.STALLS_DELETE} component={DeleteStalls} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.RVLOTS} component={RVlots} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.RVLOTS_CREATE} component={CreateRVlots} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.RVSPOTS} component={RVspots} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.RVSPOTS_CREATE} component={CreateRVspots} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.USERS} component={Users} />
              <Route exact path={SUB_ROUTES.SUPER_ADMIN.USER_CREATE} component={UserCreate} />
            </Switch>
          </SuperAdminNavigation>
        </section>
      )}
      no={() => <Redirect to={props.user ? base : ROUTES.LOGIN} />}
    />
  );
};

// prettier-ignore
export default compose(withRouter, withUserContext)(AdminHome)
