import * as Yup from 'yup';

export const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phone: '',
  venueId: '',
  roleId: ''
};

export const CreateUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('first name is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('last name is required'),
  email: Yup.string()
    .email('PLEASE ENTER A VALID EMAIL')
    .required('email is required'),
  venueId: Yup.string().required('Venue is required'),
  roleId: Yup.string().required('Role is required'),
  password: Yup.string().required('Password is required'),
  phone: Yup.string()
    .matches('^[0-9]+$', 'Enter a valid phone number')
    .length(10, 'Enter a valid phone number')
    .required('Enter a valid phone number')
});
