import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { SnackbarContext } from 'Store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { deleteInitialValues, DeleteStallsSchema } from './helpers';
import { DELETE_STALLS } from 'Mutations/DeleteStalls';
import { HeadingFive } from 'Components/Headings';
import './Stalls.scss';

const DeleteStallsForm = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [deleteStalls, { data, loading, error }] = useMutation(DELETE_STALLS);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      showSnackbar('Stalls deleted successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.STALLS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to delete stalls`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  const isValid = values => {
    if (!values.stallId && !values.stallIdFrom && !values.stallIdTo) {
      return false;
    }

    if (!values.stallId && (!values.stallIdFrom || !values.stallIdTo)) {
      return false;
    }

    if (values.stallId && (!!values.stallIdFrom || !!values.stallIdTo)) {
      return false;
    }

    return true;
  };

  return (
    <div className="create-stalls-container">
      <ContextSnackbar />
      <Formik
        initialValues={deleteInitialValues}
        onSubmit={async values => {
          if (!isValid(values)) {
            showSnackbar('Give a stall ID OR a range of stall IDs', { error: true, duration: 5000 });
          }

          return await deleteStalls({
            variables: {
              input: {
                stallId: Number(values.stallId),
                stallIdFrom: Number(values.stallIdFrom),
                stallIdTo: Number(values.stallIdTo),
                buildingId: values.buildingId
              }
            }
          });
        }}
        validationSchema={DeleteStallsSchema}>
        {({ isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="DELETE STALLS" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="STALL ID" type="number" name="stallId" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="STALL ID FROM" type="number" name="stallIdFrom" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="STALL ID TO" type="number" name="stallIdTo" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="BUILDING ID" type="number" name="buildingId" variant="filled" component={FormikField} />
                </Grid>
              </Grid>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  DELETE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DeleteStallsForm;
