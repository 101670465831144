export const stallSectionHasChanged = (initialValues, values) => {
  const QuestionsAreUpdated = checkIfStallQuestionsWereUpdated(initialValues, values);
  const productsAreUpdated = checkIfStallProductsWereUpdated(initialValues, values);

  return QuestionsAreUpdated || productsAreUpdated;
};

const checkIfStallQuestionsWereUpdated = (initialValues, values) => {
  if (initialValues.stallQuestions.length !== values.stallQuestions.length) {
    return true;
  }
  for (let [index, initialQuestion] of initialValues.stallQuestions.entries()) {
    const newQuestion = values.stallQuestions[index];
    const questionWasUpdated = checkIfQuestionFieldsWereUpdated(initialQuestion, newQuestion);

    if (questionWasUpdated) {
      return true;
    }
  }
  return false;
};

const checkIfQuestionFieldsWereUpdated = (initialQuestion, newQuestion) => {
  const listOrderIsUpdated = initialQuestion.listOrder !== newQuestion.listOrder;
  const maxLengthIsUpdated = initialQuestion.maxLength !== newQuestion.maxLength;
  const minLengthIsUpdated = initialQuestion.minLength !== newQuestion.minLength;
  const questionIsUpdated = initialQuestion.question !== newQuestion.question;
  const questionTypeIsUpdated = initialQuestion.questionType !== newQuestion.questionType;
  const requiredIsUpdated = initialQuestion.required !== newQuestion.required;
  const savedIsUpdated = initialQuestion.saved !== newQuestion.saved;
  const answersWereUpdated = checkIfAnswersWereUpdated(initialQuestion, newQuestion);

  return (
    listOrderIsUpdated ||
    maxLengthIsUpdated ||
    minLengthIsUpdated ||
    questionIsUpdated ||
    questionTypeIsUpdated ||
    requiredIsUpdated ||
    answersWereUpdated ||
    savedIsUpdated
  );
};

const checkIfAnswersWereUpdated = (initialQuestion, newQuestion) => {
  if (initialQuestion.answerOptions.length !== newQuestion.answerOptions.length) {
    return true;
  }
  for (let [index, initialAnswer] of initialQuestion.answerOptions.entries()) {
    const newAnswer = newQuestion.answerOptions[index];
    if (initialAnswer.text !== newAnswer.text) {
      return true;
    }
  }
  return false;
};

const checkIfStallProductsWereUpdated = (initialValues, values) => {
  if (initialValues.stalls.length !== values.stalls.length) {
    let ratesAreValid = [];

    for (const newStallProduct of values.stalls) {
      if (
        newStallProduct.name !== '' &&
        newStallProduct.minNights !== '' &&
        newStallProduct.dateRange.startDate !== '' &&
        newStallProduct.dateRange.endDate !== '' &&
        newStallProduct.pricing !== '' &&
        newStallProduct.price !== '' &&
        newStallProduct.stallsForThisRate.length > 0
      ) {
        ratesAreValid.push(true);
      } else {
        ratesAreValid.push(false);
      }
    }

    return !ratesAreValid.some(value => !value);
  }

  for (let [index, initialStallProduct] of initialValues.stalls.entries()) {
    const newStallProduct = values.stalls[index];
    const stallProductWasUpdated = checkIfStallProductFieldsWereUpdated(initialStallProduct, newStallProduct);

    if (stallProductWasUpdated) {
      return true;
    }
  }
  return false;
};

const checkIfStallProductFieldsWereUpdated = (initialStallProduct, currentStallProduct) => {
  const stallNameIsUpdated = initialStallProduct.name !== currentStallProduct.name;
  const minNightsIsUpdated = +initialStallProduct.minNights !== +currentStallProduct.minNights;
  const startDateIsUpdated = initialStallProduct.dateRange.startDate !== currentStallProduct.dateRange.startDate;
  const endDateIsUpdated = initialStallProduct.dateRange.endDate !== currentStallProduct.dateRange.endDate;
  const pricingIsUpdated = initialStallProduct.pricing !== currentStallProduct.pricing;
  const priceIsUpdated = +initialStallProduct.price !== +currentStallProduct.price;
  const flipIsUpdated = initialStallProduct.flippingDisabled !== currentStallProduct.flippingDisabled;
  const stallsAreUpdated = checkIfSpotsWereUpdated(initialStallProduct, currentStallProduct);

  return (
    stallNameIsUpdated ||
    minNightsIsUpdated ||
    startDateIsUpdated ||
    endDateIsUpdated ||
    pricingIsUpdated ||
    priceIsUpdated ||
    flipIsUpdated ||
    stallsAreUpdated
  );
};

const checkIfSpotsWereUpdated = (initialStallProduct, currentStallProduct) => {
  if (initialStallProduct.stallsForThisRate.length !== currentStallProduct.stallsForThisRate.length && currentStallProduct.stallsForThisRate.length > 0) {
    return true;
  }
};
