import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import { GET_MAP } from 'Queries/Maps/getMap';
import usePreviousValue from 'Hooks/usePrevious';
import PRODUCT_REF_TYPE from 'Constants/productRefType';
import { ORDERS_FOR_MAP_DETAILS } from 'Queries/Maps/getOrdersForMapDetails';
import Button from '../../../components/Button';
import MapLoader from './mapLoader';
import getDataForMaps from './getDataForMaps';
import { getAddOnInfo, getDates } from './mapUtils';
import './MapsViewer.scss';

const Maps = ({ mapId, eventId, type, startDate, endDate, selectedStartDate, selectedEndDate }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [availableSpotsIds, setAvailableSpotsIds] = useState(null);
  const [isAvailableSpot, setIsAvailableSpot] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const previousMapId = usePreviousValue(mapId);
  const previousStartDate = usePreviousValue(startDate);
  const previousEndDate = usePreviousValue(endDate);
  const previousSelectedStartDate = usePreviousValue(selectedStartDate);
  const previousSelectedEndDate = usePreviousValue(selectedEndDate);
  const [spaceId, setSpaceId] = useState(null);
  const { push } = useHistory();

  const [getMap, { data: mapData, error: mapError, loading: mapLoading }] = useLazyQuery(GET_MAP, {
    variables: { id: mapId },
    fetchPolicy: 'network-only'
  });

  const [getOrderData, { data: orderData, loading: orderLoading }] = useLazyQuery(ORDERS_FOR_MAP_DETAILS, {
    variables: {
      input: {
        eventId,
        spaceId,
        startDate: selectedStartDate ? selectedStartDate : startDate,
        endDate: selectedEndDate ? selectedEndDate : endDate,
        xRefTypeId: type === 'stall' ? PRODUCT_REF_TYPE.STALL_PRODUCT : PRODUCT_REF_TYPE.RV_PRODUCT
      }
    },
    fetchPolicy: 'network-only'
  });

  const getDataForMapsCallback = useCallback(async (type, eventId, startDate, endDate) => {
    if (type && eventId && startDate && endDate) {
      const results = await getDataForMaps(type, eventId, startDate, endDate);
      setAvailableSpotsIds(results);
    }
  }, []);

  useEffect(() => {
    if (
      mapId &&
      eventId &&
      ((startDate && endDate && (previousStartDate !== startDate || previousEndDate !== endDate)) ||
        (selectedStartDate && selectedEndDate && (previousSelectedStartDate !== selectedStartDate || previousSelectedEndDate !== selectedEndDate)))
    ) {
      setDataLoaded(false);
      getMap();
      getDataForMapsCallback(type, eventId, selectedStartDate ? selectedStartDate : startDate, selectedEndDate ? selectedEndDate : endDate);
    }
  }, [mapId, previousMapId, type, eventId, startDate, endDate, selectedStartDate, selectedEndDate, previousStartDate, previousEndDate, getDataForMapsCallback]);

  useEffect(() => {
    if (availableSpotsIds) {
      setDataLoaded(true);
    }
  }, [availableSpotsIds]);

  useEffect(() => {
    if (orderData && !orderLoading && orderData?.getOrdersBySpaceAndEventId?.success) {
      setOrderInfo(orderData);
      setIsAvailableSpot(false);
    }
  }, [orderData, orderLoading]);

  const handleSelect = async selectedSpot => {
    if (availableSpotsIds) {
      const spaceId = selectedSpot.split('_')[1];
      if (spaceId) {
        setOrderInfo(null);
        await setSpaceId(spaceId);
        getOrderData();
      }
    }
  };

  if (!mapId || !eventId) {
    return 'Select a map and an event from the list';
  }

  if (!dataLoaded) {
    return 'Loading Map';
  }

  return (
    <div className="admin-maps">
      <div className="admin-maps-info">
        <h3>Details:</h3>
        {!orderInfo && !isAvailableSpot && <div className="admin-maps-info__msg">Click on different spots to get information.</div>}
        {orderInfo && orderInfo?.getOrdersBySpaceAndEventId?.count == 0 && !isAvailableSpot && (
          <div className="admin-maps-info__msg">Disabled or not available for the event.</div>
        )}
        {isAvailableSpot && !orderInfo && <div className="admin-maps-info__msg">Available</div>}
        <div className="admin-maps-info__list" data-testid="map-info">
          {orderInfo?.getOrdersBySpaceAndEventId?.count > 0 &&
            orderInfo.getOrdersBySpaceAndEventId.orders.map(order => (
              <div className="admin-maps-info__order" key={order.id}>
                <p>
                  Name: <span>{order.user?.fullName}</span>
                </p>
                <p>
                  Dates: <span>{getDates(order, type)}</span>
                </p>
                <p>
                  Has add ons?: <span>{getAddOnInfo(order)}</span>
                </p>
                <Button type={'submit'} primary onClick={() => push(`/admin/order/edit/${order.id}`)}>
                  See more details
                </Button>
              </div>
            ))}
        </div>
      </div>
      <div className="admin-maps-container" data-testid="map-container">
        {mapData && !mapLoading && !mapError && (
          <MapLoader
            width="1024px"
            height="576px"
            readMode={true}
            mapId={mapData.map.id}
            url={mapData.map.url}
            availableSpots={availableSpotsIds}
            onSelect={handleSelect}
            onAvailable={() => {
              setOrderInfo(null);
              setIsAvailableSpot(true);
            }}
            mapType={type}
          />
        )}
        {mapLoading && <div>Loading map</div>}
        {mapError && <div>Error loading map</div>}
      </div>
    </div>
  );
};

export default Maps;
