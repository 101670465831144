import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import MapLoader from './mapLoader';
import { FormSelect } from 'Components/Select';
import useWindowSize from 'Helpers/useWindowSize';
import './MapSpotsSelector.scss';

const formatMapValues = maps => {
  return maps.map(map => {
    return {
      value: map.id,
      label: map.name
    };
  });
};

// Easy intersect function. Arrays won't be too big for this use case.
function intersect(a, b) {
  var setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
}

const MapSpotsSelector = ({ maps, onSelect, availableSpots, selectedSpots, maxSelection, buildings }) => {
  const [mapId, setMapId] = useState(null);
  const [selectedMap, setSelectedMap] = useState(null);
  const { isMobile, size } = useWindowSize();

  const handleSelect = spotHTMLId => {
    const spotId = spotHTMLId.split('_')[1];
    if (!spotId) return;

    const selectedSpot = availableSpots.find(availableSpot => availableSpot.id === spotId);
    if (selectedSpot && onSelect) {
      onSelect(selectedSpot);
    }

    const deselectedSpot = selectedSpots.find(selectedSpot => selectedSpot.id === spotId);
    if (deselectedSpot && onSelect) {
      onSelect(deselectedSpot);
    }
  };

  useEffect(() => {
    const filteredMaps = filterMapsForEvent(maps);
    if (filteredMaps.length > 0 && buildings && buildings.length > 0) {
      let map;
      if (mapId) {
        map = filteredMaps.find(map => +map.id === +mapId);
      } else {
        map = filteredMaps[0];
        setMapId(map.id);
      }
      setSelectedMap(map);
    }
  }, [maps, mapId]);

  const availableIds = availableSpots.map(spot => spot.id);

  let mapSize = 660;
  if (isMobile) {
    mapSize = size - 40; // Card padding
  }

  const filterMapsForEvent = useCallback(
    maps => {
      // Maps filter is enabled only for Stalls for now
      if (!buildings || buildings.length === 0) {
        return maps;
      }

      const availableBuildings = buildings.map(building => building.value);

      return maps.filter(map => {
        const intersectArray = intersect(availableBuildings, map.buildings);
        if (intersectArray.length > 0) return true;
        return false;
      });
    },
    [maps, buildings]
  );

  return (
    <div className="maps-spots-selector">
      <Select
        className="available-maps"
        cb={e => setMapId(e.target.value)}
        label="MAP"
        options={formatMapValues(filterMapsForEvent(maps))}
        selectedOption={mapId}
        disabled={false}
      />
      <div className="maps-spots-selector__wrapper">
        {mapId && selectedMap && (
          <MapLoader
            width={`${mapSize}px`}
            height="372px"
            readMode={false}
            mapId={mapId}
            url={selectedMap?.url}
            availableSpots={availableIds || []}
            selectedSpots={selectedSpots || []}
            mapType={selectedMap?.type === 'barn' ? 'stall' : 'rv'}
            maxSelection={maxSelection}
            onSelect={id => handleSelect(id)}
            onDeselect={id => handleSelect(id)}
          />
        )}
      </div>
    </div>
  );
};

const Select = styled(FormSelect)`
  width: 100%;
  &&& {
    .MuiFormControl-root {
      background: #f1f4f7;
    }

    .MuiInputBase-root::before {
      border-bottom: 1px solid #576574;
    }

    .MuiInputBase-root {
      height: 51px;
    }
  }
`;

export default MapSpotsSelector;
