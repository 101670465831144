import React from 'react';
import { NavItem } from './NavItem';

export const Breadcrumbs = ({ displayStallsItem, displayRvsItem }) => (
  <div className={'breadcrumbs'}>
    <NavItem label={'BASIC DETAILS'} step={'details'} />
    {displayStallsItem && <NavItem label={'STALLS'} step={'stalls'} />}
    {displayRvsItem && <NavItem label={'RV SPOTS'} step={'rvs'} />}
  </div>
);
