import * as Yup from 'yup';

export const initialValues = {
  amount: '',
  spotLetter: '',
  firstSpotNumber: '',
  rvLotId: '',
  description: ''
};

export const CreateRVspotsSchema = Yup.object().shape({
  amount: Yup.number().required('amount is required'),
  rvLotId: Yup.string().required('The building id is required')
});
