import React, { useContext, useEffect, useRef } from 'react';
import { capitalize } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Separator } from 'Components/Separator';
import AddOnRows from '../../shared/AddOnRows';
import AddOnStyles from './AddOnStyles';
import { pluralizeName } from '../../Renter/Create/TicketOrderItem';
import { getValueByPropPath } from 'Utils/objectHelpers';
import WarningModal from 'Components/WarningModal/WarningModal';
import UpdatedChip from './UpdatedChip';
import DeliveryNotes from '../../shared/addOnDeliveryNotes/DeliveryNotes';
import ReadOnlyDeliveryNotes from '../../shared/addOnDeliveryNotes/ReadOnlyDeliveryNotes';
import { UserContext } from 'Store/UserContext';
import USER_ROLES from 'Constants/userRoles';

const AddOns = props => {
  const { addOnProducts, reservationEdit, className = '', isOpen, eventAddOns, isWarningOpen, handleWarningClose, toggleDeleteModalVisibility } = props;
  const { values, setFieldValue } = useFormikContext();
  const { user } = useContext(UserContext);
  const isRenter = user?.role?.name === USER_ROLES.RENTER;
  const { stalls, rv_spot } = values;
  const { requiredAddOns = [], venue } = values.event;

  const onlyAddOns = !values.stalls?.quantity && !values.rv_spot?.quantity;
  const deletedAddOnsObject = {};
  addOnProducts?.forEach(addOn => {
    deletedAddOnsObject[addOn.addOnProduct?.id] = 0;
  });

  const prevStalls = useRef();
  const prevRvSpot = useRef();
  const calculatedAddOns = useRef({});

  useEffect(() => {
    const stallsChanged = checkIfProductChanged(prevStalls.current, stalls);
    const rvSpotChanged = checkIfProductChanged(prevRvSpot.current, rv_spot);
    const hasDecreased = checkIfAddOnsDecreased(calculatedAddOns.current, values.addOns);

    if (
      (stallsChanged || rvSpotChanged || (hasDecreased && isRenter)) &&
      venue?.venueConfig?.requiredAddons &&
      requiredAddOns.length > 0 &&
      checkIfStallsOrRvsExist(stalls, rv_spot)
    ) {
      const venueAddOns = values.event?.addOnProducts?.filter(a => !a.disabled);

      if (!venueAddOns || venueAddOns.length === 0) return;

      const addonsToSum = [];

      for (let i = 0; i < requiredAddOns.length; i++) {
        const requiredAddOn = requiredAddOns[i];
        const { addOnProduct, xRefTypeId, ratio, condition } = requiredAddOn;
        const venueAddOnProduct = venueAddOns.find(x => +x.addOn?.id === +addOnProduct);

        if (!venueAddOnProduct) {
          continue; // Continue
        }

        let baseQuantity = 0;

        // Calculate base quantity based on stalls
        if (checkIfProductExist(stalls) && xRefTypeId === 1) {
          const stallsQuantity = condition === 'PRODUCT' ? +stalls.quantity : calculateNights(stalls.start, stalls.end);
          baseQuantity += stallsQuantity;
        }

        // Calculate base quantity based on rv_spot
        if (checkIfProductExist(rv_spot) && xRefTypeId === 3) {
          const rvQuantity = condition === 'PRODUCT' ? +rv_spot.quantity : calculateNights(rv_spot.start, rv_spot.end);
          baseQuantity += rvQuantity;
        }

        if (baseQuantity === 0) {
          continue;
        }

        // Calculate the total addOn quantity using the ratio
        const addOnQuantity = baseQuantity * ratio;

        addonsToSum.push({
          id: venueAddOnProduct.id,
          qty: addOnQuantity
        });
      }

      const addOnsOutputObj = addonsToSum.reduce((acc, item) => {
        if (acc[item.id]) {
          acc[item.id] += item.qty;
        } else {
          acc[item.id] = item.qty;
        }
        return acc;
      }, {});

      const mergedAddOns = { ...values.addOns, ...addOnsOutputObj };
      setFieldValue('addOns', mergedAddOns);
      calculatedAddOns.current = mergedAddOns;
    }

    prevStalls.current = stalls;
    prevRvSpot.current = rv_spot;
  }, [stalls, rv_spot, venue, requiredAddOns, setFieldValue, values.addOns]);

  const calculateNights = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24); // Convert time difference to days
  };

  const checkIfStallsOrRvsExist = (stalls, rvs) => {
    const hasStalls = !!stalls.quantity && !!stalls.start && !!stalls.end;
    const hasRvs = !!rvs.quantity && !!rvs.start && !!rvs.end;

    return hasStalls || hasRvs;
  };

  const checkIfProductExist = p => {
    return !!p.quantity && !!p.start && !!p.end;
  };

  const checkIfProductChanged = (prev, curr) => {
    if (!prev) return false;
    return prev.quantity !== curr.quantity || prev.start !== curr.start || prev.end !== curr.end;
  };

  const checkIfAddOnsDecreased = (prevAddOns, newAddOns) => {
    for (const key in prevAddOns) {
      if ((newAddOns[key] ?? false) !== false && newAddOns[key] < prevAddOns[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={`${className}__card-content ${isOpen ? 'open' : ''}`}>
      {isOpen ? (
        <>
          <Separator margin="0.625rem 0 1.375rem" />
          <AddOnRows {...props} />
          <DeliveryNotes />
        </>
      ) : reservationEdit && addOnProducts ? (
        <>
          <WarningModal
            isOpen={isWarningOpen}
            onCancel={handleWarningClose}
            handleClose={handleWarningClose}
            continueLabel="DELETE"
            cancelLabel="GO BACK"
            header="ARE YOU SURE?"
            text={`Are you sure you would like to delete ${onlyAddOns ? 'your entire reservation' : 'all addons'}?`}
            onContinue={() => {
              if (onlyAddOns) toggleDeleteModalVisibility();
              else setFieldValue('addOns', deletedAddOnsObject);
              handleWarningClose && handleWarningClose();
            }}
          />
          <div className={'separator'} />
          <div className={'add-on-row'}>
            {eventAddOns?.map(addOn => {
              const bookedAddOn = addOnProducts.find(bookedAddOn => bookedAddOn.addOnProduct.id === addOn.id) || {};
              const addOnProduct = bookedAddOn.addOnProduct ? bookedAddOn.addOnProduct : values.addOns[addOn.id] ? addOn : null;
              const quantity = values.addOns && values.addOns[addOnProduct?.id];
              const isUpdated = bookedAddOn?.quantity !== quantity || (bookedAddOn?.quantity > 0 && !values.addOns[addOnProduct?.id]);

              return (
                <div key={addOnProduct?.id || addOn.id} className={'column'}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h4>{capitalize(addOn.addOn.name)}</h4>
                    {isUpdated && (
                      <div style={{ marginTop: '25px' }}>
                        <UpdatedChip />
                      </div>
                    )}
                  </div>
                  {`${(values.addOns && values.addOns[addOnProduct?.id]) || '-'} ${
                    quantity ? pluralizeName(capitalize(getValueByPropPath(addOnProduct, 'addOn.unitName', '')), quantity) : ''
                  }`}
                </div>
              );
            })}
          </div>
          <div className={'separator'} />
          <ReadOnlyDeliveryNotes showChip />
        </>
      ) : null}
    </div>
  );
};

export default AddOnStyles(AddOns);
