import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { headingThree } from '../../styles/Typography';

const HeadingThreeBase = props => {
  return (
    <Typography className={props.className} variant="h3">
      {props.label}
    </Typography>
  );
};

const HeadingThree = styled(HeadingThreeBase)`
  &&& {
    ${headingThree}
  }
`;

export default HeadingThree;
