import gql from 'graphql-tag';

export const CREATE_ADDON = gql`
  mutation createAddon($input: CreateAddonInput) {
    createAddon(input: $input) {
      name
      unitName
      description
      venue {
        id
      }
    }
  }
`;
