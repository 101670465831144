import gql from 'graphql-tag';

export const CREATE_RVLOT = gql`
  mutation createRVlot($input: CreateRVlotInput) {
    createRVlot(input: $input) {
      name
      description
      venue {
        id
      }
      power
      sewer
      water
    }
  }
`;
