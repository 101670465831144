import React, { memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';

import { subRouteCodes as SUB_ROUTES } from '../../constants/routes';
import EventsTable from '../../containers/Tables/Admin/EventsTable';
import { displayFlex } from '../../styles/Mixins';
import AddButton from '../../components/Button/AddButton';
import Snackbar from '../../components/Snackbar';
import { withSnackbarContextActions } from 'Store/SnackbarContext';
import { HeadingOne } from 'Components/Headings';

const AdminEventsBase = props => {
  const { className, showSnackbar } = props;

  return (
    <AdminEvents isMobile={isMobile}>
      <section className={className}>
        <FlexWrapper isMobile={isMobile}>
          <HeadingOne label="EVENTS" className="" />
          <CreateEventLink to={SUB_ROUTES.ADMIN.CREATE_EVENT}>
            <AddButton label={isMobile ? '' : 'CREATE EVENT'} styles={isMobile ? { width: '40px', height: '40px', marginRight: '16px' } : {}} />
          </CreateEventLink>
        </FlexWrapper>
        <EventsTable showSnackbar={showSnackbar} onEditClick={row => props.history.push(`events/edit/${row.id}`)} />
        <Snackbar />
      </section>
    </AdminEvents>
  );
};

const AdminEvents = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 44px 0;
      min-width: 100%;
    `}

  ${({ isMobile }) =>
    !isMobile &&
    `
      margin: 85px 50px 50px;
      max-width: 1800px;
      min-width: 1130px;
    `}
`;

const FlexWrapper = styled.div`
  ${displayFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  ${({ isMobile }) =>
    isMobile &&
    `
      margin-left: 22px;
    `}
`;

const CreateEventLink = styled(Link)`
  text-decoration: none;
`;

export default compose(withRouter, withSnackbarContextActions)(memo(AdminEventsBase));
