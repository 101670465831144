import gql from 'graphql-tag';

export const INTERACTIVE_MAPS_FLAG = gql`
  query VenueInteractiveMapsFlag($id: ID) {
    venue(id: $id) {
      id
      interactiveMaps
      interactiveMapsForRenters
      requiredMapAssignmentsForRenters
    }
  }
`;
