export const mapStallProductList = (stallProducts = []) =>
  stallProducts.map(props => {
    const { name, id, nightly, price, description } = props;
    return {
      ...props,
      header: name,
      id,
      key: `product-${id}`,
      nightly,
      price,
      subheader: description,
      description
    };
  });

export const mapRvProductList = products =>
  products.map(props => {
    const { id, price, rvLot, description: rvProductDescription, name: rvProductName } = props;
    const { description, name, power, sewer, water } = rvLot;

    return {
      ...props,
      description: '',
      header: rvProductName || name,
      id,
      key: `product-${id}`,
      power,
      price,
      sewer,
      subheader: rvProductDescription || description,
      water
    };
  });
