import * as Yup from 'yup';

export const initialValues = {
  name: '',
  venueId: ''
};

export const CreateBuildingSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Building name is required'),
  venueId: Yup.string().required('Venue is required')
});

export const UpdateBuildingSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Building name is required')
});
