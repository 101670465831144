import gql from 'graphql-tag';

export const RV_PRODUCT_AVAILABILITY = gql`
  query RvProductAvailability($input: RVProductAvailabilityInputType) {
    rvProductAvailability(input: $input) {
      productId
      available
    }
  }
`;
