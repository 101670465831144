export const getAddOnInfo = order => {
  const addOn = order.orderItems?.find(orderItem => !!orderItem.addOnProduct);
  if (!addOn) return 'No';

  return 'Yes';
};

export const getDates = (order, type) => {
  if (type === 'stall') {
    const stallOrderItem = order.orderItems?.find(orderItem => !orderItem.addOnProduct && orderItem.reservation?.stalls);

    if (stallOrderItem && stallOrderItem.reservation) {
      return `${stallOrderItem.reservation.startDate} / ${stallOrderItem.reservation.endDate}`;
    }
  } else {
    const rvOrderItem = order.orderItems?.find(orderItem => !orderItem.addOnProduct && orderItem.reservation?.rvSpots);

    if (rvOrderItem && rvOrderItem.reservation) {
      return `${rvOrderItem.reservation.startDate} / ${rvOrderItem.reservation.endDate}`;
    }
  }

  return 'N/A';
};
