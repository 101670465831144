import React, { createContext, useState, useCallback } from 'react';

export const SnackbarContext = createContext({
  message: '',
  open: false,
  options: {},
  // eslint-disable-next-line
  showSnackbar: (message, options) => {},
  hideSnackbar: () => {}
});

const { Provider, Consumer } = SnackbarContext;

const SnackbarProvider = props => {
  const { children } = props;
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({});

  const showSnackbar = (message, options = {}) => {
    if (!isOpen) {
      setMessage(message);
      setOptions(options);
      setIsOpen(true);
    }
  };

  const hideSnackbar = useCallback(() => {
    setIsOpen(false);
  });

  return (
    <Provider
      value={{
        snackbarOpen: isOpen,
        snackbarMessage: message,
        showSnackbar,
        hideSnackbar,
        options
      }}>
      {children}
    </Provider>
  );
};

const withSnackbarContextActions = ComposedComponent => {
  const WithSnackbarContext = props => {
    return (
      <Consumer>
        {contextValues => {
          const { hideSnackbar, showSnackbar } = contextValues;
          return <ComposedComponent {...props} hideSnackbar={hideSnackbar} showSnackbar={showSnackbar} />;
        }}
      </Consumer>
    );
  };
  WithSnackbarContext.displayName = 'WithSnackbarContext';
  return WithSnackbarContext;
};

export { SnackbarProvider, withSnackbarContextActions };
