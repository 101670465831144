import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import Checkbox from '../../../../components/Checkbox';
import colors from '../../../../styles/Colors';

export const StallDates = ({ children, rvStartDateStr, rvEndDateStr, stallsStartDateStr, stallsEndDateStr, resStart, resEnd, checkMinNightsWithParams }) => {
  const { values, setFieldValue } = useFormikContext();
  const { sameDates } = values;
  const [checked, setResDatesChecked] = useState(sameDates);

  const setDatesFromStalls = checked => {
    if (checked && resStart && resEnd) {
      setFieldValue('rv_spot', {
        ...values.rv_spot,
        start: resStart,
        end: resEnd
      });
    }
  };

  const toggleCheckbox = () => {
    const newValue = !checked;
    setDatesFromStalls(newValue);
    setResDatesChecked(newValue);
    checkMinNightsWithParams({ startDate: resStart, endDate: resEnd });
  };

  useEffect(() => {
    if ((stallsStartDateStr !== rvStartDateStr || rvEndDateStr !== stallsEndDateStr) && checked) {
      setDatesFromStalls(checked);
    }
  }, [rvStartDateStr, rvEndDateStr, checked, stallsStartDateStr, stallsEndDateStr]);

  useEffect(() => {
    setFieldValue('sameDates', checked);
  }, [checked]);

  const showCheckbox = Boolean(resEnd && resStart);

  return <>{children({ checked, toggleCheckbox, showCheckbox })}</>;
};

export const StyledCheckbox = styled(Checkbox)`
  &&& {
    span svg {
      color: ${colors.secondary};
    }
  }
`;
