import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';

import Button from '../../../../components/Button';
import { LinkRow } from './LinkRow';

export const LinksRows = ({ links = [] }) => {
  const { values, setFieldValue } = useFormikContext();
  const [activeRows, setActiveRows] = useState([]);

  const addRow = () => {
    setFieldValue('eventLinks', [...values.eventLinks, { id: '', url: '', label: '', description: '' }]);
  };

  const removeRow = element => () => {
    setFieldValue('eventLinks', [...values.eventLinks.slice(0, element), ...values.eventLinks.slice(element + 1)]);
  };

  useEffect(() => {
    setActiveRows([...values.eventLinks].map((_, key) => key));
  }, [JSON.stringify(values.eventLinks)]);

  return (
    <>
      {activeRows.map(id => {
        return (
          <LinkRow key={id} id={id} links={links}>
            <LinksActionsContainer>
              <a className={'remove-link'} onClick={removeRow(id)} onKeyPress={removeRow(id)} role={'button'} tabIndex={0}>
                <DeleteIcon /> REMOVE
              </a>
            </LinksActionsContainer>
          </LinkRow>
        );
      })}

      <AddLinkButton className="add-link-button" secondary onClick={addRow}>
        + Link
      </AddLinkButton>
    </>
  );
};

const LinksActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  a {
    cursor: pointer;
    &:nth-child(1) {
      margin-right: 15px;
    }
  }
`;

const AddLinkButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`;
