import { useLocation } from 'react-router';

import authRules from '../../constants/authRules';
import { withUserContext } from 'Store/UserContext';

export const check = (role, action) => {
  const permissions = authRules[role];
  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;
  return staticPermissions && staticPermissions.includes(action);
};

const Can = props => {
  const userId = props.user ? props.user.role.id : null;
  const { pathname } = useLocation();
  if (check(userId, props.perform)) {
    return props.yes();
  }
  if (!props.ignoreRoute) {
    props.rejectRoute(pathname);
  }
  return props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
  user: null,
  ignoreRoute: false
};

export default withUserContext(Can);
