import gql from 'graphql-tag';

export const EVENT_BY_ID = gql`
  query GetEventById($eventId: ID) {
    event(id: $eventId) {
      id
      description
      name
      checkInTime
      checkOutTime
      startDate
      endDate
      protectedStartDate
      protectedEndDate
      protectedEvent
      hidden
      openDate
      closeDate
      createdAt
      stallQuestions {
        id
        question
        questionType
        answerOptions
        required
        minLength
        maxLength
        saved
      }
      stallProducts {
        id
        nightly
        startDate
        endDate
        booked
        name
        description
        price
        stalls {
          id
        }
        bookedStalls
        soldOut
        minNights
        stallFlip
      }
      rvQuestions {
        id
        question
        answerOptions
        questionType
        required
        listOrder
        minLength
        maxLength
        saved
      }
      rvProducts {
        id
        startDate
        endDate
        nightly
        price
        booked
        assignedSpots
        rvLot {
          id
          name
        }
        rvSpots {
          id
          name
        }
        minNights
        rvFlip
      }
      orders {
        id
      }
      addOnProducts {
        id
        price
        booked
        disabled
        addOn {
          id
          name
          unitName
        }
      }
      requiredAddOns {
        id
        eventId
        addOnProduct
        xRefTypeId
        ratio
        condition
        max
      }
      venueAgreement {
        id
        name
        description
        url
      }
      venueMap {
        id
        name
      }
      isGroupCodeRequired
      allowAddOnOnlyOrder
      eventLinks {
        id
        url
        label
        description
      }
    }
  }
`;
