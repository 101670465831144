import styled from 'styled-components';
import OSTooltip from '../../../../components/Tooltip/OSTooltip';
import InfoIcon from '@material-ui/icons/Info';

export const checkIfFlipIsValid = (buildingsWithFlipping, buildingId, currentStallProduct, noSelectableBuildingsId) => {
  const buildingWithFlipping = buildingsWithFlipping.find(b => b.id === buildingId);

  const dateIsSelected = checkIfDateIsSelected(currentStallProduct.dateRange);
  const thisRateMatchesDBRatesFlip = buildingFromDBAndRateFlipMatch(buildingWithFlipping, currentStallProduct);
  const thisRateMatchesOtherRatesFlipInThisEvent = buildingsInThisEventAndRateFlipMatch(buildingId, noSelectableBuildingsId);

  return dateIsSelected && thisRateMatchesDBRatesFlip && thisRateMatchesOtherRatesFlipInThisEvent;
};

const checkIfDateIsSelected = ({ startDate, endDate }) => {
  return !!startDate && !!endDate;
};

const buildingFromDBAndRateFlipMatch = (buildingWithFlipping, currentStallProduct) => {
  const rateFlipIsDisabled = currentStallProduct.flippingDisabled;
  const stallProducts = excludeCurrentProduct(buildingWithFlipping, currentStallProduct.id);

  if (!buildingWithFlipping || stallProducts.length === 0) {
    return true;
  }

  const FIRST_STALL_PRODUCT_INDEX = 0;
  const { flip } = stallProducts[FIRST_STALL_PRODUCT_INDEX];

  return (!rateFlipIsDisabled && flip) || (rateFlipIsDisabled && !flip);
};

export const excludeCurrentProduct = (buildingWithFlipping, currentProductId) => {
  return buildingWithFlipping?.stallProducts.filter(sp => sp.id !== currentProductId);
};

const buildingsInThisEventAndRateFlipMatch = (buildingId, noSelectableBuildingsId) => {
  const isFound = noSelectableBuildingsId.has(buildingId);
  return !isFound;
};

export const getLabel = (buildingsWithFlippingInDB, buildingId, labelText, selectedBuildings, index, currentProductId) => {
  const buildingWithFlippingInDB = buildingsWithFlippingInDB.find(b => b.id === buildingId);
  const productsWithFlippingInDB = excludeCurrentProduct(buildingWithFlippingInDB, currentProductId);
  const selectedBuilding = selectedBuildings[buildingId];

  if (!showFlipWarning(productsWithFlippingInDB, selectedBuilding, index)) {
    return labelText;
  }

  const flipInfo = getFlipInformation(productsWithFlippingInDB, selectedBuilding);
  return <LabelWithTooltip labelText={labelText} flipInfo={flipInfo} />;
};

const showFlipWarning = (productsWithFlippingInDB, selectedBuilding, index) => {
  return hasFlippingInDB(productsWithFlippingInDB) || buildingIsSelectedInOtherRate(selectedBuilding, index);
};

const hasFlippingInDB = productsWithFlippingInDB => {
  return productsWithFlippingInDB && productsWithFlippingInDB.length > 0;
};

export const buildingIsSelectedInOtherRate = (selectedBuilding, index) => {
  return selectedBuilding && (!selectedBuilding.indexSet.has(index) || selectedBuilding.indexSet.size > 1);
};

const getFlipInformation = (productsWithFlippingInDB, selectedBuilding) => {
  if (productsWithFlippingInDB && productsWithFlippingInDB.length > 0) {
    return getWarningForBuildingWithFlipFromDB(productsWithFlippingInDB);
  }

  return getWarningForBuildingWithFlipFromThisEvent(selectedBuilding);
};

const getWarningForBuildingWithFlipFromDB = productsWithFlippingInDB => {
  const FIRST_STALL_PRODUCT_INDEX = 0;
  const { name, event, flip } = productsWithFlippingInDB[FIRST_STALL_PRODUCT_INDEX];
  const flipStatus = getFlipStatus(flip);
  return ` This barn has flipping ${flipStatus} in rate "${name}" in event "${event}"`;
};

const getWarningForBuildingWithFlipFromThisEvent = selectedBuilding => {
  const flipStatus = getFlipStatus(selectedBuilding.flip);
  return `This barn has flipping ${flipStatus} in other rate in this event`;
};

const getFlipStatus = flip => {
  return flip ? 'enabled' : 'disabled';
};

const LabelWithTooltip = ({ labelText, flipInfo }) => {
  return (
    <BarnCheckboxLabel>
      <span>{labelText}</span>
      <OSTooltip arrow title={flipInfo} placement="top">
        <InfoIcon fontSize="small" color="primary" />
      </OSTooltip>
    </BarnCheckboxLabel>
  );
};

const BarnCheckboxLabel = styled.div`
  display: flex;

  span {
    margin-right: 5px;
  }
`;
