import gql from 'graphql-tag';

export const UPDATE_ADDON = gql`
  mutation updateAddon($input: UpdateAddonInput) {
    updateAddon(input: $input) {
      name
      unitName
      description
      id
    }
  }
`;
