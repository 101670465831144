import gql from 'graphql-tag';

export const SAVED_QUESTIONS = gql`
  query SavedQuestions($input: SavedQuestionsInput) {
    savedQuestions(input: $input) {
      id
      question
      questionType
      answerOptions
    }
  }
`;
