import React, { memo } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import AdminMapsNavigation from '../../containers/Navigation/AdminMapsNavigation';

const RVsBase = props => {
  const { className } = props;

  return (
    <RVs isMobile={isMobile}>
      <AdminMapsNavigation klass={className} type="rv" />
    </RVs>
  );
};

const RVs = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 50px 0;
      min-width: 100%;
    `}

  ${({ isMobile }) =>
    !isMobile &&
    `
      margin: 85px 50px 50px;
      max-width: 1800px;
      min-width: 1130px;
    `}

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover,
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: none;
    border: none;
    color: #cacccd;
    text-decoration: line-through;
  }
`;

export default memo(RVsBase);
