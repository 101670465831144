import gql from 'graphql-tag';

export const UPLOAD_AGREEMENT = gql`
  mutation uploadAgreement($input: UploadEventFileInput) {
    uploadAgreement(input: $input) {
      success
      error
      documentId
    }
  }
`;
