import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import moment from 'moment/moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { EventCard } from '../Cards/EventCard';
import { HeadingFour } from 'Components/Headings';
import CheckboxThemed from '../Checkbox';
import Calendar from 'Components/Calendar/Calendar';
import { DATE_FORMAT } from 'Helpers/DATE_FORMAT';
import { SnackbarContext } from 'Store/SnackbarContext';
import './Dates.scss';

export const Dates = ({ isEdit }) => {
  const MIN_START_DATE = '2019-10-30';
  const [initialStartDate, setInitialStartDate] = useState(null);
  const [initialEndDate, setInitialEndtDate] = useState(null);

  const {
    setFieldValue,
    values: {
      protectedEvent,
      protectedEntireEvent,
      password,
      eventDates,
      protectedDates,
      bookingWindow,
      closeTime,
      openTime,
      hasOrders,
      protectedStartTime,
      protectedEndTime
    }
  } = useFormikContext();

  const { showSnackbar } = useContext(SnackbarContext);

  const [selectedStartDate, setSelectedStartDate] = useState(eventDates.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(eventDates.endDate);

  const [selectedBWStartDate, setSelectedBWStartDate] = useState(bookingWindow.startDate);
  const [selectedBWEndDate, setSelectedBWEndDate] = useState(bookingWindow.endDate);

  const [selectedOpeningTime, setSelectedOpeningTime] = useState(openTime);
  const [selectedClosingTime, setSelectedClosingTime] = useState(closeTime);

  const [selectedPStartDate, setSelectedPStartDate] = useState(protectedDates.startDate);
  const [selectedPEndDate, setSelectedPEndDate] = useState(protectedDates.endDate);

  const [selectedPStartTime, setSelectedPStartTime] = useState(protectedStartTime);
  const [selectedPEndTime, setSelectedPEndTime] = useState(protectedEndTime);

  React.useEffect(() => {
    setInitialStartDate(eventDates.startDate);
    setInitialEndtDate(eventDates.endDate);
  }, []);

  useEffect(() => {
    setFieldValue('openTime', protectedStartTime);
  }, [protectedStartTime]);

  useEffect(() => {
    setFieldValue('closeTime', protectedEndTime);
  }, [protectedEndTime]);

  useEffect(() => {
    setFieldValue('eventDates', {
      startDate: selectedStartDate,
      endDate: selectedEndDate
    });
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setFieldValue('bookingWindow', {
      startDate: selectedBWStartDate,
      endDate: selectedBWEndDate
    });
  }, [selectedBWStartDate, selectedBWEndDate]);

  useEffect(() => {
    setFieldValue('openTime', selectedOpeningTime);
    setFieldValue('closeTime', selectedClosingTime);
    // We need this here, so booking window is not null, formik generates an error.
    // Async is broken idk why
    setFieldValue('bookingWindow', {
      startDate: selectedBWStartDate,
      endDate: selectedBWEndDate
    });
  }, [selectedOpeningTime, selectedClosingTime]);

  useEffect(() => {
    setFieldValue('protectedDates', {
      startDate: selectedPStartDate,
      endDate: selectedPEndDate
    });
  }, [selectedPStartDate, selectedPEndDate]);

  useEffect(() => {
    setFieldValue('protectedStartTime', selectedPStartTime);
    setFieldValue('protectedEndTime', selectedPEndTime);
    // We need this here, so booking window is not null, formik generates an error.
    // Async is broken idk why
    setFieldValue('protectedDates', {
      startDate: selectedPStartDate,
      endDate: selectedPEndDate
    });
  }, [selectedPStartTime, selectedPEndTime]);

  const handleProtectedEntireEvent = e => {
    setFieldValue('protectedEntireEvent', e.target.checked);

    if (e.target.checked) {
      const today = moment.utc(new Date()).format('YYYY-MM-DD');

      setFieldValue('protectedDates.startDate', today);
      setFieldValue('protectedDates.endDate', eventDates.endDate);
      setFieldValue('bookingWindow.startDate', eventDates.startDate);
      setFieldValue('bookingWindow.endDate', eventDates.endDate);
      setFieldValue('protectedStartTime', openTime);
      setFieldValue('protectedEndTime', closeTime);
    }
  };

  return (
    <EventCard title={'Dates'} testId="event-create-dates">
      <div className={'card-row'}>
        <div className={'card-col'}>
          <HeadingFour label="Reservable Dates for Event" />
          <p>Set the date range in which stalls and/or RV spots will be available for this event</p>
          <div className={'date-picker-container'}>
            <Calendar
              selectableRangeEnd={eventDates.endDate}
              startDatePlaceholder={'START DATE'}
              endDatePlaceholder={'END DATE'}
              plural={true}
              rangeEndWithoutLimits={true}
              defaultValue={[initialStartDate, initialEndDate]}
              cb={({ startDate, endDate }) => {
                const startDateSelected = moment(startDate).format(DATE_FORMAT);
                let setStartDate = true;

                if (hasOrders > 0) {
                  if (new Date(startDateSelected) > new Date(initialStartDate)) {
                    setStartDate = false;
                    showSnackbar('Decreasing dates is not allowed due to active orders', { error: true });
                  }
                }

                setSelectedStartDate(startDate ? moment(setStartDate ? startDate : initialStartDate).format(DATE_FORMAT) : null);

                const endDateSelected = moment(endDate).format(DATE_FORMAT);
                let setEndDate = true;

                if (hasOrders > 0) {
                  if (new Date(endDateSelected) < new Date(initialEndDate)) {
                    setEndDate = false;
                    showSnackbar('Decreasing dates is not allowed due to active orders', { error: true });
                  }
                }

                setSelectedEndDate(endDate ? moment(setEndDate ? endDate : initialEndDate).format(DATE_FORMAT) : null);
              }}
              styles={{
                colorBgContainer: '#f1f4f7',
                colorBorder: 'transparent'
              }}
            />
          </div>
        </div>

        <div className={'card-col booking-col'}>
          <HeadingFour label="Online Booking Window" />
          <p>Set the date range in which this event will be shown on Open Stalls for athletes to book stalls and/or RV spots online</p>
          <div className={'date-picker-container'}>
            <Calendar
              selectableRangeEnd={eventDates.endDate}
              startDatePlaceholder={'OPEN DATE'}
              endDatePlaceholder={'CLOSE DATE'}
              plural={true}
              showTime={true}
              backDate={MIN_START_DATE}
              defaultTime={[openTime, closeTime]}
              defaultValue={[bookingWindow.startDate, bookingWindow.endDate]}
              disabled={!eventDates.startDate || !eventDates.endDate || protectedEntireEvent}
              cb={({ startDate, endDate, openingTime, closingTime }) => {
                setSelectedBWStartDate(startDate ? moment(startDate).format(DATE_FORMAT) : null);
                setSelectedBWEndDate(endDate ? moment(endDate).format(DATE_FORMAT) : null);

                if (openingTime && closingTime) {
                  setSelectedOpeningTime(openingTime);
                  setSelectedClosingTime(closingTime);
                }
              }}
              styles={{
                colorBgContainer: '#f1f4f7',
                colorBorder: 'transparent'
              }}
            />
          </div>
        </div>
      </div>
      {((password && password.length > 0) || (protectedEvent && isEdit)) && (
        <div className={'card-row'}>
          <div className={'card-col'}>
            <div className="protected-event-heading">
              <HeadingFour label="Protected Dates" />
              <div id="entire-event-container">
                <Field name="protectedEntireEvent" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      aria-label="Entire event"
                      control={
                        <CheckboxThemed
                          name={field.name}
                          onChange={handleProtectedEntireEvent}
                          style={{ marginLeft: 15 }}
                          checked={protectedEntireEvent}
                          disabled={!eventDates.startDate || !eventDates.endDate}
                        />
                      }
                      label="Entire event"
                    />
                  )}
                </Field>
              </div>
            </div>
            <p>
              Set the date range in which the event will be protected <br />
              by the password
            </p>
            <div className={'date-picker-container'}>
              <Calendar
                selectableRangeEnd={eventDates.endDate}
                startDatePlaceholder={'START DATE'}
                endDatePlaceholder={'END DATE'}
                plural={true}
                showTime={true}
                defaultTime={[protectedStartTime, protectedEndTime]}
                defaultValue={[protectedDates.startDate, protectedDates.endDate]}
                disabled={!eventDates.startDate || !eventDates.endDate}
                cb={({ startDate, endDate, openingTime, closingTime }) => {
                  const startDateSelected = moment(startDate).format(DATE_FORMAT);
                  let setStartDate = true;

                  if (hasOrders > 0) {
                    if (new Date(startDateSelected) > new Date(initialStartDate)) {
                      setStartDate = false;
                      showSnackbar('Decreasing dates is not allowed due to active orders', { error: true });
                    }
                  }

                  setSelectedPStartDate(startDate ? moment(setStartDate ? startDate : initialStartDate).format(DATE_FORMAT) : null);

                  const endDateSelected = moment(endDate).format(DATE_FORMAT);
                  let setEndDate = true;

                  if (hasOrders > 0) {
                    if (new Date(endDateSelected) < new Date(initialEndDate)) {
                      setEndDate = false;
                      showSnackbar('Decreasing dates is not allowed due to active orders', { error: true });
                    }
                  }

                  setSelectedPEndDate(endDate ? moment(setEndDate ? endDate : initialEndDate).format(DATE_FORMAT) : null);

                  if (openingTime && closingTime) {
                    setSelectedPStartTime(openingTime);
                    setSelectedPEndTime(closingTime);
                  }
                }}
                styles={{
                  colorBgContainer: '#f1f4f7',
                  colorBorder: 'transparent'
                }}
              />
            </div>
          </div>
        </div>
      )}
    </EventCard>
  );
};
