import React, { memo } from 'react';
import styled from 'styled-components';

import { OutlinedSelect } from '../Select';
import { OutlinedTextField } from '../Fields';
import colors from '../../styles/Colors';
import Calendar from '../Calendar/Calendar';
export const FilterFieldsBase = props => {
  const { className } = props;
  return (
    <form className={props.className}>
      {props.filters.map(filter => {
        if (filter.type === 'text') {
          return (
            <div className={`${className}__field`} key={`${filter.label}__field`}>
              <OutlinedTextField id={filter.label} key={filter.label} label={filter.label} value={filter.value} onChange={e => filter.cb(e.target.value)} />
            </div>
          );
        }
        if (filter.type === 'date') {
          return (
            <Calendar
              {...filter}
              selectableRangeStart={'2019-10-30'}
              rangeEndWithoutLimits={true}
              key={filter.label}
              styles={{ colorBorder: 'rgba(0, 0, 0, 0.87)' }}
            />
          );
        }
        if (filter.type === 'select') {
          return (
            <div className={`${className}__field`} key={`${filter.label}__field`}>
              <OutlinedSelect {...filter} key={filter.label} />
            </div>
          );
        }
      })}
    </form>
  );
};

const FilterFields = styled(FilterFieldsBase)`
  display: flex;
  width: 100%;

  &__field {
    width: 200px;
    margin: 0 0.5rem 0 0;
    fieldset {
      border: 1px solid ${colors.text.secondary};
    }
  }
`;

export default memo(FilterFields);
