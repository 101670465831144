import React from 'react';
import moment from 'moment';

import { EventCard } from '../Cards/EventCard';

const ReviewRvs = ({ formikValues, initialValues }) => {
  const { rvs, rvQuestions } = formikValues;

  const initialObj = {};

  initialValues?.rvs.forEach(rv => {
    initialObj[rv.id] = rv;
  });

  rvQuestions.forEach(question => {
    if (!initialValues?.rvQuestions) {
      return (question.updated = '');
    }

    const initialQuestion = initialValues.rvQuestions.find(element => element.id === question.id);
    const questionTitleWasUpdated = initialQuestion ? question.question !== initialQuestion.question : true;
    const questionTypeWasUpdated = initialQuestion ? question.questionType !== initialQuestion.questionType : true;
    const questionRequiredWasUpdated = initialQuestion ? question.required !== initialQuestion.required : true;
    const questionMaxLengthWasUpdated = initialQuestion ? question.maxLength !== initialQuestion.maxLength : true;
    const questionMinLengthWasUpdated = initialQuestion ? question.minLength !== initialQuestion.minLength : true;
    const questionAnswerOptionsWasUpdated = initialQuestion ? question.answerOptions.length !== initialQuestion.answerOptions.length : true;
    const questionAnswerOptionsTextWasUpdated = initialQuestion
      ? question.answerOptions.filter(answerOption => {
          const initialAnswerOption = initialQuestion.answerOptions.find(element => element.id === answerOption.id);

          if (initialAnswerOption && initialAnswerOption.text !== answerOption.text) {
            return true;
          }

          return false;
        })
      : [];

    const updated =
      questionTitleWasUpdated ||
      questionTypeWasUpdated ||
      questionRequiredWasUpdated ||
      questionMaxLengthWasUpdated ||
      questionMinLengthWasUpdated ||
      questionAnswerOptionsWasUpdated ||
      questionAnswerOptionsTextWasUpdated.length > 0
        ? 'highlighted'
        : '';

    question.updated = updated;
  });

  const highlightReservableDates = ({ initialStart, initialEnd, updatedStart, updatedEnd }) => {
    if (!initialValues?.rvs || !initialStart || !initialEnd || !updatedStart || !updatedEnd) {
      return '';
    }
    const startDateWasUpdated = initialStart !== updatedStart;
    const endDateWasUpdated = initialEnd !== updatedEnd;
    return startDateWasUpdated || endDateWasUpdated ? 'highlighted' : '';
  };

  return (
    <EventCard title="RV Spots" testId="review-rvs">
      <div className="info-row">
        {rvs.length > 0 &&
          rvs.map(rv => {
            const numSpots = rv.spots.length;
            const initialRvObj = initialObj[rv.id];
            const eDates = initialRvObj
              ? {
                  initialStart: initialRvObj.startDate,
                  updatedStart: rv.dateRange.startDate,
                  initialEnd: initialRvObj.endDate,
                  updatedEnd: rv.dateRange.endDate
                }
              : {
                  initialStart: '-',
                  updatedStart: rv.dateRange.startDate,
                  initialEnd: '-',
                  updatedEnd: rv.dateRange.endDate
                };

            const getClassNames = propName => {
              const highlighted = initialRvObj && initialRvObj[propName] !== rv[propName] ? 'highlighted' : '';
              const classes = `info ${highlighted}`;
              return classes;
            };

            return (
              <div className="info-item" key={rv.rvLot.id}>
                <h3 className={initialRvObj && initialRvObj.rvLot.name !== rv.rvLot.name ? 'highlighted' : ''}>{rv.rvLot.name}</h3>
                <div className={getClassNames('minNights')}>Min nights: {rv.minNights}</div>
                <div className={getClassNames('flippingDisabled')}>RV Flipping: {rv.flippingDisabled ? 'Disabled' : 'Enabled'}</div>
                <div className={`info ${highlightReservableDates(eDates)}`}>
                  {moment(rv.dateRange.startDate).format('MM/DD/YY')} – {moment(rv.dateRange.endDate).format('MM/DD/YY')}
                </div>
                <div className={getClassNames('price')}>
                  {rv.pricing === 'nightly' ? 'Nightly' : 'Flat'} Rate – ${rv.price}
                </div>
                <div className={`info ${initialRvObj && initialRvObj.rvSpots.length !== numSpots ? 'highlighted' : ''}`}>
                  {numSpots} {numSpots === 1 ? 'spot' : 'spots'}
                </div>
              </div>
            );
          })}
      </div>
      <div className="info-questions">
        {!!rvQuestions.length && (
          <>
            <h3>Custom Questions</h3>
            {rvQuestions.map(question => {
              return (
                <div className={`info-question-bloc info ${question.updated}`} key={question.id}>
                  <p className="info-question">{question.question}</p>
                  <ul className="info-answers">
                    {question.questionType !== 'openText' &&
                      question.answerOptions.map(option => {
                        return <li key={option.id}>{option.text}</li>;
                      })}
                  </ul>
                </div>
              );
            })}
          </>
        )}
      </div>
    </EventCard>
  );
};

export default ReviewRvs;
