import { isNumeric } from '../../../../utils/numberHelpers';

export const isAddOnOnlyOrder = (addOns, stallProductId, rvProductId) => {
  const hasAddOns = Object.values(addOns).some(addOnQty => addOnQty > 0);
  const hasStall = isNumeric(stallProductId);
  const hasRV = isNumeric(rvProductId);

  return hasAddOns && !hasStall && !hasRV;
};

export const isRenterAddOnOnlyOrder = (addOns, stallProductId, rvProductId, event) => {
  const hasAddOns = Object.values(addOns).some(addOnQty => addOnQty > 0);
  const hasStall = isNumeric(stallProductId);
  const hasRV = isNumeric(rvProductId);
  const allowAddOnOnlyOrder = event?.allowAddOnOnlyOrder;

  return hasAddOns && !hasStall && !hasRV && allowAddOnOnlyOrder;
};
