import moment from 'moment';

export const getOrderItems = orderItems => {
  const stallsOrder = orderItems.find(item => !!item.reservation && item.reservation.stallProduct) || null;
  const rvsOrder = orderItems.find(item => !!item.reservation && item.reservation.rvProduct) || null;
  const addOnOrderItems = orderItems.filter(item => item.addOnProduct);

  return {
    stallsOrder,
    rvsOrder,
    addOnOrderItems
  };
};

export const parseDateRange = (from, to) => `${moment(from).format('MM/DD/YY')} - ${moment(to).format('MM/DD/YY')}`;
