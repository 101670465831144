import gql from 'graphql-tag';

export const VENUE_ADD_ONS = gql`
  query venue($id: ID) {
    venue(id: $id) {
      addOns {
        description
        id
        name
        unitName
        description
      }
      venueConfig {
        requiredAddons
      }
    }
  }
`;
