import ContextSnackbar from 'Components/Snackbar';
import { HeadingTwo } from 'Components/Headings';
import { subRouteCodes as SUB_ROUTES } from 'Constants/routes';
import AddButton from 'Components/Button/AddButton';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { displayFlex } from '../../../styles/Mixins';
import { paragraphReg } from '../../../styles/Typography';
import colors from '../../../styles/Colors';
import './RVspots.scss';

function RVspots(props) {
  const { className } = props;

  return (
    <>
      <div className={className}>
        <ContextSnackbar />
        <div className={`${className}__page-header`}>
          <div className={`${className}__header-wrapper`}>
            <HeadingTwo label="RV SPOTS" />
          </div>
        </div>
      </div>
      <div className={className}>
        <div className={`${className}__page-body`}>
          <div>
            <CreateRVspotsLink to={SUB_ROUTES.SUPER_ADMIN.RVSPOTS_CREATE}>
              <AddButton label={'CREATE RV SPOTS'} />
            </CreateRVspotsLink>
          </div>
        </div>
      </div>
    </>
  );
}

const CreateRVspotsLink = styled(Link)`
  text-decoration: none;
`;

const SuperAdminRVspots = styled(RVspots)`
  &&& {
    ${displayFlex}
    align-items: center;
    width: 100%;
    height: auto;
    padding: 12px 0 11px 20px;
    ${paragraphReg}
    font-size: 14px;
    text-align: left;
    background-color: ${colors.background.primary};
    color: ${colors.text};
    border-radius: 3px;
  }

  &__page-header {
    text-align: left;
    width: 100%
    border-bottom: 1px solid rgb(200, 214, 229);
    &&& {
      margin: 65px 0 25px;
    }

  }

  &__page-body {
    text-align: left;
    width: 100%
    &&& {
      margin: 0;
      padding: 10px
    }

    h3 {
      text-transform: uppercase;
      font-family: IBMPlexSans-Bold;
      font-size: 12px;
      font-weight: 400;
      color: rgb(79, 93, 109);
      margin-top: 0px;
      margin-bottom: 3px;
    }
  }

  &__page-body-child {
    margin-top: 20px;
  }

  &__header-wrapper {
    margin: 20px 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiInputBase-root {
    border: ${colors.text.lightGray2} 1px solid;
    border-radius: 3px;
    padding: 0 10px;
    min-width: 300px;
  }

  .MuiSvgIcon-root {
    width: .8em;
    height: .8em;
  }

  .MuiIconButton-root {
    padding-top: 6px;
  }

  .line {
    background: ${colors.border.primary};
    height: 1px;
    flex: 1;
    position: relative;
    top: 11px;
  }
`;

export default SuperAdminRVspots;
