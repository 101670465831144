import React from 'react';

import { formatPriceInformation } from '../../../../helpers';

export const pluralizeName = (name, quantity) => (quantity > 1 ? name.trim().replace(/s$/i, '') + 's' : name);

const TicketOrderItem = props => {
  const { quantityUnit, by, duration, quantity, unit, orderItemCost, adminCharge } = props;
  if (adminCharge) {
    return (
      <div className="ticket-line">
        <p>Misc charge</p>
        <p>${formatPriceInformation(orderItemCost)}</p>
      </div>
    );
  }

  return (
    <div className="ticket-line">
      <p>{`${quantity} ${pluralizeName(unit, quantity)} ${by} ${duration ? duration : ''} ${pluralizeName(quantityUnit, duration ? duration : quantity)}`}</p>
      <p>${formatPriceInformation(orderItemCost)}</p>
    </div>
  );
};

export default TicketOrderItem;
