import gql from 'graphql-tag';

export const CREATE_MAP = gql`
  mutation createMap($input: MapUpsertInput) {
    createMap(input: $input) {
      map {
        id
        name
        url
        description
        type
      }
    }
  }
`;
