import * as Yup from 'yup';

export const initialValues = {
  name: '',
  description: '',
  unitName: '',
  venueId: ''
};

export const CreateAddonSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Add on name is required'),
  description: Yup.string().required('Description is required'),
  unitName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Unit name is required'),
  venueId: Yup.string().required('Venue is required')
});

export const UpdateAddonSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Add on name is required'),
  unitName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Unit name is required')
});
