import gql from 'graphql-tag';

export const DELETE_STALLS = gql`
  mutation deleteStalls($input: DeleteStallsInput) {
    deleteStalls(input: $input) {
      success
      error
    }
  }
`;
