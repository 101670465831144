import React from 'react';

import styled from 'styled-components';
import { isMobile, MobileView } from 'react-device-detect';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useValidateAction } from 'Utils/actions';
import { actions } from 'Constants/actions';

import BulkActionsSelect from './BulkActionsSelect';
import RowSelectionInfo from './RowSelectionInfo';
import OrderSortControl from './OrderSortControl';
import TableCount from './TableCount';

const ActionBarComponent = ({
  className,
  ordering,
  setOrdering,
  count,
  filters,
  checkingInCount,
  checkingOutCount,
  checkInOutFilter,
  setCheckInOutFilter,
  loading,
  getAllOrders,
  allOrders,
  openFilters
}) => {
  const canSendResAssigments = useValidateAction('orders', actions.GET_DETAILS_SMS_COUNT);
  const canSendCustomMessage = useValidateAction('orders', actions.SEND_CUSTOM_SMS_BY_ORDER_IDS);
  const canBulkActions = canSendResAssigments && canSendCustomMessage;

  const handleOpenFilters = () => {
    isMobile && openFilters && openFilters();
  };

  return (
    <ActionBar isMobile={isMobile}>
      <div className="row-wrapper">
        <TableCount
          className={className}
          count={count}
          filters={filters}
          checkingInCount={checkingInCount}
          checkingOutCount={checkingOutCount}
          checkInOutFilter={checkInOutFilter}
          setCheckInOutFilter={setCheckInOutFilter}
          loading={loading}
        />
        <MobileView>
          <button className="filters-button" type="primary" onClick={handleOpenFilters}>
            <span>
              <FilterListIcon />
            </span>
            <span>Filters</span>
          </button>
        </MobileView>
      </div>
      <div className="action-bar">
        {canBulkActions && (
          <>
            <RowSelectionInfo getAllOrders={getAllOrders} allOrders={allOrders} className="" />
            <BulkActionsSelect />
          </>
        )}
        <OrderSortControl className={className} ordering={ordering} setOrdering={setOrdering} />
      </div>
    </ActionBar>
  );
};

const platform = window.navigator.platform;

const ActionBar = styled.div`
  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .filters-button {
    margin-right: 16px;
    margin-left: 22px;
    height: 36px;
    width: 103px;
    box-shadow: 0 0 5px 0 rgba(17, 24, 31, 0.10);
    background-color: #fff;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    &:active {
      background-color: rgba(0, 0, 0, 0.08);
    }

    svg {
      fill: #8395A7;
    }
  }

  .action-bar {
    margin-left: 50px;
    margin-right: ${platform.indexOf('Win') > -1 ? '0' : '45px'}
    padding-right: ${platform.indexOf('Win') > -1 ? '50px' : '5px'};
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-left: 20px;
      margin-right: 20px;
      padding: 0;
      justify-content: space-between;
    `}
  }
`;

export default ActionBarComponent;
