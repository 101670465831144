import { HeadingOne } from 'Components/Headings';
import React from 'react';
import { useValidateAction } from 'Utils/actions';
import { actions } from 'Constants/actions';
import { isMobile } from 'react-device-detect';

import AddButton from '../../../../components/Button/AddButton';

interface GroupsTableHeaderProps {
  handleClick: () => void;
}

const GroupsTableHeader: React.FC<GroupsTableHeaderProps> = ({ handleClick }) => {
  const canCreateGroup = useValidateAction('groups', actions.CREATE_GROUP);

  return (
    <div className={`groups-page-header ${isMobile ? 'mobile' : ''}`}>
      <div className={`groups-header-wrapper`}>
        <HeadingOne label="GROUPS" />
        {canCreateGroup && (
          <div>
            <AddButton
              className="create-groups-button"
              onClick={handleClick}
              label={isMobile ? '' : 'CREATE NEW'}
              styles={isMobile ? { width: '40px', height: '40px', marginRight: '33px' } : {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupsTableHeader;
