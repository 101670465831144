import React from 'react';

const UserSearchList = props => {
  const { tab, visible, className, users = [], setUser } = props;
  const isTabActive = tab !== null;

  if (!visible || !users.length) return null;
  return (
    <div className={`${className}__user-list-container`}>
      {users.map((user, key) => (
        <div
          className={`${className}__user-list-item ${isTabActive && key === tab ? 'active' : ''}`}
          key={user.email}
          onClick={() => setUser(user)}
          onKeyPress={() => setUser(user)}
          role="button"
          tabIndex={0}>
          {user.email}
        </div>
      ))}
    </div>
  );
};

export default UserSearchList;
