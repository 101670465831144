import gql from 'graphql-tag';

export const ORDER_FOR_VIEW_DETAILS = gql`
  query orderForViewDetails($id: ID) {
    order(id: $id) {
      id
      createdAt
      updatedAt
      total
      fee
      platformFee
      group {
        id
        name
      }
      canceled
      event {
        id
        endDate
        checkInTime
        checkOutTime
        name
        rvProducts {
          id
          price
          nightly
          rvLot {
            id
            power
            sewer
            water
          }
        }
        startDate
        venue {
          id
          name
          city
          street
          street2
          state
          phone
          description
          interactiveMaps
          interactiveMapsForRenters
          requiredMapAssignmentsForRenters
          showAssignments
          allowCardReaderPayments
          stripeLocation
        }
        addOnProducts {
          id
          price
          addOn {
            id
            name
            description
            unitName
          }
        }
        eventLinks {
          id
          label
          url
          description
        }
      }
      orderItems {
        id
        addOnProduct {
          id
          price
          addOn {
            id
            name
            unitName
          }
        }
        reservation {
          id
          endDate
          rvProduct {
            id
            price
            nightly
          }
          rvSpots {
            id
            name
          }
          stallProduct {
            id
            nightly
            price
          }
          stalls {
            id
            building {
              id
              name
            }
            name
            description
            status
          }
          startDate
        }
        quantity
      }
      payments {
        id
        amount
        cardBrand
        cardPayment
        last4
        cardReader
        adminCharge
      }
    }
  }
`;
