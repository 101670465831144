import React, { useState, useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import { Select, MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { FormikField } from '../../../components/Fields';
import { VENUES } from 'Queries/SuperAdmin';
import { HeadingFour } from 'Components/Headings';
import { MapCard } from './MapCard';
import validateSVGMap from './validateSVGMap';

const getOptions = data => {
  if (!data) return null;

  const options = data.map(data => (
    <MenuItem key={data.id} value={data.id}>
      ID: {data.id} - {data.name}
    </MenuItem>
  ));

  return options;
};

const getValueFromType = type => {
  if (!type) return '';

  const typeOption = typeOptions.find(typeOption => typeOption.value === type);

  if (!typeOption) return '';

  return typeOption.id;
};

const typeOptions = [
  {
    id: 1,
    name: 'Barn',
    value: 'barn'
  },
  {
    id: 2,
    name: 'Lot',
    value: 'lot'
  },
  {
    id: 3,
    name: 'Venue',
    value: 'venue'
  },
  {
    id: 4,
    name: 'General',
    value: 'general'
  }
];

export const MapFormFields = ({ isEdit, setHasMapErrors }) => {
  const { setFieldValue, values } = useFormikContext();
  const [venuesList, setVenuesList] = useState(null);
  const [mapErrors, setMapErrors] = useState([]);
  const [mapWarnings, setMapWarnings] = useState([]);
  const [isReplacingFile, setIsReplacingFile] = useState(false);

  const getValueFromUrl = venueId => {
    if (venueId && venuesList) {
      const venue = venuesList.find(venue => venue.id === venueId);
      if (!venue) return '';
      if (values.venueId !== venue.id) {
        setFieldValue('venueId', venue.id);
      }
      return venue.id;
    }

    return '';
  };

  const queryParams = new URLSearchParams(window.location.search);
  const venueIdParam = queryParams.get('venueId');

  const [selectedVenueId, setSelectedVenueId] = useState(values.venueId || venueIdParam || '');
  const [selectedType, setSelectedType] = useState(getValueFromType(values.mapType));
  const [selectedBuildings, setSelectedBuildings] = useState(values.buildings);

  const { data: venuesData, error: venuesError, loading } = useQuery(VENUES);

  useEffect(() => {
    if (venuesData) setVenuesList(venuesData.venues);
  }, [venuesData]);

  const getBuildingsForVenue = (venueId, mapType) => {
    if (venueId && venuesList) {
      const venue = venuesList.find(venue => venue.id === venueId);
      if (!venue) return [];

      if (mapType === 'barn') {
        return venue.buildings;
      } else {
        return venue.rvLots;
      }
    }

    return [];
  };

  const handleTypeChange = e => {
    const type = typeOptions.filter(type => type.id === e.target.value)[0];
    setFieldValue('mapType', type.value);
    setSelectedType(type.value);
  };

  const handleVenueChange = e => {
    const venue = venuesList.filter(venue => venue.id === e.target.value)[0];
    setFieldValue('venueId', venue?.id || '');
    setSelectedVenueId(venue?.id || '');
  };

  const handleBuildingChange = e => {
    setFieldValue('buildings', e?.target?.value || []);
    setSelectedBuildings(e?.target?.value);
  };

  const handleFileSelect = async ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    setMapErrors([]);
    setMapWarnings([]);

    const [errors, warnings] = await validateSVGMap(file, {
      venueId: selectedVenueId,
      type: selectedType,
      buildings: selectedBuildings
    });

    if (validity?.valid && errors.length === 0) {
      setFieldValue('file', file);
      setIsReplacingFile(true);
      setMapWarnings(warnings);
      setHasMapErrors(false);
    } else {
      if (errors.length > 0) setMapErrors(errors);
      if (warnings.length > 0) setMapWarnings(warnings);
      setHasMapErrors(true);
    }
  };

  return (
    <>
      <MapCard title={'Basic Information'} testId="basic-information">
        <div className={'card-row'}>
          <div className={'card-col'}>
            <HeadingFour label="Name" className="" />
            <Field
              component={FormikField}
              value={values.mapName}
              label="Map Name"
              name="mapName"
              type="text"
              variant="filled"
              onChange={e => {
                setFieldValue('mapName', e.target.value);
              }}
              data-testid="map-name-input"
            />
          </div>
          <div className={'card-col'}>
            <HeadingFour label="Description" className="" />
            <Field
              component={FormikField}
              value={values.mapDescription}
              label="Map Description"
              name="mapDescription"
              type="text"
              variant="filled"
              onChange={e => {
                setFieldValue('mapDescription', e.target.value);
              }}
              data-testid="map-description-input"
            />
          </div>
        </div>
        <div className={'card-row'}>
          <div className={'card-col'}>
            <HeadingFour label="Map Type" className="" />
            <Field label="Mat Type" name="mapType">
              {({ meta, ...props }) => (
                <Select
                  onChange={handleTypeChange}
                  value={getValueFromType(values.mapType)}
                  {...props}
                  meta={meta}
                  data-testid="map-type-input"
                  error={meta.touched && meta.error ? meta.error : null}
                  disabled={false}>
                  {getOptions(typeOptions)}
                </Select>
              )}
            </Field>
          </div>
          <div className={'card-col'}>
            <HeadingFour label="Venue" className="" />
            {!loading && !venuesError && (
              <Field label="Venue" name="venueId">
                {({ meta, ...props }) => (
                  <Select
                    onChange={handleVenueChange}
                    value={getValueFromUrl(values.venueId || venueIdParam)}
                    {...props}
                    meta={meta}
                    data-testid="map-venue-input"
                    error={meta.touched && meta.error ? meta.error : null}
                    disabled={!!(venueIdParam || isEdit)}>
                    {getOptions(venuesList)}
                  </Select>
                )}
              </Field>
            )}
            {loading && <div>Loading venues...</div>}
            {venuesError && <div>Ups...there was an error retrieving the venues</div>}
          </div>
          <div className={'card-col'}>
            <HeadingFour label="Building(s)" className="" />
            {!loading && !venuesError && (
              <Field label="Buildings" name="buildings">
                {({ meta, ...props }) => (
                  <Select
                    onChange={handleBuildingChange}
                    value={values.buildings}
                    {...props}
                    meta={meta}
                    multiple
                    data-testid="map-buildings-input"
                    error={meta.touched && meta.error ? meta.error : null}>
                    {getOptions(getBuildingsForVenue(venueIdParam || values.venueId, values.mapType))}
                  </Select>
                )}
              </Field>
            )}
            {loading && <div>Loading venues...</div>}
            {venuesError && <div>Ups...there was an error retrieving the venues</div>}
          </div>
        </div>
      </MapCard>
      <MapCard title={'SVG'} testId="svg-information">
        <div className={'card-row'}>
          <div className={'card-col'} style={{ width: '100%' }}>
            <HeadingFour label="File" className="" />
            {isEdit && !isReplacingFile && <i>It won't replace the current map if any file was selected.</i>}
            {isEdit && isReplacingFile && <i>It will replace the current map.</i>}
            {isEdit && values.file && !isReplacingFile && (
              <p style={{ marginTop: '10px' }}>
                <span>Curent map: </span>
                <span>
                  <a target="_blank" href={values.file?.name}>
                    {values.file?.name}
                  </a>
                </span>
              </p>
            )}
            <input
              style={{ display: 'block', marginTop: '10px' }}
              data-testid="map-file-input"
              type="file"
              id="upload-file"
              accept=".svg"
              required
              onChange={handleFileSelect}
            />
          </div>
        </div>
        <div className={'card-row'}>
          {mapErrors.length > 0 && (
            <MapErrorsStyled>
              <ul>
                {mapErrors.map((e, i) => (
                  <li key={`error-${i}`}>{e}</li>
                ))}
              </ul>
            </MapErrorsStyled>
          )}
        </div>
        <div className={'card-row'}>
          <div className={'card-col'} style={{ width: '100%' }}>
            {mapWarnings.length > 0 && (
              <MapWarningsStyled>
                <ul>
                  {mapWarnings.map((w, i) => (
                    <li key={`warning-${i}`}>{w}</li>
                  ))}
                </ul>
              </MapWarningsStyled>
            )}
          </div>
        </div>
      </MapCard>
    </>
  );
};

const MapErrorsStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #b55b5b;
  width: 100%;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
`;

const MapWarningsStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ce9f34;
  width: 100%;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
`;
