import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { isMobile } from 'react-device-detect';
import Calendar from '../../components/Calendar/Calendar';
import moment from 'moment';
import { UserContext } from 'Store/UserContext';
import { INTERACTIVE_MAPS_FLAG } from 'Queries/Maps/getVenueInteractiveMapsFlag';
import { GET_MAPS_BY_VENUE_ID } from 'Queries/Maps/getMapsByVenueId';
import { UPCOMING_VENUE_EVENTS } from 'Queries/Admin/UpcomingVenueEvents';
import { HeadingTwo } from 'Components/Headings';
import ContextSnackbar from '../../components/Snackbar';
import { FormSelect } from '../../components/Select';
import FormCard from '../../components/Cards/FormCard';
import MapsViewer from '../../pages/Admin/Maps';
import OperationsTable from '../Tables/Ops/OperationsTable';
import RVsTable from '../../containers/Tables/Ops/RVsTable';

const getMapName = (mapId, list) => {
  if (!mapId || list.lenght === 0) return '';
  return list.find(map => map.id === mapId)?.label || '';
};

const AdminMapsNavigation = ({ klass, type }) => {
  const {
    user: { venues = [] }
  } = useContext(UserContext);

  const [firstVenue = {}] = venues;
  const { id: venueId } = firstVenue;

  const [selectedEvent, setEvent] = useState(null);
  const [selectedEventInfo, setEventInfo] = useState(null);
  const [selectedMap, setMap] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const { loading, error, data } = useQuery(INTERACTIVE_MAPS_FLAG, {
    variables: {
      id: venueId
    }
  });

  const [getCurrentVenueEvents, { data: currentEvents, error: currentEventsError, loading: currentEventsLoading }] = useLazyQuery(UPCOMING_VENUE_EVENTS, {
    variables: {
      filterBy: {
        endDate: moment()
          .format('YYYY-MM-DD')
          .toString()
      }
    },
    fetchPolicy: 'network-only'
  });

  const [getMaps, { data: mapsData, error: mapsQueryError, loading: mapsQueryLoading }] = useLazyQuery(GET_MAPS_BY_VENUE_ID, {
    variables: { venueId: venueId },
    fetchPolicy: 'network-only'
  });

  const hasMapsForType = useCallback(
    type => {
      if (!mapsData) {
        return false;
      }

      const mapType = type === 'stall' ? 'barn' : 'lot';

      return mapsData.maps.some(map => map.type === mapType);
    },
    [mapsData]
  );

  useEffect(() => {
    if (!loading && !error && data?.venue?.interactiveMaps) {
      getCurrentVenueEvents();
      getMaps();
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data?.venue?.interactiveMaps &&
      currentEvents &&
      !currentEventsError &&
      !currentEventsLoading &&
      mapsData &&
      !mapsQueryError &&
      !mapsQueryLoading
    ) {
      setDataLoaded(true);
    }
  }, [loading, error, data, currentEvents, currentEventsError, currentEventsLoading, mapsData, mapsQueryError, mapsQueryLoading]);

  useEffect(() => {
    if (selectedEvent && currentEvents) {
      const { venue } = currentEvents;
      const event = venue?.events?.find(e => e.id === selectedEvent);
      if (event) {
        setEventInfo(event);
      }
    }
  }, [selectedEvent]);

  if (loading || mapsQueryLoading || currentEventsLoading) {
    return 'Loading...';
  }

  if (error || currentEventsError || mapsQueryError) {
    return 'Oops, there was an error';
  }

  if (dataLoaded && hasMapsForType(type) && !isMobile) {
    let availableEventLabels = [];
    const { venue } = currentEvents;
    availableEventLabels = venue.events.map(event => {
      return { value: event.id, label: event.name };
    });

    let mapsLabels = [];
    mapsLabels = mapsData.maps.map(map => {
      return { value: map.id, label: map.name, description: map.description, type: map.type };
    });

    const availableEventList = {
      cb: e => setEvent(e.target.value),
      disabled: !selectedMap,
      label: 'EVENT',
      options: availableEventLabels,
      selectedOption: selectedEvent ? selectedEvent : '',
      type: 'select',
      value: selectedEvent,
      emptyListMessage: 'No current events'
    };

    const mapsList = {
      cb: e => setMap(e.target.value),
      disabled: false,
      label: type === 'stall' ? 'BARN' : 'Lot',
      options: mapsLabels.filter(map => map.type === `${type === 'stall' ? 'barn' : 'lot'}`),
      selectedOption: selectedMap ? selectedMap : '',
      type: 'select',
      value: getMapName(selectedMap, mapsLabels),
      emptyListMessage: 'No current maps'
    };

    return (
      <>
        <ContextSnackbar />
        <div data-testid="map-admin-view">
          <FormCard className={`${klass} card-item`}>
            <div className="heading">
              <HeadingTwo label={`${type === 'stall' ? 'STALL' : 'RV'} MAPS`} />
            </div>
            <SelectsWrapper>
              <Select data-testid="map-select-map" className="select-maps" {...mapsList} key={mapsList.label} />
              <Select data-testid="map-select-event" className="select-events" {...availableEventList} key={availableEventList.label} />
              <Calendar
                disabled={!selectedEventInfo}
                selectableRangeStart={selectedEventInfo?.startDate}
                selectableRangeEnd={selectedEventInfo?.endDate}
                cb={e => {
                  if (e?.startDate) {
                    setSelectedStartDate(e.startDate.format('YYYY-MM-DD'));
                  } else {
                    setSelectedStartDate(moment(selectedEventInfo?.startDate).format('YYYY-MM-DD'));
                  }

                  if (e?.endDate) {
                    setSelectedEndDate(e.endDate.format('YYYY-MM-DD'));
                  } else {
                    setSelectedEndDate(moment(selectedEventInfo?.endDate).format('YYYY-MM-DD'));
                  }
                }}
                styles={{
                  colorBgContainer: '#f1f4f7',
                  colorBorder: 'transparent'
                }}
              />
            </SelectsWrapper>
          </FormCard>
          <MapsViewer
            mapId={selectedMap}
            startDate={selectedEventInfo ? selectedEventInfo.startDate : null}
            endDate={selectedEventInfo ? selectedEventInfo.endDate : null}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            eventId={selectedEvent}
            type={type}
          />
        </div>
      </>
    );
  }

  if (type === 'rv') {
    return <RVsTable />;
  }

  return <OperationsTable />;
};

const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .calendar-picker {
    width: 33%;
    height: 56px;
  }
`;

const Select = styled(FormSelect)`
  width: 33%;
  &&& {
    .MuiInputBase-root::before {
      border-bottom: 1px solid #576574;
    }

    .MuiInputBase-root {
      height: 57px;
      background: #f1f4f7;
      margin: 0;
    }

    .MuiInputBase-root.Mui-disabled {
      opacity: 0.5;
    }
  }
`;

export default AdminMapsNavigation;
