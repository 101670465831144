import Cookies from 'universal-cookie';

class Auth {
  cookies;
  constructor() {
    this.cookies = new Cookies();
  }

  // read cookies
  getToken = () => {
    return this.cookies.get('rolo-jwt', { path: '/' });
  };
  // write to cookies
  setToken = token => {
    /** @todo maxAge should be from env */
    return this.cookies.set('rolo-jwt', token, { maxAge: 100000, path: '/' });
  };

  // clear cookies
  clearToken = () => {
    return this.cookies.remove('rolo-jwt', { path: '/' });
  };
}

export default new Auth();
