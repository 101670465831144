import React from 'react';

import FormCard from '../../../components/Cards/FormCard';
import { HeadingTwo } from 'Components/Headings';

export const MapCard = ({ title, children, className, disabled, testId }) => (
  <FormCard className={`${className} card-item ${disabled ? 'disabled' : ''}`} dataTestId={testId}>
    {!!title && (
      <div className={'heading'}>
        <HeadingTwo label={title} className="" />
      </div>
    )}
    {children}
  </FormCard>
);
