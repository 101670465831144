import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import AdminOrderContainer from '../../containers/Order/Admin';
import { HeadingOne } from 'Components/Headings';

const AdminCreateOrderBase = () => {
  return (
    <AdminCreateOrder isMobile={isMobile}>
      <div className="header">
        <HeadingOne label="CREATE RESERVATION" className="" />
      </div>
      <AdminOrderContainer reservationEditable={false} />
    </AdminCreateOrder>
  );
};

const AdminCreateOrder = styled.div`
  margin: 85px 50px 50px;

  .header {
    margin: 0 0 20px;
    text-align: left;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin: 70px 0 20px;
      text-align: center;
    `}
  }

  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 50px 16px;
    `}
`;

export default AdminCreateOrderBase;
