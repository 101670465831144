import React, { useEffect, useState, useLayoutEffect } from 'react';
import { isEqual } from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import usePreviousValue from 'Hooks/usePrevious';
var _remove = require('lodash.remove');
import './mapLoader.scss';

const MapLoader = ({
  readMode,
  availableSpots = [],
  disabledIds = [],
  selectedSpots = [],
  url,
  mapType,
  width,
  height,
  maxSelection = 100,
  onSelect,
  onDeselect,
  onAvailable
}) => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const previousAvailableSpots = usePreviousValue(availableSpots);
  const previousSelectedSpots = usePreviousValue(selectedSpots);

  // spots changed
  useEffect(() => {
    if (!isEqual(previousAvailableSpots, availableSpots)) {
      setFirstRender(true);
    }
  }, [availableSpots, previousAvailableSpots]);

  // map url changed
  useEffect(() => {
    fetch(url)
      .then(res => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => {
        setIsLoaded(true);
        setFirstRender(true);
      });
  }, [url]);

  useEffect(() => {
    if (selectedSpots?.length !== previousSelectedSpots?.length && selectedSpots?.length === 0) {
      setSelectedIds([]);
    }
  }, [selectedSpots, previousSelectedSpots]);

  // actions on map init
  useLayoutEffect(() => {
    if (document && document.getElementById(`map_svg_${mapType}`) && firstRender) {
      lockSpotsOnInit();
      disableSpotsOnInit();
      selectSpotsOnInit();
    }
  });

  const handleClick = e => {
    const id = e.target.getAttribute('id');

    if (!id || (e.target.classList.contains('locked') && !readMode) || e.target.classList.contains('disabled')) return;

    const parsedId = id.split('_');
    const type = parsedId[0];

    if (e.target.classList.contains('locked') && readMode) {
      onSelect && onSelect(id, [...selectedIds, id]);
    } else if (readMode && !e.target.classList.contains('locked')) {
      onAvailable && onAvailable();
    }

    if (!readMode && (type === 'stall' || type === 'rv')) {
      if (e.target.classList.contains('selected')) {
        e.target.classList.remove('selected');
        const ids = _remove(selectedIds, x => {
          return x !== id;
        });
        setSelectedIds(ids);
        onDeselect && onDeselect(id, ids);
      } else {
        if (+maxSelection === selectedIds.length) return;

        e.target.classList.add('selected');
        setSelectedIds([...selectedIds, id]);
        onSelect && onSelect(id, [...selectedIds, id]);
      }
    }
  };

  const lockSpotsOnInit = () => {
    const spots = Array.from(document.getElementById(`map_svg_${mapType}`).querySelector('#interactive')?.children);
    if (spots) {
      spots.forEach(spot => {
        spot.classList.remove('locked');

        if (!availableSpots.includes(spot.id.split('_')[1]) && !spot.classList.contains('selected')) {
          spot.classList.add('locked');
        }
      });
    }
    setFirstRender(false);
  };

  const disableSpotsOnInit = () => {
    if (disabledIds && disabledIds.length > 0) {
      disabledIds.forEach(item => {
        if (document.getElementById(item)) {
          document.getElementById(item).classList.add('disabled');
        }
      });
    }
    setFirstRender(false);
  };

  const selectSpotsOnInit = () => {
    if (selectedSpots && selectedSpots.length > 0 && mapType) {
      selectedSpots.forEach(item => {
        const el = document.getElementById(`${mapType === 'stall' ? 'stall' : 'rv'}_${item.id}`);

        if (el && !el.classList.contains('selected')) {
          el.classList.remove('locked');
          el.classList.add('selected');
        }
      });

      const htmlSpotsIds = selectedSpots.map(spot => `${mapType === 'stall' ? 'stall' : 'rv'}_${spot.id}`);
      setSelectedIds(htmlSpotsIds);
    }
    setFirstRender(false);
  };

  return (
    <div
      style={{ width: width ? width : '768px', height: height ? height : '768px' }}
      className={`mapLoader mapLoader--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'mapLoader--errored' : ''}`}>
      {isLoaded && svg && (
        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
          {({ zoomIn, zoomOut }) => (
            <React.Fragment>
              <div className="tools">
                <button onClick={() => zoomIn()}>+</button>
                <button onClick={() => zoomOut()}>-</button>
              </div>
              <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
                <div
                  id="svg-map"
                  data-testid="map-svg-container"
                  className="svg-map arena-map visible"
                  dangerouslySetInnerHTML={{ __html: svg }}
                  onClick={handleClick}></div>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      )}
    </div>
  );
};

export default MapLoader;
