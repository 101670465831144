import React, { useCallback, useEffect, useMemo, useContext, useRef, useState } from 'react';
import { capitalize, IconButton } from '@material-ui/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getValueByPropPath } from 'Utils/objectHelpers';
import Button from '../../../../components/Button';
import { ORDER_FOR_ORDER_TABLE_DETAIL } from 'Queries/Admin/OrderForOrderTableDetail';
import { formatPhoneNumber } from 'Helpers';
import { getOrderItems, parseDateRange } from './ViewOrderSidePanelHelpers';
import { ClosePanelButton as CloseButton } from './ClosePanelButton';
import { SidePanelRow as Row } from './SidePanelRow';
import { SidePanelCol as Columns } from './SidePanelCol';
import { SidePanelAddOns as AddOns } from './SidePanelAddOns';
import { SidePanelForm as Form } from './SidePanelForm';
import Layout from './SidePanelLayout';
import { SidePanelSelect as Select } from './SidePanelSelect';
import { ViewOrderSidePanelLoading as Loading } from './ViewOrderSidePanelLoading';
import { SidePanelStalls as Stalls } from './SidePanelStalls';
import { SidePanelRvs as Rvs } from './SidePanelRvs';
import UPDATE_ISVISITED from '../../../../mutations/UpdateIsVisited';
import { useValidateAction } from 'Utils/actions';
import { actions } from 'Constants/actions';
import { PRODUCTS_TYPES } from 'Constants/productType';
import { UserContext } from 'Store/UserContext';
import { GROUP_LEADER } from 'Constants/userRoles';
import { ORDER_FOR_PRINT_RECEIPT } from 'Queries/Admin/OrderForPrintReceipt';
import PrintReceipt from 'Components/PrintReceipt';
import PrintIcon from '../../../../assets/img/icons/Printer.svg';
import AddOnStatusSelect from './AddOnStatusSelect';

const EDIT_ORDER_LINK_MAP = (role, orderId) => `/${role}/order/edit/${orderId}`;

export const ViewOrderSidePanelComponent = props => {
  const { orderID, onClose } = props;
  const { push } = useHistory();
  const componentRef = useRef();
  const [isReadyForPrint, setIsReadyForPrint] = useState(false);

  const [getOrderByIdCallback, { called, data, loading, refetch }] = useLazyQuery(ORDER_FOR_ORDER_TABLE_DETAIL, {
    fetchPolicy: 'network-only'
  });

  const { data: dataForPrint } = useQuery(ORDER_FOR_PRINT_RECEIPT, {
    variables: { id: orderID },
    skip: !orderID,
    fetchPolicy: 'network-only'
  });

  const order = data && data.order ? data.order : {};
  const { user = {}, event = {}, orderItems = [], notes } = order;
  const { stallsOrder, rvsOrder, addOnOrderItems } = useMemo(() => getOrderItems(orderItems), [JSON.stringify(orderItems)]);
  const currentOrderAddOns = addOnOrderItems.filter(item => item.quantity > 0);
  const canSeeStallStatus = useValidateAction(PRODUCTS_TYPES.ORDERS, actions.STALL_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR);
  const canSeeRVStatus = useValidateAction(PRODUCTS_TYPES.ORDERS, actions.RV_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR);
  const hasRvs = Boolean(rvsOrder);
  const hasStalls = Boolean(stallsOrder);
  const hasAddOns = addOnOrderItems?.length > 0;

  const { user: userContext } = useContext(UserContext);

  const role = +userContext.role.id === GROUP_LEADER ? 'group-leader' : 'admin';

  const [setIsVisited] = useMutation(UPDATE_ISVISITED, {
    variables: { id: orderID }
  });

  const loadOrder = useCallback(() => {
    if (orderID) {
      getOrderByIdCallback({ variables: { id: orderID } });
    }
  }, [orderID]);

  useEffect(() => {
    if (data && data.order && !data.order.isVisited) {
      setIsVisited();
      refetch();
    }
  }, [data]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  useEffect(() => {
    if (dataForPrint && dataForPrint.order) {
      setIsReadyForPrint(true);
    } else {
      setIsReadyForPrint(false);
    }
  }, [dataForPrint]);

  const getAddOnOnlyOrderDetails = () => {
    if (!hasStalls && !hasRvs && hasAddOns) {
      return (
        <>
          <AddOnStatusSelect disabled={!!order.canceled} orders={props.orders} orderID={orderID} />
          <div className="order-details">
            <div className={`order-col`} style={{ marginRight: 0 }}>
              <div className={'heading'}>DELIVERY INFORMATION</div>
              <div>{order?.addOnDeliveryNotes ?? '-'}</div>
            </div>
          </div>
        </>
      );
    }
  };

  if (!orderID) return null;

  if (loading || !called) return <Loading />;
  return (
    <Form
      hasRvs={hasRvs}
      hasStalls={hasStalls}
      key={orderID}
      order={order}
      addOnStatusId={order.addOnStatusId || ''}
      rvStatusId={getValueByPropPath(rvsOrder, 'reservation.status.id', '')}
      stallStatusId={getValueByPropPath(stallsOrder, 'reservation.status.id', '')}>
      {() => (
        <Layout>
          <Row className={'row-20 order-user-name'}>
            <div>{`${capitalize(user.firstName || '')} ${capitalize(user.lastName || '')}`}</div>
            <CloseButton onClose={onClose} />
          </Row>

          <Row className="phone-and-updated">
            <div className="user-phone">{formatPhoneNumber(user.phone)}</div>
            <p>Edited at {moment.unix(new Date(order.updatedAt) / 1000).format('MM/DD/YY[,]h:mm A')}</p>
            {isReadyForPrint && (
              <ReactToPrint
                trigger={() => (
                  <IconButton data-testid="print-receipt-icon" className="printer-icon">
                    <img src={PrintIcon} alt="printer icon" />
                  </IconButton>
                )}
                content={() => componentRef.current}
              />
            )}
          </Row>

          <Row className={'row-20'} title={'Event'} text={event.name} />
          {!!stallsOrder?.quantity && (
            <Row
              title={'Stalls'}
              text={parseDateRange(getValueByPropPath(stallsOrder, 'reservation.startDate', ''), getValueByPropPath(stallsOrder, 'reservation.endDate', ''))}>
              <Stalls order={stallsOrder}>{stallColumns => <Columns columns={stallColumns} />}</Stalls>
              {canSeeStallStatus && <Select name={'stallStatusId'} orderItemId={stallsOrder.id} disabled={order.canceled ? true : false} />}
            </Row>
          )}

          {!!rvsOrder?.quantity && (
            <Row
              title={'RV Spots'}
              text={parseDateRange(getValueByPropPath(rvsOrder, 'reservation.startDate', ''), getValueByPropPath(rvsOrder, 'reservation.endDate', ''))}>
              <Rvs order={rvsOrder}>{columns => <Columns columns={columns} />}</Rvs>
              {canSeeRVStatus && <Select name={'rvStatusId'} orderItemId={rvsOrder.id} disabled={order.canceled ? true : false} />}
            </Row>
          )}

          {currentOrderAddOns.length > 0 && (
            <Row title={'Add Ons'}>
              <AddOns addOnOrderItems={currentOrderAddOns}>{columns => <Columns columns={columns} />}</AddOns>
              {getAddOnOnlyOrderDetails()}
            </Row>
          )}

          {!!order.adminNotes && <Row title={'Admin notes'} text={order.adminNotes} />}

          {!!notes && <Row title={'Special Request'} text={notes} />}

          {order.isEditable && (
            <Row className={'row-edit-button'}>
              <Button type={'submit'} secondary onClick={() => push(EDIT_ORDER_LINK_MAP(role, orderID))}>
                Edit Reservation
              </Button>
            </Row>
          )}

          {isReadyForPrint && dataForPrint?.order && (
            <div style={{ display: 'none' }}>
              <PrintReceipt order={dataForPrint.order} ref={componentRef} />
            </div>
          )}
        </Layout>
      )}
    </Form>
  );
};
