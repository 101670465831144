import React, { useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { isMobile } from 'react-device-detect';

import { REPORT_CONFIG } from 'Queries/Admin/ReportConfig';
import EventReportPreview from '../../../assets/img/reports/EventReport.png';
import ReconciliationPreview from '../../../assets/img/reports/Reconciliation.png';
import StallReportPreview from '../../../assets/img/reports/StallReport.png';
import RVReportPreview from '../../../assets/img/reports/RVReport.png';
import ReservationReportPreview from '../../../assets/img/reports/ReservationReport.png';
import AccountingReportPreview from '../../../assets/img/reports/AccountingReport.png';
import TransactionReportPreview from '../../../assets/img/reports/TransactionReport.png';
import { HeadingThree } from 'Components/Headings';
import { Report, REPORTS_BY_EVENT, REPORTS_HEADER } from 'Constants/reportType';
import TransReportModal from './TransReportModal';
import EventAndStallReport from './EventAndStallReportModal';
import { UserContext } from 'Store/UserContext';
import { UserContextType } from 'Store/UserContextType';
import IndeterminateLoading from '../../../components/Loading/IndeterminateLoading';

const ReportsList: React.FC = () => {
  const [reportType, setReportType] = useState('');
  const { user } = useContext<UserContextType>(UserContext);
  const userVenueId = user?.venues && user.venues[0].id;

  const { data, loading } = useQuery(REPORT_CONFIG, {
    variables: {
      id: userVenueId
    }
  });

  if (loading) {
    return <IndeterminateLoading className="" />;
  }

  const RenderReport = () => {
    if (!reportType) return <></>;
    const header = REPORTS_HEADER[reportType];

    if (isFilteredByEvent(reportType)) {
      return <EventAndStallReport isOpen handleClose={handleCloseModal} onCancel={handleCloseModal} header={header} reportType={reportType} />;
    }
    return <TransReportModal isOpen handleClose={handleCloseModal} onCancel={handleCloseModal} header={header} reportType={reportType} />;
  };

  const isFilteredByEvent = (reportType: string) => REPORTS_BY_EVENT.includes(reportType);

  const handleCloseModal = () => {
    setReportType('');
  };

  const financialReports = [
    {
      name: 'Transaction Report',
      img: TransactionReportPreview,
      testid: 'transaction-report',
      onClick: () => setReportType(Report.TRANSACTION)
    },
    {
      name: 'Accounting Report',
      img: AccountingReportPreview,
      testid: 'accounting-report',
      onClick: () => setReportType(Report.ACCOUNTING),
      hidden: !data?.venue?.venueConfig?.accountingReport
    },
    {
      name: 'Event Report',
      img: EventReportPreview,
      testid: 'event-report',
      onClick: () => setReportType(Report.EVENT)
    },
    {
      name: 'Reconciliation Report',
      img: ReconciliationPreview,
      testid: 'reconciliation-report',
      onClick: () => setReportType(Report.RECON),
      hidden: !data?.venue?.venueConfig?.reconReport
    }
  ];
  const operationsReports = [
    {
      name: 'Stall & Add On Report',
      img: StallReportPreview,
      testid: 'stall-report',
      onClick: () => setReportType(Report.STALL)
    },
    {
      name: 'RV Report',
      img: RVReportPreview,
      testid: 'rv-report',
      onClick: () => setReportType(Report.RV)
    },
    {
      name: 'Reservation Report',
      img: ReservationReportPreview,
      testid: 'reservation-report',
      onClick: () => setReportType(Report.RESERVATION)
    }
  ];

  return (
    <div className={`reports-list-container ${isMobile ? 'mobile' : ''}`}>
      <HeadingThree label="Financial Reports" />
      <div className="financial-reports">
        {financialReports.map(report => {
          const { onClick, name, testid, img, hidden } = report;

          if (hidden) {
            return <></>;
          }
          return (
            <div key={report.name} className="reports" onClick={onClick}>
              <img src={img} alt={name} width="164px" height="208px" data-testid={testid} />
              <p>{name}</p>
              <span>Financial Reports</span>
            </div>
          );
        })}
        <RenderReport />
      </div>
      <HeadingThree label="Operations Reports" />
      <div className="operations-reports">
        {operationsReports.map(report => {
          const { onClick, name, testid, img } = report;
          return (
            <div key={report.name} className="reports" onClick={onClick}>
              <img src={img} alt={name} width="164px" height="208px" data-testid={testid} />
              <p>{name}</p>
              <span>Operations Reports</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportsList;
