const addOnStatus = {
  NEW_ORDER: {
    id: 1,
    name: 'New Order'
  },
  CONFIRMED: {
    id: 2,
    name: 'Confirmed'
  },
  DELIVERED: {
    id: 3,
    name: 'Delivered'
  }
};

export default addOnStatus;
