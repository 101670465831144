import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Select, MenuItem } from '@material-ui/core';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { headingOne } from '../../../styles/Typography';
import { displayFlex } from '../../../styles/Mixins';
import AddButton from '../../../components/Button/AddButton';
import Snackbar from '../../../components/Snackbar';
import { withSnackbarContextActions } from 'Store/SnackbarContext';
import { VENUES } from 'Queries/SuperAdmin';
import { GET_MAPS_BY_VENUE_ID } from 'Queries/Maps/getMapsByVenueId';
import { HeadingOne } from 'Components/Headings';

const getOptions = data => {
  if (!data) return null;

  const options = data.map(data => (
    <MenuItem key={data.id} value={data.id}>
      ID: {data.id} - {data.name}
    </MenuItem>
  ));

  return options;
};

export const MapsBase = ({ className, showSnackbar }) => {
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [venuesList, setVenuesList] = useState(null);

  const { data: venuesData, error: venuesError, loading } = useQuery(VENUES);

  const [getMaps, { data: mapsData, error: mapsQueryError, loading: mapsQueryLoading }] = useLazyQuery(GET_MAPS_BY_VENUE_ID, {
    variables: { venueId: selectedVenue?.id },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (venuesData) setVenuesList(venuesData.venues);
  }, [venuesData]);

  useEffect(() => {
    if (selectedVenue?.id) getMaps();
  }, [selectedVenue]);

  const handleVenueChange = e => {
    const venue = venuesList.filter(venue => venue.id === e.target.value)[0];
    setSelectedVenue(venue);
  };

  return (
    <section className={className}>
      <FlexWrapper>
        <HeadingOne label="MAPS" className="" />
        <CreateMapLink to={`${SUB_ROUTES.SUPER_ADMIN.MAPS_CREATE}${selectedVenue?.id ? `?venueId=${selectedVenue?.id}` : ''}`}>
          <AddButton label={'CREATE MAP'} />
        </CreateMapLink>
      </FlexWrapper>
      <div className="venue-selection">
        <h3>Choose a venue</h3>
        {venuesData && !loading && !venuesError && (
          <Select data-testid="map-select-venue" onChange={handleVenueChange} value={selectedVenue?.id || ''} disableUnderline>
            {getOptions(venuesList)}
          </Select>
        )}
        {loading && <div>Loading...</div>}
        {venuesError && <div>Ups... there was an error loading the venues</div>}
      </div>
      {mapsData && (
        <div className="maps-container">
          {mapsData.maps.length > 0 && (
            <div className="maps-list" data-testid="map-list">
              {mapsData.maps.map(map => (
                <div key={map.id} className="map-box">
                  <h4>
                    <strong>{map.name}</strong>
                  </h4>
                  <div>
                    <em>{map.description}</em>
                  </div>
                  <div>
                    <strong>type:</strong> {map.type}
                  </div>
                  <EditMapLink to={`${SUB_ROUTES.SUPER_ADMIN.MAPS_EDIT}?mapId=${map.id}`}>
                    <button type="prymary">Edit</button>
                  </EditMapLink>
                </div>
              ))}
            </div>
          )}
          {mapsData.maps.length === 0 && <div>Create a map</div>}
        </div>
      )}
      {mapsQueryLoading && <div>Loading maps...</div>}
      {mapsQueryError && <div>Ups...There was an error loading the maps</div>}
      {showSnackbar && <Snackbar />}
    </section>
  );
};

const SuperAdminMaps = styled(MapsBase)`
  margin: 85px 50px 50px;
  max-width: 1800px;
  min-width: 1130px;
  &__Header {
    ${headingOne}
    text-align: left;
    margin: 0;
  }

  .venue-selection {
    text-align: left;
  }

  .maps-list {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow: scroll;
  }

  .map-box {
    padding: 20px;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 10px 0;
    text-align: left;
    max-width: 400px;
    position: relative;

    h4 {
      margin: 0;
      margin-bottom: 10px;
      font-size: 18px;
    }

    button {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      background-color: #f2f4f7;
      outline: none;
      border: 0;
      padding: 10px;
      font-weight: 600;
    }
  }
`;

const FlexWrapper = styled.div`
  ${displayFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CreateMapLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

const EditMapLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export default compose(withRouter, withSnackbarContextActions)(memo(SuperAdminMaps));
