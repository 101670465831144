import Cookies from 'universal-cookie';

class CustomAuth {
  cookies;
  constructor() {
    this.cookies = new Cookies();
  }

  // read cookies
  getToken = cookieName => {
    return this.cookies.get(cookieName, { path: '/' });
  };
  // write to cookies
  setToken = (cookieName, token) => {
    /** @todo maxAge should be from env */
    return this.cookies.set(cookieName, token, { maxAge: 100000, path: '/' });
  };

  // clear cookies
  clearToken = cookieName => {
    return this.cookies.remove(cookieName, { path: '/' });
  };
}

export default new CustomAuth();
