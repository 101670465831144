import React from 'react';
import styled from 'styled-components';

import { EventCard } from '../Cards/EventCard';

const ReviewAddOns = ({ formikValues, initialValues }) => {
  const { addOns = [], requiredAddOns = [] } = formikValues;
  const isEditing = !!initialValues;
  const initialAddOns = initialValues?.addOns ?? [];

  addOns.map(addOn => {
    let priceWasEdited = false;
    let disableWasEdited = false;
    const isNewAddOn = !initialAddOns.some(initialAddOn => initialAddOn.id === addOn.id);

    if (!isNewAddOn) {
      const initialAddOn = initialAddOns.find(initialAddOn => initialAddOn.id === addOn.id);
      priceWasEdited = initialAddOn.price !== +addOn.price;
      disableWasEdited = initialAddOn.disabled !== addOn.disabled;
    }

    addOn.toggleLabel = addOn.disabled ? 'disabled' : 'enabled';
    addOn.highlight = (isNewAddOn || priceWasEdited || disableWasEdited) && isEditing;
  });

  return (
    <EventCard title="Add Ons" testId="review-addons">
      <div className="info-row">
        {addOns.map(addOn => (
          <div className="info-item" key={addOn.id}>
            <h3 className="capitalize">{addOn.name}</h3>
            <div className={`info ${addOn.highlight ? 'highlighted' : ''}`}>{`$${addOn.price} / ${addOn.unitName} / ${addOn.toggleLabel}`}</div>
          </div>
        ))}
      </div>
      {requiredAddOns.length > 0 && (
        <div>
          <h3>Required:</h3>
          <RequiredAddOnsListStyled>
            {requiredAddOns.map(ra => (
              <div>
                - For {ra.xRefTypeId === 1 ? 'Stalls' : 'RVs'}, require {ra.ratio} {addOns.find(a => +a.id === +ra.addOnProduct)?.name} per{' '}
                {ra.condition === 'NIGHT' ? 'night' : 'unit'}
              </div>
            ))}
          </RequiredAddOnsListStyled>
        </div>
      )}
    </EventCard>
  );
};

const RequiredAddOnsListStyled = styled.div`
  display: block;
  margin-top: 10px;
`;

export default ReviewAddOns;
