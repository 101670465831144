import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { HeadingFour } from 'Components/Headings';
import CheckboxThemed from '../Checkbox';
import { BlueTooltip } from 'src/containers/Order/Admin/Edit/orderHistoryHelpers';
import { Notice } from '../../../../components/Alerts';
import { getSelectedBuildingsId } from './stallsHelper';
import { buildingIsSelectedInOtherRate, excludeCurrentProduct } from './flipHelper';

const StallFlipping = ({ index, isDisabled, stallsPerBuilding, buildingsWithFlipping, stallsHash, selectedBuildingsOverlapping }) => {
  const { setFieldValue, values, initialValues } = useFormikContext();
  const flippingDisabled = values.stalls[index].flippingDisabled;
  const currentProductId = values.stalls[index].id;
  const selectedStalls = values.stalls[index].stallsForThisRate;
  const [buildingsWithMismatchFlipAreChecked, setBuildingsWithMismatchFlipAreChecked] = useState(true);
  const [warning, setWarning] = useState('');

  const getBuildingsWithMismatchFlip = () => {
    const selectedBuildingsId = getSelectedBuildingsId(stallsHash, selectedStalls);
    const buildingsWithMismatchFlip = [];

    for (const buildingId of selectedBuildingsId) {
      const wasAdded = addBuildingToListIfMismatchesDBBuilding(buildingId, buildingsWithMismatchFlip);
      if (!wasAdded) {
        addBuildingToListIfMismatchesOverlappingBuilding(buildingId, buildingsWithMismatchFlip);
      }
    }

    return buildingsWithMismatchFlip;
  };

  const addBuildingToListIfMismatchesDBBuilding = (buildingId, buildingsWithMismatchFlip) => {
    const building = buildingsWithFlipping.find(b => b.id === buildingId);

    if (!building) {
      return;
    }
    if (flipMismatches(building)) {
      addToList(buildingsWithMismatchFlip, buildingId);
      return true;
    }
  };

  const flipMismatches = building => {
    const stallProducts = excludeCurrentProduct(building, currentProductId);
    return stallProducts.length > 0 && stallProducts[0].flip === !flippingDisabled;
  };

  const addToList = (buildingsWithMismatchFlip, buildingId) => {
    const buildingName = stallsPerBuilding.find(b => b.id === buildingId);
    buildingsWithMismatchFlip.push(buildingName);
  };

  const addBuildingToListIfMismatchesOverlappingBuilding = (buildingId, buildingsWithMismatchFlip) => {
    const building = selectedBuildingsOverlapping[buildingId];

    if (!building || !buildingIsSelectedInOtherRate(building, index)) {
      return;
    }
    if (flipMismatchesOverlappingBuilding(building)) {
      addToList(buildingsWithMismatchFlip, buildingId);
    }
  };

  const flipMismatchesOverlappingBuilding = building => {
    const { flip } = building;
    return flip === !flippingDisabled;
  };

  const getWarning = buildingsWithMismatchFlip => {
    const invalidBuildings = buildingsWithMismatchFlip.map(b => b.name).join(', ');
    const enableDisabled = flippingDisabled ? 'enable' : 'disable';
    return `To ${enableDisabled} flipping please unselect these barns: ${invalidBuildings}`;
  };

  const hasValues = stallsPerBuilding => {
    return stallsPerBuilding && stallsPerBuilding.length > 0;
  };

  const isBookedWithFlippingEnabled = () => {
    const isBooked = values.stalls[index].booked;
    const initialStallProduct = initialValues?.stalls?.find(s => s.id === currentProductId);
    const hasFlippingEnabled = initialStallProduct && !initialStallProduct.flippingDisabled;

    return isBooked && hasFlippingEnabled;
  };

  const getTooltipText = () => {
    return isBookedWithFlippingEnabled()
      ? 'This function is now unable to be edited. please reach out to support if you need assistance.'
      : 'Stalls can only be sold once during an event';
  };

  useEffect(() => {
    if (hasValues(stallsPerBuilding)) {
      const buildingsWithMismatchFlip = getBuildingsWithMismatchFlip();

      setBuildingsWithMismatchFlipAreChecked(buildingsWithMismatchFlip.length > 0);
      setWarning(getWarning(buildingsWithMismatchFlip));
    }
  }, [stallsHash, selectedStalls, flippingDisabled, buildingsWithFlipping, selectedBuildingsOverlapping]);

  return (
    <div className="flipping">
      <HeadingFour className="section-label" label="Flipping" />
      <div className="checkbox-container" style={{ display: 'flex', alignItems: 'center' }}>
        <Field name={`stalls[${index}].flippingDisabled`} type="checkbox">
          {({ field }) => (
            <FormControlLabel
              aria-label="Disable Flip"
              control={
                <CheckboxThemed
                  name={field.name}
                  onChange={e => {
                    setFieldValue(field.name, e.target.checked);
                  }}
                  disabled={isDisabled || buildingsWithMismatchFlipAreChecked || isBookedWithFlippingEnabled()}
                  checked={flippingDisabled}
                />
              }
              label="Disable Flip"
            />
          )}
        </Field>
        <BlueTooltip placement="top" arrow title={getTooltipText()}>
          <InfoIcon fontSize="medium" />
        </BlueTooltip>
      </div>
      {buildingsWithMismatchFlipAreChecked && !isBookedWithFlippingEnabled() && <Notice label={warning} />}
    </div>
  );
};

export default StallFlipping;
