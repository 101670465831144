import styled from 'styled-components';

export const StyledHeader = styled('div')`
  border-bottom: 1px solid rgb(200, 214, 229);

  .header-wrapper {
    margin: 85px 22px 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledCard = styled('div')`
  display: flex;

  .card {
    background-color: #ffffff;
    flex: 1;
    max-width: 700px;
    margin-top: 30px;
    margin-right: 15px;
    margin-left: 22px;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px #11181f1a;
    min-height: 149px;

    .card-header {
      text-align: left;
      border-bottom: 1px solid #c8d6e5;
      padding-bottom: 10px;
    }

    .card-content {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
    }
  }
`;

export const StyledSwitch = styled('div')`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #ffffff;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #10ac84;
    opacity: 1;
  }
`;

export const StyledLoading = styled('div')`
  margin-top: 300px;
`;
