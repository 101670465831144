import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Button from './index';

const CancelButton = props => {
  return <CancelButtonStyled isMobile={isMobile} warn variant="contained" size="large" {...props} />;
};

const CancelButtonStyled = styled(Button)`
  &&& {
    align-items: center;
    height: 36px;
    letter-spacing: 0.7px;
    line-height: normal;
    justify-content: center;
    margin-left: 20px;
    white-space: nowrap;
    width: 225px;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-left: 0;
    `}
  }
`;

export default CancelButton;
