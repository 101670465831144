export const actions = {
  CREATE_GROUP: 'CREATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  PRODUCT_ASSIGNMENT: 'PRODUCT_ASSIGNMENT',
  ADMIN_NOTES: 'ADMIN_NOTES',
  ORDER_PAYMENT: 'ORDER_PAYMENT',
  DEFERRED_SETLEMENT_CASH_PAYMENT: 'DEFERRED_SETLEMENT_CASH_PAYMENT',
  EDIT_RESERVATION_BUTTON_ON_RESERVATION_SIDEBAR: 'EDIT_RESERVATION_BUTTON_ON_RESERVATION_SIDEBAR',
  RV_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR: 'RV_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR',
  RESERVATION_BULK_ACTIONS: 'RESERVATION_BULK_ACTIONS',
  STALL_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR: 'STALL_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR',
  REFRESH_CODE: 'REFRESH_CODE',
  GET_DETAILS_SMS_COUNT: 'GET_DETAILS_SMS_COUNT',
  SEND_CUSTOM_SMS_BY_ORDER_IDS: 'SEND_CUSTOM_SMS_BY_ORDER_IDS'
};
