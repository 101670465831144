import { useState } from 'react';

export const useOrderSidePanel = () => {
  const [orderID, setOrderId] = useState(null);
  return {
    orderID,
    open: id => setOrderId(id),
    onClose: () => setOrderId(null)
  };
};
