import moment from 'moment';

import PRODUCT_REF_TYPE from 'Constants/productRefType';
import { isEmpty } from './isEmpty';

export const mapOrderItems = ({ addOns, stallProductId, stallsQuantity, stallsStart, stallsEnd, rvProductId, rvsQuantity, rvsStart, rvsEnd }) => {
  const orderItemsArray = [];
  if (!!stallProductId && !!stallsQuantity && !!stallsStart && !!stallsEnd) {
    orderItemsArray.push({
      xProductId: stallProductId,
      xRefTypeId: PRODUCT_REF_TYPE.STALL_PRODUCT,
      quantity: parseInt(stallsQuantity),
      startDate: moment(stallsStart).format('YYYY-MM-DD'),
      endDate: moment(stallsEnd).format('YYYY-MM-DD')
    });
  }
  if (!isEmpty(addOns)) {
    Object.keys(addOns).map(addOnProductId => {
      const quantity = parseInt(addOns[addOnProductId]);
      if (quantity > 0) {
        orderItemsArray.push({
          xProductId: addOnProductId,
          xRefTypeId: PRODUCT_REF_TYPE.ADD_ON_PRODUCT,
          quantity
        });
      }
    });
  }
  if (!!rvProductId && !!rvsQuantity && !!rvsStart && !!rvsEnd) {
    orderItemsArray.push({
      xProductId: rvProductId,
      xRefTypeId: PRODUCT_REF_TYPE.RV_PRODUCT,
      quantity: parseInt(rvsQuantity),
      startDate: moment(rvsStart).format('YYYY-MM-DD'),
      endDate: moment(rvsEnd).format('YYYY-MM-DD')
    });
  }
  return orderItemsArray;
};

export const mapOrderItemsSummary = ({ orderItems, addOnProducts, adminCharge }) => {
  const items = orderItems.map(orderItem => parseOrderItem(orderItem, addOnProducts));
  if (!adminCharge?.amount) {
    return items;
  } else {
    return [
      ...items,
      {
        adminCharge: true,
        orderItemCost: adminCharge.amount
      }
    ];
  }
};

const parseOrderItem = (orderItem, addOnProducts) => {
  switch (parseInt(orderItem.xRefTypeId)) {
    case PRODUCT_REF_TYPE.STALL_PRODUCT:
      return {
        unit: 'Stall',
        by: 'x',
        duration: Number(moment(orderItem.endDate).diff(moment(orderItem.startDate), 'days')),
        quantityUnit: 'night',
        ...orderItem
      };
    case PRODUCT_REF_TYPE.RV_PRODUCT:
      return {
        unit: 'RV spot',
        by: 'x',
        duration: Number(moment(orderItem.endDate).diff(moment(orderItem.startDate), 'days')),
        quantityUnit: 'night',
        ...orderItem
      };
    case PRODUCT_REF_TYPE.ADD_ON_PRODUCT: {
      const match = addOnProducts.find(addOnProduct => addOnProduct.id === orderItem.xProductId);
      const addOnProduct = match ? match : { addOn: {} };
      return {
        unit: addOnProduct.addOn.unitName || '',
        by: 'of',
        duration: null,
        quantityUnit: addOnProduct.addOn.name || '',
        ...orderItem
      };
    }
    default:
      return {
        ...orderItem,
        by: '',
        duration: null,
        quantityUnit: '',
        unit: ''
      };
  }
};
