import React from 'react';

import closeIcon from '../../../../assets/img/close.png';

export const ClosePanelButton = ({ onClose }) => {
  return (
    <div className={'close-icon'} tabIndex={0} role="button" onKeyPress={() => onClose()} onClick={() => onClose()}>
      <img src={closeIcon} alt={'Close'} />
    </div>
  );
};
