import gql from 'graphql-tag';

export const ORDER_FOR_ORDER_TABLE_DETAIL = gql`
  query OrderForOrderTableDetail($id: ID) {
    order(id: $id) {
      id
      isVisited
      notes
      adminNotes
      canceled
      updatedAt
      isEditable
      addOnStatusId
      addOnDeliveryNotes
      orderItems {
        id
        quantity
        addOnProduct {
          id
          addOn {
            id
            name
            unitName
          }
        }
        reservation {
          id
          status {
            id
            name
          }
          startDate
          endDate
          rvProduct {
            id
            rvLot {
              id
              name
            }
            name
          }
          stallProduct {
            id
            name
          }
          stalls {
            id
            name
            status
            building {
              id
              name
            }
          }
          rvSpots {
            id
            name
            rvLot {
              id
              name
            }
          }
        }
      }
      user {
        firstName
        id
        lastName
        phone
      }
      event {
        id
        name
        description
      }
    }
  }
`;
