import gql from 'graphql-tag';

export const UPDATE_MAP = gql`
  mutation updateMap($input: UpdateMapInput) {
    updateMap(input: $input) {
      map {
        id
        name
        url
        description
        buildings
        type
      }
    }
  }
`;
