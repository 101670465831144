import React, { useContext, useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { UserContext } from 'Store/UserContext';
import IndeterminateLoading from 'Components/Loading/IndeterminateLoading';
import { USER_SETTINGS_QUERY } from '../../../queries/Admin/UserSettings';
import { SET_USER_SETTINGS } from '../../../mutations/UserSettings';
import { StyledHeader, StyledCard, StyledSwitch, StyledLoading } from './StyledSettings';
import { unauthenticatedHelper } from 'Helpers/unauthenticatedHelper';
import { SnackbarContext } from 'Store/SnackbarContext';
import ContextSnackbar from 'Components/Snackbar';
import { useHistory } from 'react-router';
import routeCodes from '../../../constants/routes';
import HeadingOne from '../../../components/Headings/HeadingOne';
import HeadingThree from '../../../components/Headings/HeadingThree';
import HeadingFive from '../../../components/Headings/HeadingFive';

const Settings = () => {
  const { user } = useContext(UserContext);
  const [reservationEmail, setReservationEmail] = useState(false);
  const [addOnReservationEmail, setAddOnReservationEmail] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const { push } = useHistory();
  const [setSettings, { data: setSettingsResponse, loading: setSettingsLoading, error: setSettingsError }] = useMutation(SET_USER_SETTINGS);

  const { data, loading } = useQuery(USER_SETTINGS_QUERY, {
    fetchPolicy: 'network-only'
  });

  const onChangeReservationEmail = event => {
    const newReservationEmailFlag = event.target.checked;
    setReservationEmail(newReservationEmailFlag);
    setSettings({ variables: { input: { reservationEmail: newReservationEmailFlag, addOnReservationEmail } } });
  };

  const onChangeAddOnReservationEmail = event => {
    const newAddOnReservationEmailFlag = event.target.checked;
    setAddOnReservationEmail(newAddOnReservationEmailFlag);
    setSettings({ variables: { input: { addOnReservationEmail: newAddOnReservationEmailFlag, reservationEmail } } });
  };

  useEffect(() => {
    if (!loading) {
      setReservationEmail(data.userSettings.reservationEmail);
      setAddOnReservationEmail(data.userSettings.addOnReservationEmail);
    }
  }, [data]);

  useEffect(() => {
    if (setSettingsError) {
      if (unauthenticatedHelper(setSettingsError)) {
        showSnackbar('Unauthenticated user', { error: true });
        setTimeout(() => push(routeCodes.LOGIN), 4000);
      } else {
        showSnackbar('Something went wrong', { error: true });
      }
    }
  }, [setSettingsError]);

  useEffect(() => {
    if (setSettingsResponse?.setUserSettings?.error) {
      showSnackbar(setSettingsResponse.setUserSettings.error, { error: true });
    }
  }, [setSettingsResponse]);

  if (loading || setSettingsLoading) {
    return (
      <StyledLoading>
        <IndeterminateLoading />
      </StyledLoading>
    );
  }

  return (
    <>
      <ContextSnackbar />
      <StyledHeader>
        <div className="header-wrapper">
          <HeadingOne label="SETTINGS & NOTIFICATIONS" />
        </div>
      </StyledHeader>
      <StyledCard>
        <div className="card">
          <div className="card-header">
            <HeadingThree label="Account Information" />
          </div>
          <div className="card-content">
            <HeadingFive label="Email" />
            <span>{user.email}</span>
          </div>
        </div>
      </StyledCard>
      <StyledCard>
        <div className="card">
          <div className="card-header">
            <HeadingThree label="Notifications" />
          </div>
          <div className="card-content">
            <StyledSwitch>
              <Switch onChange={onChangeReservationEmail} size={'medium'} color="primary" checked={reservationEmail} />
              <span>Email me when there is a new reservation</span>
            </StyledSwitch>
            <StyledSwitch>
              <Switch onChange={onChangeAddOnReservationEmail} size={'medium'} color="primary" checked={addOnReservationEmail} />
              <span>Email me when there is an add on only reservation</span>
            </StyledSwitch>
          </div>
        </div>
      </StyledCard>
    </>
  );
};

export default Settings;
