import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const EventForm = ({ children, className, isLoading }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue('isLoading', isLoading);
  }, [isLoading]);
  return <form className={className}>{children}</form>;
};
