import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Grid, Modal } from '@material-ui/core';
import { HeadingFour } from 'Components/Headings';
import ContextSnackbar from 'Components/Snackbar';
import { UpdateBuildingSchema } from 'Pages/SuperAdmin/Buildings/helpers';
import { Field, Form, Formik } from 'formik';
import { FormikField } from 'Components/Fields';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_BUILDING } from 'Mutations/updateBuilding';
import { SnackbarContext } from 'Store/SnackbarContext';
const BuildingsTable = ({ buildings, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const [updateBuilding, { data, loading, error }] = useMutation(UPDATE_BUILDING);

  useEffect(() => {
    if (data) {
      showSnackbar('Building saved successfully', {
        error: false,
        duration: 5000
      });
      setIsOpen(false);
      refetch && refetch();
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to save Building`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  if (!buildings) {
    return null;
  }

  if (buildings && buildings.length === 0) {
    return <div>No buildings</div>;
  }

  const handleEdit = buildingId => {
    const building = buildings.find(b => b.id === buildingId);
    if (building) {
      setSelectedBuilding(building);
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ContextSnackbar />
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {buildings.map(building => {
            return (
              <tr>
                <td>{building.id}</td>
                <td>{building.name}</td>
                <td>
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleEdit(building.id);
                    }}>
                    <span>EDIT</span>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal open={isOpen} onClose={handleClose} disableAutoFocus={true} disableRestoreFocus={true}>
        <Card className="modal-card">
          <div className="header-container">
            <HeadingFour label="EDIT BUILDING" />
          </div>
          <Formik
            initialValues={{
              name: selectedBuilding.name
            }}
            onSubmit={async values =>
              await updateBuilding({
                variables: {
                  input: {
                    id: selectedBuilding.id,
                    name: values.name
                  }
                }
              })
            }
            validationSchema={UpdateBuildingSchema}>
            {({ isValid, dirty, isSubmitting }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field autoComplete="none" label="BUILDING NAME" type="text" name="name" variant="filled" component={FormikField} />
                    </Grid>
                  </Grid>
                  <div className="flex-button-wrapper">
                    <Button
                      className="form-button"
                      primary
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid || loading}
                      data-testid="create-group-save">
                      SAVE
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Modal>
    </>
  );
};

export default BuildingsTable;
