import gql from 'graphql-tag';

export const STALLS_QUERY = gql`
  query VenueBuildingsAndStalls($id: ID) {
    venue(id: $id) {
      buildings {
        id
        name
        availableStalls {
          id
          name
          status
        }
      }
    }
  }
`;
