import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

const SidePanelFormValidations = Yup.object().shape({
  order: Yup.object(),
  stallStatusId: Yup.string().when('hasStalls', {
    is: val => !!val,
    then: Yup.string().required('Stall Reservation status is required'),
    otherwise: Yup.string().nullable()
  }),
  rvStatusId: Yup.string().when('hasRvs', {
    is: val => !!val,
    then: Yup.string().required('RV Reservation status is required'),
    otherwise: Yup.string().nullable()
  })
});

export const SidePanelForm = ({ children, ...props }) => {
  return (
    <Formik initialValues={{ ...props }} validationSchema={SidePanelFormValidations}>
      {props => children(props)}
    </Formik>
  );
};
