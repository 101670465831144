import { STALL_PRODUCT_AVAILABILITY } from 'Queries/StallProductAvailability';
import { RV_PRODUCT_AVAILABILITY } from 'Queries/RvProductAvailability';
import { STALL_AVAILABILITY_BY_PRODUCT_IDS } from 'Queries/StallAvailabilityByProductIds';
import { RV_AVAILABILITY_BY_PRODUCT_IDS } from 'Queries/RvAvailabilityByProductIds';
import client from 'Lib/api';

const getDataForMaps = async (type, eventId, startDate, endDate) => {
  const productAvailabilityQuery = type === 'stall' ? STALL_PRODUCT_AVAILABILITY : RV_PRODUCT_AVAILABILITY;
  const productsQuery = type === 'stall' ? STALL_AVAILABILITY_BY_PRODUCT_IDS : RV_AVAILABILITY_BY_PRODUCT_IDS;

  // 1) -----------------------------------------------------------------------------------------------------
  const { data: productAvailabilityData } = await client.query({
    query: productAvailabilityQuery,
    variables: {
      input: {
        eventId,
        startDate: startDate,
        endDate: endDate
      }
    },
    fetchPolicy: 'network-only'
  });

  // 2 -----------------------------------------------------------------------------------------------------
  let productIds;
  if (type === 'stall') {
    productIds = productAvailabilityData?.stallProductAvailability.map(x => x.productId);
  } else {
    productIds = productAvailabilityData?.rvProductAvailability.map(x => x.productId);
  }

  if (!productIds || productIds?.length === 0) {
    return [];
  }

  const { data } = await client.query({
    query: productsQuery,
    variables: {
      input: {
        startDate: startDate,
        endDate: endDate,
        productIds
      }
    },
    fetchPolicy: 'network-only'
  });

  let productsData;
  if (type === 'stall') {
    productsData = data?.stallAvailabilityByProductIds?.availableSpaces;
  } else {
    productsData = data?.rvAvailabilityByProductIds?.availableSpaces;
  }

  // 3 -----------------------------------------------------------------------------------------------------
  if (productsData) {
    return productsData.map(x => x.id);
  } else {
    return [];
  }
};

export default getDataForMaps;
