import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikField } from '../../../components/Fields';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../../components/Button';
import ContextSnackbar from '../../../components/Snackbar';
import { SnackbarContext } from '../../../store/SnackbarContext';
import { subRouteCodes as SUB_ROUTES } from '../../../constants/routes';
import { initialValues, CreateRVspotsSchema } from './helpers';
import { CREATE_RVSPOTS } from '../../../mutations/CreateRVSpots';
import { HeadingFive } from 'Components/Headings';
import './RVspots.scss';

const CreateRVspotsForm = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [createRVspots, { data, loading, error }] = useMutation(CREATE_RVSPOTS);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      showSnackbar('RV spots created successfully', {
        error: false,
        duration: 5000
      });
      history.push(SUB_ROUTES.SUPER_ADMIN.RVSPOTS);
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to create spots`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  return (
    <div className="create-rvspots-container">
      <ContextSnackbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async values =>
          await createRVspots({
            variables: {
              input: {
                amount: Number(values.amount),
                spotLetter: values.spotLetter,
                firstSpotNumber: Number(values.firstSpotNumber),
                rvLotId: values.rvLotId,
                description: values.description
              }
            }
          })
        }
        validationSchema={CreateRVspotsSchema}>
        {({ isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <HeadingFive label="RV SPOTS INFORMATION" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="HOW MANY SPOTS?" type="text" name="amount" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="SPOT LETTER" type="text" name="spotLetter" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="FIRST SPOT NUMBER" type="number" name="firstSpotNumber" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="RV LOT ID" type="number" name="rvLotId" variant="filled" component={FormikField} />
                </Grid>
                <Grid item xs={12}>
                  <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                </Grid>
              </Grid>
              <div className="flex-button-wrapper">
                <Button
                  className="form-button"
                  primary
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid || loading}
                  data-testid="create-group-save">
                  SAVE
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateRVspotsForm;
