import React from 'react';
import { useFormikContext } from 'formik';

import Summary from './Summary';
import FormCard from '../../../../components/Cards/FormCard';

const CheckoutSummaryPreview = () => {
  const {
    values: { event, stalls, addOns, rvProductId, rv_spot, selectedStalls, selectedRvs }
  } = useFormikContext();

  return (
    <FormCard>
      <Summary
        event={event}
        stalls={stalls}
        addOns={addOns}
        rvProductId={rvProductId}
        rv_spot={rv_spot}
        selectedStalls={selectedStalls}
        selectedRvs={selectedRvs}
      />
    </FormCard>
  );
};

export default CheckoutSummaryPreview;
