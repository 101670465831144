import gql from 'graphql-tag';

export const LOTS_WITH_FLIPPING_QUERY = gql`
  query getLotsWithFlipping($input: LotsWithFlippingInput) {
    lotsWithFlipping(input: $input) {
      id
      rvProducts {
        id
        name
        event
        flip
      }
    }
  }
`;
