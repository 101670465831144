import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Grid, Modal } from '@material-ui/core';
import { HeadingFour } from 'Components/Headings';
import ContextSnackbar from 'Components/Snackbar';
import { UpdateRVlotSchema } from 'Pages/SuperAdmin/RVlots/helpers';
import { Field, Form, Formik } from 'formik';
import { FormikField } from 'Components/Fields';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_RVLOT } from 'Mutations/updateRVlot';
import { SnackbarContext } from 'Store/SnackbarContext';
import { FormSelect } from 'Components/Select';
const RVLotsTable = ({ rvlots, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRVlot, setSelectedRVlot] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const [updateRVlot, { data, loading, error }] = useMutation(UPDATE_RVLOT);

  useEffect(() => {
    if (data) {
      showSnackbar('RV Lot saved successfully', {
        error: false,
        duration: 5000
      });
      setIsOpen(false);
      refetch && refetch();
    } else if (error) {
      showSnackbar(error?.message?.replace('GraphQL error: ', '') || `Unable to save RV Lot`, { error: true, duration: 5000 });
    }
  }, [data, error]);

  if (!rvlots) {
    return null;
  }

  if (rvlots && rvlots.length === 0) {
    return <div>No rv lots</div>;
  }

  const handleEdit = rvLotId => {
    const rvLot = rvlots.find(b => b.id === rvLotId);
    if (rvLot) {
      setSelectedRVlot(rvLot);
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ContextSnackbar />
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Power</th>
            <th>Sewer</th>
            <th>Water</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {rvlots.map(rvlot => {
            return (
              <tr>
                <td>{rvlot.id}</td>
                <td>{rvlot.name}</td>
                <td>{rvlot.description}</td>
                <td>{rvlot.power}</td>
                <td>{rvlot.sewer === true ? 'YES' : 'NO'}</td>
                <td>{rvlot.water === true ? 'YES' : 'NO'}</td>
                <td>
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleEdit(rvlot.id);
                    }}>
                    <span>EDIT</span>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal open={isOpen} onClose={handleClose} disableAutoFocus={true} disableRestoreFocus={true}>
        <Card className="modal-card">
          <div className="header-container">
            <HeadingFour label="EDIT RV LOT" />
          </div>
          <Formik
            initialValues={{
              name: selectedRVlot.name,
              description: selectedRVlot.description,
              power: selectedRVlot.power,
              sewer: selectedRVlot.sewer,
              water: selectedRVlot.water
            }}
            onSubmit={async values =>
              await updateRVlot({
                variables: {
                  input: {
                    id: selectedRVlot.id,
                    name: values.name,
                    description: values.description,
                    power: values.power,
                    sewer: values.sewer === 'true',
                    water: values.water === 'true'
                  }
                }
              })
            }
            validationSchema={UpdateRVlotSchema}>
            {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field autoComplete="none" label="NAME" type="text" name="name" variant="filled" component={FormikField} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field autoComplete="none" label="DESCRIPTION" type="text" name="description" variant="filled" component={FormikField} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="power" autoComplete="none">
                        {({ field, meta }) => (
                          <FormSelect
                            {...field}
                            {...meta}
                            selectedOption={values.power || selectedRVlot.power}
                            label="POWER"
                            cb={e => {
                              setFieldValue('power', e.target.value);
                            }}
                            className="power-select"
                            options={[
                              { label: '20', value: '20' },
                              { label: '30', value: '30' },
                              { label: '50', value: '50' },
                              { label: '20, 30', value: '20, 30' },
                              { label: '20, 50', value: '20, 50' },
                              { label: '30, 50', value: '30, 50' },
                              { label: '20, 30, 50', value: '20, 30, 50' }
                            ]}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="sewer" autoComplete="none">
                        {({ field, meta }) => (
                          <FormSelect
                            {...field}
                            {...meta}
                            selectedOption={values.sewer}
                            label="SEWER"
                            cb={e => {
                              setFieldValue('sewer', e.target.value);
                            }}
                            className="sewer-select"
                            options={[
                              { label: 'TRUE', value: 'true' },
                              { label: 'FALSE', value: 'false' }
                            ]}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="water" autoComplete="none">
                        {({ field, meta }) => (
                          <FormSelect
                            {...field}
                            {...meta}
                            selectedOption={values.water}
                            label="WATER"
                            cb={e => {
                              setFieldValue('water', e.target.value);
                            }}
                            className="water-select"
                            options={[
                              { label: 'TRUE', value: 'true' },
                              { label: 'FALSE', value: 'false' }
                            ]}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <div className="flex-button-wrapper">
                    <Button
                      className="form-button"
                      primary
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid || loading}
                      data-testid="create-group-save">
                      SAVE
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Modal>
    </>
  );
};

export default RVLotsTable;
