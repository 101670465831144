import React from 'react';
import styled from 'styled-components';
import { Field, useFormikContext } from 'formik';
import { FormikField } from '../../../../components/Fields';

export const LinkRow = ({ id, children }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <LinkRowStyled className={'card-row link'}>
      <div className={'card-row links-form-wrapper'}>
        <Field
          className="form-link-url"
          name={`eventLinks[${id}].url`}
          type="text"
          component={FormikField}
          label="URL"
          variant="filled"
          required
          onChange={e => {
            setFieldValue(`eventLinks[${id}].url`, e.target.value);
          }}
        />
        <Field
          className="form-link-label"
          name={`eventLinks[${id}].label`}
          type="text"
          component={FormikField}
          label="Label"
          variant="filled"
          onChange={e => {
            setFieldValue(`eventLinks[${id}].label`, e.target.value);
          }}
        />
        <Field
          className="form-link-description"
          name={`eventLinks[${id}].description`}
          type="text"
          component={FormikField}
          label="Description"
          variant="filled"
          onChange={e => {
            setFieldValue(`eventLinks[${id}].description`, e.target.value);
          }}
        />
      </div>
      {children}
    </LinkRowStyled>
  );
};

const LinkRowStyled = styled.div`
  .links-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 20px;
  }
`;
