import gql from 'graphql-tag';

export const VENUE_RV_LOTS = gql`
  query venue($id: ID) {
    venue(id: $id) {
      rvLots {
        id
        name
        description
        sewer
        water
        power
        availableRVSpots {
          id
          name
          description
        }
      }
    }
  }
`;
