import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      error
    }
  }
`;

export const withResetPassword = graphql(RESET_PASSWORD, {
  props: ({ mutate }) => ({
    resetPassword: input => mutate({ variables: { input } })
  }),
  options: () => ({
    onCompleted: async data => {
      return data;
    }
  })
});

export default withResetPassword;
