import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const RESERVATION_CUSTOM_SMS = gql`
  mutation OrderCustomSMS($input: SMSByOrderIdsInput) {
    customSMSWithOrders(input: $input) {
      success
      error
    }
  }
`;

export const withOrdersCustomSMS = graphql(RESERVATION_CUSTOM_SMS, {
  props: ({ mutate }) => ({
    customSMSWithOrders: input => mutate({ variables: { input } })
  })
});

export default withOrdersCustomSMS;
