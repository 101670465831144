import React, { memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import CreateEvent from '../../containers/Event/Create';
import ContextSnackbar from '../../components/Snackbar';

const AdminCreateEventBase = () => {
  return (
    <AdminCreateEvent isMobile={isMobile}>
      <ContextSnackbar />
      <CreateEvent />
    </AdminCreateEvent>
  );
};

const AdminCreateEvent = styled.div`
  margin: 85px 50px 50px;

  ${({ isMobile }) =>
    isMobile &&
    `
      margin: 50px 16px;
    `}
`;

export default compose(withRouter)(memo(AdminCreateEventBase));
