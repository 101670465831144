const isMaximumAllowedExceeded = (value, productName) => {
  if (!value) return false;
  return Boolean(
    value &&
      Number(value) >
        (productName === 'rvProduct' || productName === 'rvs' ? Number(process.env.REACT_APP_MAX_RVS) || 50 : Number(process.env.REACT_APP_MAX_STALLS) || 50)
  );
};

const isMaximumRvsAllowedExceeded = value => {
  return isMaximumAllowedExceeded(value, 'rvProduct');
};

const isMaximumStallsAllowedExceeded = value => {
  return isMaximumAllowedExceeded(value, 'stalls');
};

const isMaximumAddOnAllowedExceeded = value => {
  return Boolean(value && Number(value) > (Number(process.env.REACT_APP_MAX_ADDONS) || 150));
};

const checkIfProductExist = p => {
  return !!p.quantity && !!p.start && !!p.end;
};

const calculateNights = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInTime = end.getTime() - start.getTime();
  return differenceInTime / (1000 * 3600 * 24); // Convert time difference to days
};

const isMaximumRequiredAddOnAllowedExceeded = (requiredAddOn, values, value) => {
  const { stalls, rv_spot } = values;
  const { xRefTypeId, ratio, condition, max } = requiredAddOn;

  let baseQuantity = 0;

  // Calculate base quantity based on stalls
  if (checkIfProductExist(stalls) && xRefTypeId === 1) {
    const stallsQuantity = condition === 'PRODUCT' ? +stalls.quantity : calculateNights(stalls.start, stalls.end);
    baseQuantity += stallsQuantity;
  }

  // Calculate base quantity based on rv_spot
  if (checkIfProductExist(rv_spot) && xRefTypeId === 3) {
    const rvQuantity = condition === 'PRODUCT' ? +rv_spot.quantity : calculateNights(rv_spot.start, rv_spot.end);
    baseQuantity += rvQuantity;
  }

  // Calculate the total addOn quantity using the ratio
  const addOnQuantity = baseQuantity * ratio;

  let currentQuantity = addOnQuantity > value ? addOnQuantity : value;

  return currentQuantity > max * baseQuantity;
};

const hasProductQuantityError = (isRv, errors) => {
  if (errors) {
    return (isRv && !!errors.rv_spot && !!errors.rv_spot.quantity) || (!isRv && !!errors.stalls && !!errors.stalls.quantity);
  }
  return false;
};

export {
  hasProductQuantityError,
  isMaximumAllowedExceeded,
  isMaximumRvsAllowedExceeded,
  isMaximumStallsAllowedExceeded,
  isMaximumAddOnAllowedExceeded,
  isMaximumRequiredAddOnAllowedExceeded
};
