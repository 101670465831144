import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { InputNumber } from 'antd';
import { isMobile } from 'react-device-detect';

import { HeadingFour } from 'Components/Headings';
import Button from 'Components/Button';

const getOptions = data => {
  if (!data) return null;

  return data.map(data => (
    <MenuItem key={data.id} value={data.id}>
      {data.name}
    </MenuItem>
  ));
};

const requiredAddOns = () => {
  const { setFieldValue, values } = useFormikContext();
  const [activeRows, setActiveRows] = useState([]);
  const { addOns } = values;

  useEffect(() => {
    setActiveRows([...values.requiredAddOns]);
  }, [JSON.stringify(values.requiredAddOns)]);

  const addRow = () => {
    setFieldValue('requiredAddOns', [
      ...values.requiredAddOns,
      {
        id: values.requiredAddOns.length + Math.floor(Math.random() * (20 - 10 + 1)) + 10,
        eventId: '',
        addOnProduct: '',
        xRefTypeId: '',
        ratio: 1,
        condition: ''
      }
    ]);
  };

  const removeRow = id => () => {
    const updatedRows = values.requiredAddOns.filter(ra => +ra.id !== +id);
    setFieldValue('requiredAddOns', updatedRows);
  };

  const handleIntChange = (id, field) => value => {
    const updatedRows = values.requiredAddOns.map(row => (+row.id === +id ? { ...row, [field]: value } : row));
    setFieldValue('requiredAddOns', updatedRows);
  };

  const handleChange = (id, field) => e => {
    const updatedRows = values.requiredAddOns.map(row => (+row.id === +id ? { ...row, [field]: e.target.value } : row));
    setFieldValue('requiredAddOns', updatedRows);
  };

  if ((addOns && addOns.length === 0) || !addOns.every(a => !!a.name && !!a.price)) {
    return (
      <RequiredAddOnsStyled>
        <HeadingFour label="Required Add Ons" />
        <p>Set up required add ons based on product type and specific rules.</p>
        <p>Add at least one Add On to the event.</p>
      </RequiredAddOnsStyled>
    );
  }

  return (
    <RequiredAddOnsStyled>
      <HeadingFour label="Required Add Ons" />
      <p>Set up required add ons based on product type and specific rules.</p>
      <FormControlStyled isMobile={isMobile}>
        {activeRows.map(ar => {
          return (
            <div className={`requiredAddOn ${isMobile ? 'mobile' : ''}`}>
              <>
                <span className="phrase">For </span>
                <Field
                  as="select"
                  component={Select}
                  className="select-input input-xRefTypeId"
                  name={`requiredAddOns[${ar.id}].xRefTypeId`}
                  value={ar.xRefTypeId}
                  onChange={handleChange(ar.id, 'xRefTypeId')}>
                  <MenuItem value={1}>Stalls</MenuItem>
                  <MenuItem value={3}>RVs</MenuItem>
                </Field>
                <span className="phrase">{isMobile ? ' require ' : ' , require '}</span>
                <InputNumber
                  className="ratio-input"
                  value={ar.ratio}
                  name={`requiredAddOns[${ar.id}].ratio`}
                  min={1}
                  defaultValue={1}
                  onChange={handleIntChange(ar.id, 'ratio')}
                />
                <Field
                  as="select"
                  component={Select}
                  className="select-input input-addOnProduct"
                  name={`requiredAddOns[${ar.id}].addOnProduct`}
                  value={ar.addOnProduct}
                  onChange={handleChange(ar.id, 'addOnProduct')}>
                  {getOptions(addOns)}
                </Field>
                <span className="phrase">per </span>
                <Field
                  as="select"
                  component={Select}
                  className="select-input input-condition"
                  name={`requiredAddOns[${ar.id}].condition`}
                  value={ar.condition}
                  onChange={handleChange(ar.id, 'condition')}>
                  <MenuItem value={'NIGHT'}>Night</MenuItem>
                  <MenuItem value={'PRODUCT'}>Unit</MenuItem>
                </Field>
                <span className="phrase">{isMobile ? ' Max (0 = no limit): ' : '. Max (0 = no limit):'}</span>
                <InputNumber
                  className="max-input"
                  value={ar.max}
                  name={`requiredAddOns[${ar.id}].max`}
                  min={0}
                  defaultValue={0}
                  onChange={handleIntChange(ar.id, 'max')}
                />
                <a className={'remove-requiredAddOn'} onClick={removeRow(ar.id)} role={'button'} tabIndex={0}>
                  <DeleteIcon /> REMOVE
                </a>
              </>
            </div>
          );
        })}
        <div className="actions-buttons">
          <Button secondary onClick={addRow}>
            + REQUIRED ADD ON
          </Button>
        </div>
      </FormControlStyled>
    </RequiredAddOnsStyled>
  );
};

export default requiredAddOns;

const RequiredAddOnsStyled = styled.div`
  margin-top: 30px;
`;

const FormControlStyled = styled(FormControl)`
  background: none !important;

  span.phrase {
    margin-right: 10px;
    font-size: 16px;
  }

  .requiredAddOn {
    height: 40px;
    margin: 10px 0;
    display: flex;
    justify-content: start;
    align-items: end;
  }

  .requiredAddOn.mobile {
    flex-direction: column;
    height: auto;
    align-items: center;
    width: 100%;
  }

  .MuiSelect-select {
    background-color: #f2f4f7;
    min-width: 80px;
  }

  .remove-requiredAddOn {
    cursor: pointer;
    margin-left: 50px;
  }

  .ratio-input,
  .max-input {
    max-width: 57px;
    margin-right: 10px;
    background-color: #f2f4f7;
    border-radius: 3px;
    font-size: 18px;
  }

  .input-addOnProduct {
    margin-right: 10px;
  }

  .actions-buttons {
    display: flex;
    height: 50px;
    justify-content: start;
    align-items: center;

    button {
      width: 200px !important;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    `
      width: 100%;

      .select-input {
        width: 100%;
      }

      span.phrase {
        margin-bottom: 10px;
      }

      .remove-requiredAddOn {
        margin-top: 15px;
      }

      .actions-buttons {
        justify-content: start;

        button {
          width: 100% !important;
        }
      }
    `}
`;
