import * as Yup from 'yup';

export const CreateValidationSchema = Yup.object().shape({
  mapName: Yup.string().required('Name is required'),
  mapDescription: Yup.string().required('Description is required'),
  mapType: Yup.string().required('Type is required'),
  venueId: Yup.string().required('Venue is required'),
  buildings: Yup.string().required('Buildings are required'),
  file: Yup.string().required('File is required')
});

export const EditValidationSchema = Yup.object().shape({
  mapName: Yup.string().required('Name is required'),
  mapDescription: Yup.string().required('Description is required'),
  mapType: Yup.string().required('Type is required'),
  venueId: Yup.string().required('Venue is required'),
  buildings: Yup.string().required('Buildings are required')
});
