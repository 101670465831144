import React from 'react';
import StallFlipping from './StallFlipping';
import RvFlipping from '../RvSpots/RvFlipping';

const Flipping = ({ productType, index, isDisabled, stallsPerBuilding, buildingsWithFlipping, stallsHash, selectedBuildingsOverlapping, lotsWithFlipping }) => {
  const isStallProduct = productType === 'stalls';

  return (
    <>
      {isStallProduct ? (
        <StallFlipping
          index={index}
          isDisabled={isDisabled}
          stallsPerBuilding={stallsPerBuilding}
          buildingsWithFlipping={buildingsWithFlipping}
          stallsHash={stallsHash}
          selectedBuildingsOverlapping={selectedBuildingsOverlapping}
        />
      ) : (
        <RvFlipping index={index} isDisabled={isDisabled} lotsWithFlipping={lotsWithFlipping} selectedLotsOverlapping={{}} />
      )}
    </>
  );
};

export default Flipping;
