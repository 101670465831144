import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { capitalize } from '@material-ui/core';
import { compose } from 'recompose';

import { reportGraphqlError } from 'Helpers/graphqlResponseUtil';
import { withSnackbarContextActions } from 'Store/SnackbarContext';

const UPDATE_RESERVATION_STATUS = gql`
  mutation UpdateReservationStatus($input: UpdateReservationStatusInput!) {
    updateReservationStatus(input: $input) {
      error
      reservation {
        status {
          name
        }
      }
      success
    }
  }
`;

const withUpdateReservationStatus = graphql(UPDATE_RESERVATION_STATUS, {
  props: ({ mutate }) => ({
    updateReservationStatus: input => {
      mutate({
        variables: { input }
      });
    }
  }),
  options: props => ({
    onCompleted: async ({ updateReservationStatus }) => {
      if (updateReservationStatus.success) {
        const { reservation = {} } = updateReservationStatus;
        const product = props.productType === 'rvProduct' ? 'RV spot' : 'Stall';
        props.showSnackbar(` ${product} reservation status updated to ${capitalize((reservation.status && reservation.status.name) || '')}`);
      } else {
        props.showSnackbar('Reservation update failed', { error: true });
      }
    },
    onError: async error => {
      reportGraphqlError(props.showSnackbar, 'Reservation update failed', error);
    }
  })
});

export default Component => compose(withSnackbarContextActions, withUpdateReservationStatus)(Component);
