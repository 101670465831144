import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CREATE_PASSWORD = gql`
  mutation CreatePassword($input: CreatePasswordInputType!) {
    createPassword(input: $input) {
      success
      error
    }
  }
`;

const withCreatePassword = graphql(CREATE_PASSWORD, {
  props: ({ mutate }) => ({
    createPassword: input =>
      mutate({
        variables: { input }
      })
  }),
  options: () => ({
    onCompleted: result => result,
    onError: error => error
  })
});

export default withCreatePassword;
