import React, { memo } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import CreateMap from '../../../containers/Map/Create';
import ContextSnackbar from '../../../components/Snackbar';

const SuperAdminCreateMapBase = ({ className }) => (
  <>
    <section className={className}>
      <ContextSnackbar />
      <CreateMap />
    </section>
  </>
);

const SuperAdminCreateMap = styled(SuperAdminCreateMapBase)`
  margin: 85px 50px 50px;
`;

// eslint-disable-next-line prettier/prettier
export default compose(withRouter)(memo(SuperAdminCreateMap));
